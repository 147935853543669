import React from 'react'
import { IconProps } from './sendIcon.component'

const TrashIcon: React.FC<IconProps> = ({ colorCode, ...props }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M20.25 4.50049H16.5V3.75049C16.4975 3.15451 16.2597 2.58365 15.8383 2.16222C15.4168 1.7408 14.846 1.50296 14.25 1.50049H9.75C9.15402 1.50296 8.58316 1.7408 8.16174 2.16222C7.74031 2.58365 7.50247 3.15451 7.5 3.75049V4.50049H3.75C3.55109 4.50049 3.36032 4.57951 3.21967 4.72016C3.07902 4.86081 3 5.05158 3 5.25049C3 5.4494 3.07902 5.64017 3.21967 5.78082C3.36032 5.92147 3.55109 6.00049 3.75 6.00049H4.5V19.5005C4.5 19.8983 4.65804 20.2798 4.93934 20.5611C5.22064 20.8425 5.60218 21.0005 6 21.0005H18C18.3978 21.0005 18.7794 20.8425 19.0607 20.5611C19.342 20.2798 19.5 19.8983 19.5 19.5005V6.00049H20.25C20.4489 6.00049 20.6397 5.92147 20.7803 5.78082C20.921 5.64017 21 5.4494 21 5.25049C21 5.05158 20.921 4.86081 20.7803 4.72016C20.6397 4.57951 20.4489 4.50049 20.25 4.50049ZM9 3.75049C9 3.55158 9.07902 3.36081 9.21967 3.22016C9.36032 3.07951 9.55109 3.00049 9.75 3.00049H14.25C14.4489 3.00049 14.6397 3.07951 14.7803 3.22016C14.921 3.36081 15 3.55158 15 3.75049V4.50049H9V3.75049ZM18 19.5005H6V6.00049H18V19.5005ZM10.5 9.75049V15.7505C10.5 15.9494 10.421 16.1402 10.2803 16.2808C10.1397 16.4215 9.94891 16.5005 9.75 16.5005C9.55109 16.5005 9.36032 16.4215 9.21967 16.2808C9.07902 16.1402 9 15.9494 9 15.7505V9.75049C9 9.55158 9.07902 9.36081 9.21967 9.22016C9.36032 9.07951 9.55109 9.00049 9.75 9.00049C9.94891 9.00049 10.1397 9.07951 10.2803 9.22016C10.421 9.36081 10.5 9.55158 10.5 9.75049ZM15 9.75049V15.7505C15 15.9494 14.921 16.1402 14.7803 16.2808C14.6397 16.4215 14.4489 16.5005 14.25 16.5005C14.0511 16.5005 13.8603 16.4215 13.7197 16.2808C13.579 16.1402 13.5 15.9494 13.5 15.7505V9.75049C13.5 9.55158 13.579 9.36081 13.7197 9.22016C13.8603 9.07951 14.0511 9.00049 14.25 9.00049C14.4489 9.00049 14.6397 9.07951 14.7803 9.22016C14.921 9.36081 15 9.55158 15 9.75049Z" fill={colorCode} />
        </svg>

    )
}

export default TrashIcon
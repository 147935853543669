export enum EConditionTypes {
    EQUAL_TO = "equal_to",
    EQUAL_TO_SENSITIVE = "equal_to_sensitive",
    NOT_EQUAL= "not_equal",
    CONTAIN_TEXT = "contain_text",
    BIGGER_THAN = "bigger_than",
    BIGGER_OR_EQUAL_THAN = "bigger_or_equal_than",
    LOWER_THAN = "lower_than",
    LOWER_OR_EQUAL_THAN = "lower_or_equal_than",
    IS_EMAIL= "is_email",
    IS_NUMBER= "is_number",
}


export enum EMessageType {
    NORMAL_MESSAGE = "normal_message",
    MENU_MESSAGE = "menu_message",
    BUTTONS_MESSAGE = "buttons_message",
    NO_CUMPLE = "No cumple",
    GO_ACTION = "go_action",
    GROUP_AND_SUBGROUP = "group_subgroup",
    REDIRECT_ADVISOR = "redirect_advisor",
    START = "start",
    HUBSPOT = "hubspot",
    MULTIMEDIA = "multimedia"
}


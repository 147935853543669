import React, { useContext } from 'react';
import Swal from 'sweetalert2';
import withCurrentStep, { withCurrentStepProps } from '../../../HOC/withCurrentStep.hoc';
import { ICase } from '../../../types/requests/bot/case.interface';
import { StepsFormContext, StepsFormContextType } from '../../../context/stepsForm.context';
import { FlexGap, FormBody, StepFormContainer, StepFormWrapper } from '../../UI/molecules/steps/step.styles';
import { StepTable } from "../../UI/atoms/wrapper/stepTable.component";
import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import { ConditionalFormSchema } from '../../../utils/schemas/conditional.schema';
import StepFormHeader from '../../UI/organisms/botForm/stepFormHeader.component';
import Button from '../../UI/atoms/button/button.component';
import InputBox from '../../UI/molecules/inputs/inputBox.component';
import Condition from '../../UI/molecules/steps/condition.component';
import SelectBox from '../../UI/molecules/inputs/selectBox.component';
import { EConditionTypes } from '../../../types/requests/bot/conditionTypes.enum';
import { GetConditionTypeText } from '../../../utils/pipes/conditionType.pipe';
import FootBottons from '../../UI/molecules/steps/formFoot.component';
import StepsHandler from '../../../utils/bots/steps.utils';
import MixedTagInput from '../TagInput/tagInput';
import { IStep } from '../../../types/requests/bot/step.interface';
import { EMessageType } from '../../../types/requests/bot/stepType.enum';
import { BotFormContext, BotFormContextType } from '../../../context/botForm.context';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../UI/atoms/floatingElements/tooltip.component';
import BotQuestion from "../../../assets/images/question.svg";
import useBot from '../../../hooks/useBot.hook';

export const emptyCase = {
    caseId: '',
    conditions: [
        {
            answer: '',
            type: '',
            value: '',
        }
    ],
    type: '',
    conditionalStructure: ''
}

export const emptyCondition = {
    answer: '',
    type: '',
    value: ''
}

const mapCases = (cases: ICase[]) => {
    let casesObj: { [name: number]: string } = {}
    cases.forEach((conditionCase, i) => {
        casesObj[i] = (conditionCase.type === 'NO_MATCHES') ? "No cumplió ninguna condición" : `Ramificación ${conditionCase.caseId.split("_")[1]}`;
    });

    return casesObj;
}

export interface IDataFormik {
    conditionCase: ICase;
    idx: number;
    removeCondition: any,
}

const InputFormik: React.FC<IDataFormik> = ({ conditionCase, idx, removeCondition}) => {
    const formik = useFormikContext<IStep>();

    return (
        <>
            <div className='fw-medium'>Añade una condición {" "}
                <Tooltip placement="top-start">
                    <TooltipTrigger> <img src={BotQuestion} alt="help" width={"16"} /> </TooltipTrigger>
                    <TooltipContent  className="Tooltip">
                        <p>Podemos realizar una comparación entre dos valores, por ejemplo, si capturamos el salario del cliente y deseamos realizar una ramificación en caso que sus ingresos superen los 10 millones, podriamos configurar lo siguiente:</p> 
                        <ul>
                            <li>En valor 1 el campo salario capturado  #salario</li>
                            <li>En valor 2 el numero 10000000 </li>
                            <li>En condicional seleccionamos la condición  es mayor o igual a </li>
                        </ul>    
                    </TooltipContent>
                </Tooltip>  
            </div>
            

            {conditionCase.conditions.map((_, j) => (
                <div
                    className="d-flex justify-content-center align-items-center flex-column"
                    style={{ gap: "8px", fontSize: "14px" }}
                    key={j}
                >
                    <MixedTagInput
                        label={"Valor 1"}
                        value={formik.getFieldProps(`condition.cases.${idx}.conditions.${j}.answer`).value} 
                        id={`condition.cases.${idx}.conditions.${j}.answer`}
                        name={`condition.cases.${idx}.conditions.${j}.answer`}
                        placeholder={"Ej: Valor. Usa @ o # para incluir tokens."}
                    />
                    

                    <SelectBox
                        id={`condition.cases.${idx}.conditions.${j}.type`}
                        name={`condition.cases.${idx}.conditions.${j}.type`}
                        label={"Condicional"}
                    >
                        <option value="">Selecciona una opción</option>
                        {
                            Object.values(EConditionTypes).map(conditionType => (
                                <option value={conditionType} key={conditionType}>{GetConditionTypeText(conditionType)}</option>
                            ))
                        }
                    </SelectBox>

                    { !["is_email", "is_number"].includes(formik.getFieldProps(`condition.cases.${idx}.conditions.${j}.type`).value) && <MixedTagInput
                        label={"Valor 2"}
                        value={formik.getFieldProps(`condition.cases.${idx}.conditions.${j}.value`).value} 
                        id={`condition.cases.${idx}.conditions.${j}.value`}
                        name={`condition.cases.${idx}.conditions.${j}.value`}
                        placeholder={"Ej: Valor. Usa @ o # para incluir tokens."}
                    />}

                    <Button
                        btnType='tertiary'
                        className='p-1'
                        style={{ height: "max-content" }}
                        type='button'
                        onClick={() => (conditionCase.conditions.length > 1) && removeCondition(j)}
                    >Limpiar condición</Button>

                </div>
            ))}
        </>
    )
}

const ConditionalForm: React.FC<withCurrentStepProps> = ({ currentStep, bot, answersHandler }) => {

    const steps = bot!.steps;
    const { setVisible } = useContext(StepsFormContext) as StepsFormContextType;
    const { setIsModified } = useContext(BotFormContext) as BotFormContextType;
    const { stepsHandler, setBot } = useBot();

    const stepshandler = new StepsHandler(steps);

    const handleRemove = async () => {
        const cases = currentStep!.condition!.cases.filter(el => el.next);
        const { value: caseIndex, isDismissed } = await Swal.fire({
            title: "Desconexión de pasos",
            showCancelButton: true,
            cancelButtonText: "Cancelar eliminación",
            input: 'select',
            text: "Las pasos siguientes han sido desconectados del flujo del bot.",
            inputOptions: {
                '-1': "No conservar ninguna ramificación",
                ...mapCases(cases)
            },
        })

        if (!isDismissed) {
            const prevStep = stepshandler.getStep(currentStep?.previous ?? "");

            let handler = stepshandler.setBranch(cases[caseIndex]?.next, {
                previous: prevStep?.id,
                parentStep: prevStep?.parentStep
            })
            handler = handler.removeBranchs(
                cases.filter((conditionalCase) => conditionalCase.caseId !== cases[caseIndex]?.caseId)
            )
            handler = handler.removeStep(currentStep.id, cases[caseIndex]?.next, (cases[caseIndex]?.caseId ? false : true), undefined);
            let stepsOption = stepsHandler.getBranch(cases[caseIndex]?.next, "next");

            let findComponentGoAction = stepsOption.find(step => stepsHandler.getStep(step)?.type === EMessageType.GO_ACTION );
            if(findComponentGoAction){
                let dataStep = stepsHandler.getStep(findComponentGoAction);
                handler = handler.removeStep(findComponentGoAction,undefined, true, dataStep?.parentStep?.caseId)
            }
            
            await setBot.mutateAsync({ botProps:{ steps: handler.steps }, notification: {register: `Borrar nodo condicional: ${currentStep.name}`, url: window.location.href}});
            setVisible(false);
        }
    }

    return (
        <StepFormWrapper>
            <StepFormContainer visible={true}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ ...currentStep }}
                    validationSchema={ConditionalFormSchema}
                    onSubmit={async (values) => {
                        let updatedStep = { ...values }
                        let branchConfig: { next: undefined | string, caseId: undefined | string } = { next: undefined, caseId: undefined }

                        if (updatedStep.next) {
                           
                            const { value: caseIndex } = await Swal.fire({
                                title: "Mover acciones posteriores a ramificación",
                                input: 'select',
                                inputOptions: mapCases(updatedStep!.condition!.cases),
                            });
                            if(caseIndex) {
                                branchConfig.next = updatedStep.next;
                                branchConfig.caseId = updatedStep?.condition?.cases[caseIndex].caseId;
    
                                updatedStep!.condition!.cases[caseIndex]!.next = updatedStep.next;
                                updatedStep.next = undefined;
                            }
                        }

                        stepshandler.setConditionalNextSteps(updatedStep, branchConfig?.caseId, branchConfig?.next).setStep(updatedStep.id, updatedStep);

                        await setBot.mutateAsync({ botProps:{ steps: stepshandler.steps }, notification: {register: `Agregar nodo Condicional: ${values.name}`, url: window.location.href}});
                        setVisible(false);
                        setIsModified(true);
                    }}
                >
                    {({ values, getFieldProps }) => {
                        return (<Form>

                            <StepFormHeader />

                            <FormBody>
                                <FieldArray name='condition.cases'>
                                    {({ remove: removeCase, insert: insertCase }) => (
                                        <FlexGap className="accordion accordion-flush" id="casesAcordeon">
                                            <Button
                                                btnType='primary'
                                                type='button'
                                                onClick={() => insertCase(values!.condition!.cases.length - 1, { ...emptyCase, caseId: `${values.id}_${values!.condition!.cases.length}` })}
                                                className='w-100'
                                            >
                                                Agregar Ramificación
                                            </Button>

                                            {
                                                values?.condition?.cases.filter(conditionCase => conditionCase.type !== 'NO_MATCHES').map((conditionCase, idx) => (
                                                    <div key={idx} className="accordion-item">
                                                        <button
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#acordion-${conditionCase.caseId}`}
                                                            aria-expanded="false"
                                                            aria-controls={"acordion-" + conditionCase.caseId}

                                                        >
                                                            Ramificación {idx + 1}
                                                        </button>

                                                        <div
                                                            id={"acordion-" + conditionCase.caseId}
                                                            className="accordion-collapse collapse py-4"
                                                            aria-labelledby="flush-headingOne"
                                                            data-bs-parent="#casesAcordeon"
                                                        >
                                                            <FlexGap gap="24px">

                                                                <SelectBox
                                                                    id={`condition.cases.${idx}.type`}
                                                                    name={`condition.cases.${idx}.type`}
                                                                    label={"Selecciona cómo se deben cumplir las condiciones"}
                                                                >
                                                                    <option value="">Selecciona una opción</option>
                                                                    <option value={'AND'}>Debe cumplir todas las condiciones</option>
                                                                    <option value={'OR'}>Debe cumplir alguna las condiciones</option>
                                                                    <option value={'PERSONALIZED'}>Condición personalizada</option>  
                                                                </SelectBox>

                                                                {values?.condition?.cases[idx].type === 'PERSONALIZED' && <InputBox
                                                                    label={"Introduzca la forma a evaluar la condición"}
                                                                    id={`condition.cases.${idx}.conditionalStructure`}
                                                                    name={`condition.cases.${idx}.conditionalStructure`}
                                                                    placeholder={"Ejm: (1 Y 2) O 3"}
                                                                    value={getFieldProps(`condition.cases.${idx}.conditionalStructure`).value}
                                                                    textHelp={<div>
                                                                        <p><strong>Condicion Personalizada</strong></p>
                                                                        <p>Si abre un parentesis, se debe cerrar para que esten balanceadas en la condición.</p>
                                                                        <p>Si hay parentis no debe estar vacio.</p>
                                                                        <p>Si colocan una condicional Y ó O, las condiciones deben ser validas.</p>
                                                                        <p>Solo se permite colocar un maximo las condiciones configuradas, si hay 3 condiciones, no puede superar ese numero.</p>
                                                                    </div>}
                                                                />}
                                                                
                                                                <FieldArray name={`condition.cases.${idx}.conditions`}>
                                                                    {({ push: pushCondition, remove: removeCondition }) => {

                                                                        return <>
                                                                            <InputFormik conditionCase={conditionCase} idx={idx} removeCondition={removeCondition} />
                                                                            <span className='fw-medium ms-2'>Previsualización:</span>

                                                                            <StepTable>
                                                                                <thead>
                                                                                    <tr><th>Número</th><th>Condición</th></tr>
                                                                                </thead>

                                                                                <tbody>
                                                                                    {conditionCase.conditions.map((condition, i) => (
                                                                                        <tr key={i}>
                                                                                            <td>{i + 1}</td>
                                                                                            <td>
                                                                                                <Condition {...condition} idx={i} row={idx + 1} prefix={"form"}/>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </StepTable>

                                                                            <Button btnType='primary' type='button' onClick={() => pushCondition(emptyCondition)} className='w-100'>Agregar condición</Button>
                                                                        </>
                                                                    }}

                                                                </FieldArray>

                                                                <Button
                                                                    btnType='tertiary'
                                                                    type='button'
                                                                    className='mt-8'
                                                                    onClick={() => removeCase(idx)}
                                                                >
                                                                    Eliminar esta ramificación
                                                                </Button>

                                                            </FlexGap>

                                                        </div>

                                                    </div>
                                                ))
                                            }

                                    <InputBox
                                        id="bot_finish_last_message"
                                        label="Habilitar reintentos para enviar a un asesor"
                                        name="enable_retries"
                                        as="checkbox"
                                        checked={values.enable_retries}
                                    />
                                        </FlexGap>
                                    )}
                                </FieldArray>

                                <FootBottons handleRemove={handleRemove} />

                            </FormBody>

                        </Form>
                        )

                    }}
                </Formik>
            </StepFormContainer>
        </StepFormWrapper>
    )
}

export default withCurrentStep(ConditionalForm, React.Fragment, 'conditional');
// Función para validar si la URL es de una imagen o un video
export const isImageOrVideoURL = (value:string): boolean => {
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
    const videoExtensions = /\.(mp4|mov|avi|mkv|flv|wmv|webm)$/i;
    const pdfExtensions = /\.(pdf)$/i;
    return imageExtensions.test(value) || videoExtensions.test(value) || pdfExtensions.test(value);
  };
// Función para validar el valor es imagen, video o ninguno
export const isImageOrVideo = (value:string): {type: "image"| "video" | "pdf" | "other"} => {
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
    const videoExtensions = /\.(mp4|mov|avi|mkv|flv|wmv|webm)$/i;
    const pdfExtensions = /\.(pdf)$/i;
    return {type: imageExtensions.test(value) ? "image" : videoExtensions.test(value) ? "video": pdfExtensions.test(value) ? "pdf" :"other"};
  };
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import React, { useEffect, useState } from 'react';
import { FloatForm, FormBody, FormHeader } from '../../UI/molecules/steps/step.styles';
import SelectBox from '../../UI/molecules/inputs/selectBox.component';
import FootBottons from '../../UI/molecules/steps/formFoot.component';
import { TokenFormatted } from '../../../pages/connection/connection.page';
import { getBots } from '../../../services/bots.service';
import { IBot } from '../../../types/requests/bot/bot.interface';
import { FormGroupSubGroup } from './formGroupSubGroup';
import { getTokens, updateTokens } from '../../../services/tokens.service';
import { AlertFormLarge, ICON } from '../../../utils/Alert';

export interface TokenFormProps {
    visible: boolean;
    token: TokenFormatted;
    setVisible: (visible: boolean) => void;
    setDataTokens: (token: TokenFormatted[]) => void;
}

interface FormValues {
    number: string;
    group: string;
    subgroup: string;
    botId: string;
}

const ValidationSchema = Yup.object().shape({
    number: Yup.string(),
    botId: Yup.string().required('Debes agregar un Bot'),
    group: Yup.string().required('Debes agregar un Grupo'),
    subgroup: Yup.string().required('Debes agregar un SubGrupo'),
});

const TokenForm: React.FC<TokenFormProps> = ({ visible, token, setVisible, setDataTokens }) => {

    const [optionsListBots, setOptionsListBots] = useState<Partial<IBot>[]>([]);

    useEffect(() => {
        setData();
    }, []) 
    
    const setData = async() => {
        let listBots = await getBots();
        setOptionsListBots(listBots.map(bot => ({name: `${bot.name}${bot.legacy ? " (Legacy)": ""}`, _id: bot._id})));
    }

    const handleSubmit = async (values: FormValues) => {
        setVisible(false);
        try {
            await updateTokens(token.id, {
                token: {
                    flow: values.botId,
                    group: values.group,
                    subgroup: values.subgroup
                }},
                {
                    register: `Numero actualizado: +${values.number}`,
                    url: window.location.href
                }
            );
            AlertFormLarge({title: "Numero actualizado correctamente", icon: ICON.SUCCESS});
            let getListTokens = await getTokens();
            let tokensFormatted = getListTokens.map(el => ({id: el._id ,number: el.number, bot: {name: el.flow?.name || "", id: el.flow?._id}, group: el.group, subgroup:el.subgroup}));
            setDataTokens(tokensFormatted);
        } catch (error) {
            AlertFormLarge({title: "Hubo un error al actualizar el numero", text: "Verifique la configuración e intentalo nuevamente", icon: ICON.WARNING});
        }
        
    }

    return (
        <FloatForm mode='create' visible={visible}>
            <Formik
                initialValues={{
                    number: token.number,
                    group: token.group || "",
                    subgroup: token.subgroup || "",
                    botId: token.bot.id || ""
                }}
                enableReinitialize
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, resetForm, getFieldProps }) => (
                    <Form>
                        <FormHeader>Configuración de Numero</FormHeader>

                        <FormBody>
                            <SelectBox
                                id='token-bot'
                                label='Seleccione el Bot'
                                name='botId'
                                //value={getFieldProps("botId").value}
                            >
                                <option value={""}>--Selecciona un bot--</option>
                                {optionsListBots.map((bot, idx) => {
                                        return  <option value={bot._id} key={`option-${idx}`}>{bot.name}</option>
                                })}
                            </SelectBox>
                            
                            <FormGroupSubGroup />
                            <FootBottons
                                handleRemove={() => { 
                                    setVisible(false);
                                    resetForm();
                                }}
                                submit='Guardar Configuración'
                                cancel='Cancelar'
                            />

                        </FormBody>
                    </Form>
                )}
            </Formik>
        </FloatForm>

    );
}

export default TokenForm;
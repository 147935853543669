import axios from "axios";
import { IDraftBot, IDraftBotResponse } from "../types/requests/bot/draftbot.interface";
import { validateToken } from "../utils/token";
import { createLog } from "./logs.service";

export interface botServiceResponse<T = any> {
    status: string;
    message: string;
    data: T
}

export interface INotification {
    register: string;
    url: string;
}

const draftBotsService = () => (axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 10000,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token_user'),
    }
}));


export const createDraftBot = async (bot: IDraftBotResponse, notification?: INotification): Promise<IDraftBotResponse> => {
    try {
        let axiosBot = draftBotsService();
        const result = await axiosBot.post<botServiceResponse<IDraftBotResponse>>('draftBots/create', { bot: bot });

        if(notification) {
            await createLog({id: result.data.data._id, bot}, 'draftBots/create', notification.register, notification.url, "Create" );
        }
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}

export const updateDraftBot = async (id: string, bot: Partial<IDraftBot>) => {
    try {
        let axiosBot = draftBotsService();
        return await axiosBot.patch<botServiceResponse<IDraftBot>>('draftBots/update', {
            id: id,
            bot: bot
        });
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}

export const getDraftBot = async (id: string): Promise<IDraftBotResponse> => {
    try {
        let axiosBot = draftBotsService();
        const result = await axiosBot.get<botServiceResponse<IDraftBot>>(`draftBots/get/${id}`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}

export const getDraftBots = async (): Promise<IDraftBot[]> => {
    try {
        let axiosBot = draftBotsService();
        const result = await axiosBot.get<botServiceResponse<IDraftBot[]>>(`draftBots/get`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}
export const deleteDraftBot = async (id: string): Promise<IDraftBot> => {
    try {
        let axiosBot = draftBotsService();
        const result = await axiosBot.delete<botServiceResponse<IDraftBot>>(`draftBots/delete/${id}`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}
export const archiveDraftBot = async (id: string): Promise<IDraftBot> => {
    try {
        let axiosBot = draftBotsService();
        const result = await axiosBot.delete<botServiceResponse<IDraftBot>>(`draftBots/archive/${id}`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}
export default draftBotsService;

import * as Yup from "yup";
import { DefaultStepSchema } from "./required.schema";
import { CaseType } from "../../types/requests/bot/case.interface";
import { EConditionTypes } from "../../types/requests/bot/conditionTypes.enum";

const casesTypes: CaseType[] = ['AND', 'OR', 'NO_MATCHES', 'PERSONALIZED'];
const optionalTypes = [EConditionTypes.IS_EMAIL, EConditionTypes.IS_NUMBER, EConditionTypes.EQUAL_TO];


const regex = /^[0-9() YO]+$/;

const validateParentheses = (value: string): boolean => {
  let stack: string[] = [];
  for (let char of value) {
    if (char === '(') stack.push(char);
    else if (char === ')') {
      if (stack.length === 0) return false;
      stack.pop();
    }
  }
  return stack.length === 0;
};

const validateConditions = (value: string, maxConditions: number): boolean => {
  const numbers = value.match(/\d+/g) || [];
  return numbers.every(num => Number(num) <= maxConditions);
};

const validateComparisons = (value: string): boolean => {
    if (/\(\)/.test(value)) return false;
  
    const comparisons = value.match(/\([^\(\)]+\)/g) || [];
    
    for (let comparison of comparisons) {
      comparison = comparison.slice(1, -1);
      const parts = comparison.split(/Y|O/);
      if (parts.length !== 2 || parts.includes('')) {
        return false;
      }
    }
  
    const parts = value.split(/Y|O/);
    if (parts.some(part => part.trim() === '')) {
      return false;
    }
  
    return true;
  };
  

export const ConditionalFormSchema = DefaultStepSchema.concat(Yup.object().shape({
    condition: Yup.object().shape({
        cases: Yup.array().of(
            Yup.object().shape({
                caseId: Yup.string().required(),
                type: Yup.string().oneOf(casesTypes).required("Debes seleccionar la forma en que se evaluarán las condiciones."),
                conditionalStructure: Yup.string().when('type', {
                    is: 'PERSONALIZED',
                    then: Yup.string()
                    .matches(regex, 'Solo se permiten números, espacios, y los caracteres ( ) Y O')
                    .test('balanced-parentheses', 'Los paréntesis deben estar balanceados', value => validateParentheses(value || ''))
                    .test('valid-conditions', 'No se permiten condiciones mayores al número configurado', function(value) {
                        const { path, createError, parent } = this;
                        const maxConditions = parent.conditions.length || 4;
                        const numbers = value?.match(/\d+/g) || [];
                        
                        if (!numbers.every(num => Number(num) <= maxConditions)) {
                          return createError({ path, message: `No se permiten condiciones mayores a ${maxConditions}` });
                        }
                        
                        return true;
                      })
                    .test('valid-comparisons', 'Las comparaciones deben ser válidas', value => validateComparisons(value || ''))
                }),
                conditions: Yup.array().of(
                    Yup.object().shape({
                        answer: Yup.string().required('El valor 1 es requerido'),
                        type: Yup.string().oneOf(Object.values(EConditionTypes)).required(),
                        value: Yup.string().
                            when('type', {
                            is: (value: EConditionTypes) => optionalTypes.includes(value),
                            then: Yup.string(),
                            otherwise: Yup.string().required('El valor 2 es requerido')
                          }),
                    })
                )
            })
        ).test({
            test: (arr) => ( arr !== undefined && arr.length > 1),
            message: "Esta condición debe contener por lo menos una ramificación"
        })
    })
}));
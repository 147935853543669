import React, { useContext, useEffect, useReducer } from "react";
import { Form, Formik } from "formik";
import { EActions } from "../../../types/requests/bot/actions.enum";
import { MessageFormHsSchema, MessageFormSchema } from "../../../utils/schemas/message.schema";
import { DefaultStepSchema } from "../../../utils/schemas/required.schema";
import { SchemaOf } from "yup";
import { v4 as uuidv4 } from "uuid";
import withCurrentStep, {
    withCurrentStepProps,
} from "../../../HOC/withCurrentStep.hoc";
import {
    StepsFormContext,
    StepsFormContextType,
} from "../../../context/stepsForm.context";
import MessageActionFields from "../../UI/organisms/botForm/messageActionFields.component";
import { removeSpecialCharactersAndSpaces } from "../../../utils/pipes/strings.pipe";
import {
    FormBody,
    StepFormContainer,
    StepFormWrapper,
} from "../../UI/molecules/steps/step.styles";
import { IOption, IStep } from "../../../types/requests/bot/step.interface";
import StepFormHeader from "../../UI/organisms/botForm/stepFormHeader.component";
import FootBottons from "../../UI/molecules/steps/formFoot.component";
import HSFields from "../../UI/organisms/botForm/hsFields.component";
import { EMessageType } from "../../../types/requests/bot/stepType.enum";
import { BotFormContext, BotFormContextType } from "../../../context/botForm.context";
import Swal from "sweetalert2";
import GoAction from "../../UI/organisms/action/goAction.component";
import { ActionFormSchema } from "../../../utils/schemas/action.schema";
import GroupSubgroup from "../../UI/organisms/botForm/group/group.component";
import { GroupFormSchema } from "../../../utils/schemas/group.schema";
import RedirectAdvisor from "../../UI/organisms/botForm/advisor/advisor.component";
import { AlertForm, ICON } from "../../../utils/Alert";
import useBot from "../../../hooks/useBot.hook";

export interface SchemaReducerAction {
    type: EActions;
}

const schemaReducer = (state: SchemaOf<any>, action: SchemaReducerAction) => {
    switch (action.type) {
        case EActions.SEND_MESSAGE:
            return MessageFormSchema;
        case EActions.GO_ACTION:
            return ActionFormSchema;
        case EActions.GROUP_AND_SUBGROUP:
            return GroupFormSchema;
        case EActions.REDIRECT_ADVISOR:
            return GroupFormSchema;
        case EActions.GET:
            return MessageFormHsSchema;
        case EActions.SAVE_OR_UPDATE:
            return MessageFormHsSchema;
        default:
            return DefaultStepSchema;
    }
};
const mapOptions = (options: IOption[]) => {
    let casesObj: { [name: number]: string } = {}
    options.forEach((option, i) => {
        casesObj[i] = option.title;
    });

    return casesObj;
}
const StepForm: React.FC<withCurrentStepProps> = ({
    currentStep,
    bot,
    answersHandler,
}) => {

    const steps = bot!.steps;
    const UUID = uuidv4();
    const { stepsHandler, setBot } = useBot();
    const { setVisible, isNew } = useContext(StepsFormContext) as StepsFormContextType;
    const { setIsModified } = useContext(BotFormContext) as BotFormContextType;

    const [validationSchema, dispachValidationSchema] = useReducer(
        schemaReducer,
        DefaultStepSchema
    );

    useEffect(() => {
        dispachValidationSchema({ type: currentStep?.action?.type });
    }, [currentStep]);

    const handleContent = (UUID:string) => {
        switch (currentStep?.action?.type) {
            case EActions.SEND_MESSAGE:
                return (
                    <MessageActionFields
                        currentStep={currentStep}
                        steps={steps}
                        env={bot?.environment || []}
                        answers={answersHandler.Answers}
                        uuid={UUID}
                    />
                );
            case EActions.GET:
            case EActions.SAVE_OR_UPDATE:
                return (
                    <HSFields
                        stepsHandler={stepsHandler}
                        answersHandler={answersHandler}
                        currentStep={currentStep}
                        steps={steps}
                    />
                );
            case EActions.GO_ACTION:
                return (
                    <GoAction
                        currentStep={currentStep}
                        steps={steps}
                        env={bot?.environment || []}
                        answers={answersHandler.Answers}
                    />
                );
            case EActions.GROUP_AND_SUBGROUP:
                return (
                    <GroupSubgroup
                        currentStep={currentStep}
                        steps={steps}
                        env={bot?.environment || []}
                        answers={answersHandler.Answers}
                    />
                );
            case EActions.REDIRECT_ADVISOR:
                return (
                    <RedirectAdvisor
                        currentStep={currentStep}
                        steps={steps}
                        env={bot?.environment || []}
                        answers={answersHandler.Answers}
                    />
                );
            default:
                return <>En construcción...</>;
        }
    };

    const handleRemove = async () => {
        if (currentStep.type !== "go_action" && currentStep?.parentStep?.caseId) {
            const parentStep = stepsHandler.steps.find(
                (step) => step.id === currentStep?.parentStep?.parent
            );
            if (parentStep && parentStep.options && parentStep.options.length) {
                const optionIndex = parentStep.options.findIndex(
                    (option) => option.id === currentStep?.parentStep?.caseId
                );
                if (optionIndex !== -1) {
                    parentStep.options[optionIndex].next = currentStep.previous === parentStep.id ? currentStep.next: parentStep.options[optionIndex].next;
                    stepsHandler.setStep(parentStep.id, parentStep);
                }
            }
        }

        if(![EMessageType.NORMAL_MESSAGE, EMessageType.MULTIMEDIA, EMessageType.GO_ACTION, EMessageType.START].includes(currentStep.type) ) {
            const options = currentStep!.options!.filter(el => el.next);

            const { value: caseIndex, isDismissed } = await Swal.fire({
                title: "Desconexión de pasos",
                showCancelButton: true,
                cancelButtonText: "Cancelar eliminación",
                input: options.length > 0 ? "select": undefined,
                text: "Las pasos siguientes han sido desconectados del flujo del bot.",
                inputOptions: {
                    '-1': "No conservar ninguna ramificación",
                    ...mapOptions(options)
                }
            })
            if (!isDismissed) {
                const prevStep = stepsHandler.getStep(currentStep?.previous ?? "");
                stepsHandler.setBranch(options[caseIndex]?.next, {
                    previous: prevStep?.id,
                    parentStep: prevStep?.parentStep
                }, currentStep.id)

                stepsHandler.removeBranchsOptions(
                    options.filter((conditionalCase) => conditionalCase.id !== options[caseIndex]?.id)
                )
                if(currentStep.answer) {
                    let stepsAnswerId = stepsHandler.getStepAnswer(currentStep.answer);
                    if(stepsAnswerId.length === 1) answersHandler.removeAnswer(currentStep.answer);
                }
                if(currentStep.hubspotValues?.length) {
                    for (const item of currentStep.hubspotValues) {
                        let stepsAnswerId = stepsHandler.getStepAnswerHS(item.answer);
                        if(stepsAnswerId.length === 1) answersHandler.removeAnswer(item.answer);
                    }
                }
                stepsHandler.removeStep(
                    currentStep.id,
                    options[caseIndex]?.next,
                    currentStep.finish,
                    currentStep?.parentStep?.caseId //APLICA PARA NODOS HIJOS DEL COMPONENTE CONDICIONAL
                );
                let stepsOption = stepsHandler.getBranch(options[caseIndex]?.next, "next");
                    
                let findComponentGoAction = stepsOption.find(step => stepsHandler.getStep(step)?.type === EMessageType.GO_ACTION );                
                
                if(findComponentGoAction){
                    let dataStep = stepsHandler.getStep(findComponentGoAction);
                    stepsHandler.removeStep(findComponentGoAction,undefined, true, dataStep?.parentStep?.caseId)
                }
                await setBot.mutateAsync({
                    botProps: {
                        answers: answersHandler.Answers,
                        steps: stepsHandler.steps,
                    },
                    notification: {register: `Borrar nodo de Paso: ${currentStep.name} (${currentStep.action.type})`, url: window.location.href}
                });
                setVisible(false);
                setIsModified(true);
            }
        } else {
            
            if(currentStep.answer) {
                let stepsAnswerId = stepsHandler.getStepAnswer(currentStep.answer);
                if(stepsAnswerId.length === 1) answersHandler.removeAnswer(currentStep.answer);
            }
            stepsHandler.removeStep(
                currentStep.id,
                currentStep.next,
                currentStep.finish,
                currentStep?.parentStep?.caseId
            );
           if (currentStep.type === "normal_message") {
             stepsHandler.deleteActionComponent(currentStep.id);
           }
            await setBot.mutateAsync({
                botProps: {
                    answers: answersHandler.Answers,
                    steps: stepsHandler.steps,
                },
                notification: {register: `Borrar nodo de Paso: ${currentStep.name} (${currentStep.action.type})`, url: window.location.href}
            });
            setVisible(false);
            setIsModified(true);
        }
    };

    const generateAnswer = (uuid: string, storeName: string) => {
        let answer = {
            id: uuid,
            label: storeName,
            code: removeSpecialCharactersAndSpaces(uuid + "__" + storeName),
        };
        return answer;
    };
    const extraerContenido = (str:string) => {
        const regex = /{{(.*?)}}/;
        const match = str.match(regex);
        return match ? match[1] : "";
      }
    return (
        
        <StepFormWrapper>
            <StepFormContainer visible={true}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        ...currentStep,
                        answer: currentStep?.answer,
                        step_store_answer:
                            answersHandler.getAnswer(currentStep?.answer!)?.label ?? "",
                        options: [],
                        go_to: "",
                        inactivity_messages: currentStep.inactivity_messages,
                        hubspotValues: [],
                        deleteValues: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async ({ step_store_answer, ...values }) => {
                        let updatedStep: IStep = {
                            ...values,
                            inactivity_messages: values?.answer ? values.inactivity_messages : false,
                            answer: values.answer
                        };

                        let optionConfig: { next: undefined | string, id: undefined | string, title: string } = { next: undefined, id: undefined, title: "" }
                        let lengthOptionsUpdate = updatedStep.options.length;
                        let lengthOptionsCurrent = currentStep.options.length;
                        if (updatedStep.next && isNew && [EMessageType.BUTTONS_MESSAGE, EMessageType.MENU_MESSAGE, EMessageType.HUBSPOT].includes(updatedStep.type) && (lengthOptionsUpdate === lengthOptionsCurrent || lengthOptionsCurrent === 0)) {
                            const options = updatedStep!.options;
                            const { value: caseIndex } = await Swal.fire({
                                title: "Mover acciones posteriores a la opción",
                                input: 'select',
                                inputOptions: mapOptions(options),
                            }); 
                           
                            if(caseIndex) {
                                optionConfig.next = updatedStep.next;
                                optionConfig.id = updatedStep?.options[caseIndex].id;
                                optionConfig.title = updatedStep?.options[caseIndex].title;
    
                                updatedStep.options[caseIndex].next = updatedStep.next;
                                //updatedStep.next = undefined;
                                let propsStep = {
                                    parentStep: {
                                        parent: updatedStep.id,
                                        caseId: updatedStep.id + "_" + caseIndex
                                      },
                                }
                                stepsHandler.setStep(updatedStep.next, propsStep)
                            }
                            
                        }
   
                        if(updatedStep.options.length !== currentStep.options.length) {
                            if(updatedStep.options.length < currentStep.options.length){
                                for (const option of currentStep.options) {
                                    let filter = stepsHandler.getStepsByCaseId(option.id);
                                    if(option.title === "No cumple" && filter.length) return AlertForm({title: "No se puede modificar opciones", text: "Valida que no tenga configuracion en No cumple", icon: ICON.WARNING});
                                    let diference = lengthOptionsCurrent - lengthOptionsUpdate;
                                    let currentIndexOption = Number(option.id.split("_")[1]);
                                    if(lengthOptionsCurrent - 1 - diference <= currentIndexOption){
                                        if(filter.length) return AlertForm({title: "No se puede modificar opciones", text: "Valida que no tenga configuracion en las opciones", icon: ICON.WARNING});
                                     }
                                }
                            }
                        }
                         
                        switch (currentStep.action.type) {
                            case EActions.SEND_MESSAGE:
                                if (step_store_answer) {
                                    let allAnswers = answersHandler.Answers;
                                    let findAnswer = allAnswers.find(answer => updatedStep.answer === answer.id);
                                    let findLabel = allAnswers.find(answer => step_store_answer === answer.label);
                                    if(findAnswer && !findLabel) {
                                        let allSteps = stepsHandler.steps;
                                        for (const step of allSteps) {
                                            let stringData = JSON.stringify(step);
                                            let matchVariable = extraerContenido(stringData);
                                            if(matchVariable === "") continue;
                                            let ansCode = generateAnswer(findAnswer.id, step_store_answer)
                                            let replaceBotVariable = stringData.replaceAll(matchVariable, "bot." + ansCode.code);
                                            let dataParse = JSON.parse(replaceBotVariable);
                                            stepsHandler.setStep(dataParse.id, dataParse);
                                        }
                                    }
                                    let idGenerateAnswer = findAnswer ? findAnswer.id: findLabel ? findLabel.id: UUID;
                                    answersHandler.addAnswers(
                                        [idGenerateAnswer],
                                        [generateAnswer(
                                            idGenerateAnswer,
                                            step_store_answer
                                        )]
                                    );
                                    
                                    updatedStep.answer = idGenerateAnswer;
                                }
                                break;
                            case EActions.GET:
                                if(values.hubspotValues.length) {
                                    let generateAnswersArray = [];
                                    let generateUUID = [];
                                    let allAnswers = answersHandler.Answers;
                                    for (const value of updatedStep.hubspotValues!) {
                                        let findAnswer = allAnswers.find(answer => value.answer === answer.id);
                                        let findLabel = allAnswers.find(answer => "(HS) " + value.nameAnswerProperty === answer.label);
                                        if(findAnswer && !findLabel) {
                                            let allSteps = stepsHandler.steps;
                                            for (const step of allSteps) {
                                                let stringData = JSON.stringify(step);
                                                let matchVariable = extraerContenido(stringData);
                                                if(matchVariable === "") continue;
                                                let ansCode = generateAnswer(findAnswer.id, "(HS) " + value.nameAnswerProperty)
                                                let replaceBotVariable = stringData.replaceAll(matchVariable, "bot." + ansCode.code);
                                                let dataParse = JSON.parse(replaceBotVariable);
                                                stepsHandler.setStep(dataParse.id, dataParse);
                                            }
                                        }
                                        const UUID = uuidv4();
                                        let idGenerateAnswer = findAnswer ? findAnswer.id: findLabel ? findLabel.id: UUID;
                                        generateAnswersArray.push(generateAnswer(
                                            idGenerateAnswer,
                                            "(HS) " + value.nameAnswerProperty
                                        ));
                                        generateUUID.push(idGenerateAnswer);
                                        value.answer = idGenerateAnswer;
                                    }
                                    for (const item of values.deleteValues) {
                                        answersHandler.removeAnswer(item);
                                    }
                                    answersHandler.addAnswers(
                                        generateUUID,
                                        generateAnswersArray
                                    )
                                }
                                break;
                            default:
                                break;
                        }
                        
                        stepsHandler.setStep(updatedStep.id, updatedStep);

                        await setBot.mutateAsync({
                            botProps: {
                                steps: stepsHandler.steps,
                                answers: answersHandler.Answers,
                            },
                            notification: {register: `Agregar nodo de Paso: ${values.name} (${values.action.type})`, url: window.location.href}
                        });
                        setIsModified(true);
                        setVisible(false);
                    }}
                >
                    {({ errors, values, handleChange }) => {

                        return (
                            <Form>
                                <StepFormHeader  />
                                <FormBody>
                                    {handleContent(UUID)}
                                    <FootBottons handleRemove={handleRemove} />
                                </FormBody>
                            </Form>
                        );
                    }}
                </Formik>
            </StepFormContainer>
        </StepFormWrapper>

    );
};

export default withCurrentStep(StepForm, React.Fragment, "edit");

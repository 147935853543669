import styled from 'styled-components';

export const LoadingWraper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  height: 100%;
  z-index: 2;
  background-image: url('/minifindstore_spin.gif');
  background-repeat: no-repeat;
  background-position: center;
`;

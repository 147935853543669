import React, { useState, useEffect } from 'react';
import BotWarningImg from '../../../../assets/images/botWarning.svg';
import BotQuestionImg from '../../../../assets/images/botQuestion.svg';
import Button from '../../atoms/button/button.component';
import { HttpStatusCode, isAxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

export interface ErrorProps {
    action?: React.MouseEventHandler<HTMLButtonElement>;
    actionMessage?: string;
    error: any;
    forceErrorCode?: HttpStatusCode;
}

interface ErrorContent {
    head: string,
    body: string,
    img: string
}

const errorMessages: { [name: string | number]: ErrorContent } = {
    default: {
        head: "Oops..., Ha ocurrido un error inesperado",
        body: "Hemos presentado inconvenientes para presentar la información requerida. Intente nuevamente.",
        img: BotWarningImg
    },
    404: {
        head: "Error 404, elemento no encontrado",
        body: "No ha sido posible encontrar el elemento solicitado.",
        img: BotQuestionImg
    },
    500: {
        head: "Error 500, error interno",
        body: "Ha ocurrido un error interno, por favor intente nuevamente.",
        img: BotWarningImg
    }
}

const Error: React.FC<ErrorProps> = ({ action, error, forceErrorCode, actionMessage = "Regresar al inicio" }) => {
    const [content, setContent] = useState<ErrorContent>(errorMessages[forceErrorCode ?? "default"]);
    
    const navigator = useNavigate();

    useEffect(() => {
        if (!forceErrorCode) {
            if (isAxiosError(error)) {
                setContent(errorMessages[error?.response?.status ?? "default"]);
            } else {
                setContent(errorMessages["default"]);
            }
        }
    }, [error, forceErrorCode]);

    return (
        <div className='d-flex m-auto justify-content-center align-items-center w-auto px-2 p-4'>
            <div className='me-2' style={{ maxWidth: "416px", width: "max-content" }}>
                <h5 className='fw-medium'>{content.head}</h5>
                <p className='fw-light'>
                    {content.body}
                    {
                        error?.response?.data?.message &&
                        <>
                            <br />
                            Detalles: {error?.response?.data?.message}
                        </>
                    }
                </p>
                <Button onClick={action ? action : () => navigator("/", { replace: true })} btnType="primary">{actionMessage}</Button>
            </div>

            <img src={content.img} alt="" />
        </div>
    )
}

export default Error;
import React from 'react';
import { IconProps } from './sendIcon.component';
import { EColors } from '../../../../types/styles/colors.enum';

const CloseIcon: React.FC<IconProps> = ({ colorCode = EColors.primary, ...props }) => {
    return (
        <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L13 13M13 1L1 13" stroke={colorCode} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CloseIcon;
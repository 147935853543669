import React, { useCallback, useEffect, useRef, useState } from "react";

import "@yaireo/tagify/dist/tagify.css";
import Tagify, { TagifySettings } from '@yaireo/tagify'
import Button from "../../UI/atoms/button/button.component";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import { IStep } from "../../../types/requests/bot/step.interface";
import useBot from "../../../hooks/useBot.hook";
import { TagStyles } from "./tag.styles";
import { zeroWidthTrim } from "../../../utils/removeCharacters";
import InputError from "../../UI/atoms/input/inputError.component";
import { removeSpecialCharactersAndSpaces } from "../../../utils/pipes/strings.pipe";

import { Tooltip, TooltipContent, TooltipTrigger } from '../../UI/atoms/floatingElements/tooltip.component';
import BotQuestion from "../../../assets/images/question.svg";
//import {MixedTags} from "./tagify/react.tagify"
// Tagify settings object


export type MixedTagsProps = FieldHookConfig<string> & {
    id: string;
    label: string;
    disabled?: boolean;
    readonly?: boolean;
    className?: string;
    placeholder?: string;
    viewCount?: boolean;
    maxCount?: number;
    textHelp?: string;
};
export type IWhiteList = {
    value: string,
    prefix?: string,
};
const MixedTagInput: React.FC<MixedTagsProps> = ({className = "", value, label, placeholder, disabled = false, readonly = false, textHelp, ...props}) => {
    const formik = useFormikContext<IStep>();
    const [field, meta] = useField(props);
    const { bot } = useBot();
    const [whitelist, setWhitelist] = useState<IWhiteList[]>([]);
    const [valueInput, setValueInput] = useState<string>("");

    const settings: TagifySettings = {
        mode: 'mix', 
        pattern: /@|#/, // <- must define "patten" in mixed mode
        mixTagsInterpolator: ["{", "}"],
        dropdown: {
            enabled: 0,
            position: "all",
            classname: "color-blue",
            mapValueTo: "title",
            includeSelectedTags: true
        },
        duplicates: true,
        whitelist: whitelist,
        enforceWhitelist: true,
        editTags: {
            clicks: 2, // single click to edit a tag
            keepInvalid: false, // if after editing, tag is invalid, auto-revert   
        },
        tagTextProp: "title",
    } ;
    const inputElem = document.getElementById(props.id) as HTMLTextAreaElement;
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        setValueInput(value);
    }, [])
    
    useEffect(() => {
        // Obtener variable 
        // global.phone, global.publicName, global.firstText
        let dataValue = []
        for (const item of bot!.environment) {
            dataValue.push({value: `env.${removeSpecialCharactersAndSpaces(item.name)}`, title: `ENV ${item.name}`  })
        }
        for (const answer of bot!.answers) {
            dataValue.push({ value: `bot.${answer.code}`, title: `BOT ${answer.label}`  })
        }
        setWhitelist([
            { value: "global.phone", title: "GLOBAL Phone" },
            { value: "global.publicName", title: "GLOBAL Public Name"},
            { value: "global.firstText", title: "GLOBAL First Text" },
            { value: "global.vid", title: "GLOBAL VID" },
            { value: "global.lineNumber", title: "GLOBAL Line Number" },
            ...dataValue
            ])
    }, [bot]);

    const formatText = (texto:string) => {
        // Patrón de expresión regular para buscar y reemplazar el texto adecuado
        var patron = /{{"value":\s*"((?:global|env|bot)\.[\w.]+)"(?:[^}]*)}}/g;

        // Reemplazar el texto correspondiente en el texto dado
        return texto.replace(patron, '{{$1}}');
    }

    useEffect(() => {
            // the 'input' element which will be transformed into a Tagify component
            if(whitelist.length) {
                const tagify = new Tagify(inputElem , settings);
                if(!tagify) return;
                tagify.setDisabled(disabled);
                tagify.setReadonly(readonly);
                tagify.on('input',(e:any) =>  {
                    setTimeout(() => {
                        let value = e.detail.tagify.DOM.originalInput.value;
                        
                        let variableData = formatText(value);
                        if(variableData.length > (props.maxCount || 100)) {
                            variableData = variableData.slice(0, (props.maxCount || 100) - variableData.length);
                            tagify.update();
                        }
                        formik.setFieldValue(props.name, zeroWidthTrim(variableData));
                        setValueInput(variableData);
                    }, 500)});

                tagify.on('change',(e:any) => {
                    const value = e.detail.value;
                    const variableData = formatText(value);
                    
                    formik.setFieldValue(props.name, zeroWidthTrim(variableData));
                    setValueInput(variableData);
                })

                tagify.on('add', function(e){
                    // remove last added tag if the total length exceeds
                    let value = e.detail.tagify.DOM.originalInput.value;
                    const variableData = formatText(value);
                    if( variableData.length > (props.maxCount || 100) ) {
                        tagify.removeTags();
                    }
                    setValueInput(variableData);
                })

                tagify.whitelist = whitelist;
            }
    }, [whitelist])  

    return (
        <TagStyles className="form-group">


            {label ? <label className="form-label" htmlFor={props.id}>
                {label} {textHelp && <Tooltip placement="top-start">
                            <TooltipTrigger> <img src={BotQuestion} alt="help" width={"16"} /> </TooltipTrigger>
                            <TooltipContent className="Tooltip">{textHelp}</TooltipContent>
                        </Tooltip>}
            </label>: ""} 
                <input id={props.id} ref={inputRef} defaultValue={value} placeholder={placeholder} max={props.maxCount}/>
                <div className="d-flex justify-content-between">
                    {meta.error && (
                        <InputError> {meta.error} </InputError>
                    )}
                    {props.viewCount && <div className={`count-input ${valueInput.length > (props.maxCount || 100) && "red"}`}>{valueInput.length}/{props.maxCount || 100}</div>}
                </div>
                
                
        </TagStyles>
    );
};

export default MixedTagInput;

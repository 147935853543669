import * as Yup from "yup";
import { EActions } from "../../types/requests/bot/actions.enum";

export const DefaultStepSchema = Yup.object().shape({
    id: Yup.string().required("Todos los pasos deben contener un identificador."),
    name: Yup.string().required("Este paso debe tener un nombre."),
    action: Yup.object().shape({
        type: Yup.string().oneOf(Object.values(EActions), {
            message: "La acción seleccionada no es válida."
        }).required("Debe seleccionar una acción."),
    }),
    options: Yup.array().of(
        Yup.object().shape({
            id: Yup.string().required('Required Option ID'),
            title: Yup.string().required('Required Option Title')
        })
    )
});
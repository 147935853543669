import React from "react";
import { EActions } from "../../../../types/requests/bot/actions.enum";
import SendIcon, { IconProps } from "../icon/sendIcon.component";
import ConditionIcon from "../icon/conditionIcon.component";
import RecoveryIcon from "../icon/recoveryIcon.component";
import SaveIcon from "../icon/saveIcon.component";
import ActionIcon from "../icon/actionIcon.component";
import GroupIcon from "../icon/groupIcon.component";
import AdvisorIcon from "../icon/advisorIcon.component";


export interface StepIconProps extends IconProps {
    type: EActions;
}

const StepIcon: React.FC<StepIconProps> = ({ type, colorCode = "white", ...props }) => {
    switch (type) {
        case EActions.SEND_MESSAGE:
            return <SendIcon colorCode={colorCode} {...props} />

        case EActions.CONDITION:
            return <ConditionIcon colorCode={colorCode} {...props} />

        case EActions.GET:
            return <RecoveryIcon colorCode={colorCode} {...props} />

        case EActions.SAVE_OR_UPDATE:
            return <SaveIcon colorCode={colorCode} {...props} />
        case EActions.GO_ACTION:
            return <ActionIcon colorCode={colorCode} {...props} />
        case EActions.GROUP_AND_SUBGROUP:
            return <GroupIcon colorCode={colorCode} {...props} />
        case EActions.REDIRECT_ADVISOR:
            return <AdvisorIcon colorCode={colorCode} {...props} />
        default:
            return <></>;
    }
}

export default StepIcon;
import React, { useEffect, useState } from 'react';
import { getTokens, updateTokens } from '../../services/tokens.service';
import TokenForm from '../../components/templates/tokens/tokenForm.template';
import { AlertConfirm } from '../../utils/Alert';
import PaginationTable from '../../components/UI/organisms/tables/PaginationTable';
import usePagination from '../../hooks/usePagination';
import ConnectionListRow from '../../components/Connection/ConnectionListRow';

export interface ConnectionPageProps { }
export interface TokenFormatted {
    id: string,
    number: string,
    bot: {
        id: string,
        name: string
    },
    group: string,
    subgroup: string,
}
const initToken = {
    id: "",
    number: "",
    bot: {
        id: "",
        name: ""
    },
    group: "",
    subgroup: "",
}

const ConnectionPage: React.FC<ConnectionPageProps> = () => {
    const [dataTokens, setDataTokens] = useState<TokenFormatted[]>([]);
    const { paginationPages, setCurrentPage, showedData } = usePagination({ data: dataTokens, showRows: 10 });
    const [dataToken, setDataToken] = useState<TokenFormatted>(initToken);
    const [visibleForm, setVisibleForm] = useState<boolean>(false);
    const setData = async() => {
        let getListTokens = await getTokens();
        let tokensFormatted = getListTokens.map(el => ({id: el._id ,number: el.number, bot: {name: el.flow?.name || "", id: el.flow?._id}, group: el.group, subgroup:el.subgroup}));
        setDataTokens(tokensFormatted);
    }

    useEffect(() => {
        setData()
    }, [])
    
    const handleEditNumber = (token: TokenFormatted) => {
        setDataToken(token);
        setVisibleForm(true);
    }

    const handleChangeStatus = async(token: TokenFormatted) => {
        if(token.bot.id){
            let response = await AlertConfirm({title: "Desactivar estado?", text: "Se borrara la configuración actual y debe configurarlo nuevamente"});
            if(response.isConfirmed) {
                await updateTokens(token.id, {
                    token: {
                        flow: null,
                        group: token.group,
                        subgroup: token.subgroup
                    }},
                    {
                        register: `Numero desactivado: +${token.number}`,
                        url: window.location.href
                    }
                    );
                await setData();
            }
        }
    }
    return (
        <div className='container-lg w-50 m-auto' style={{padding: "4rem 0"}}>
            <h3>Conectar números con Bots</h3>

            <div>
            <PaginationTable
                headers={["Numero", "Nombre del Bot", "Estado", "Acciones"]} 
                withoutRight={false}
                content={showedData}
                pages={paginationPages}
                setPages={setCurrentPage}
                row={ConnectionListRow}
                updateRow={handleEditNumber}
                handleChangeStatus={handleChangeStatus}
                />
            
            </div>
            <TokenForm token={dataToken} setDataTokens={setDataTokens} visible={visibleForm} setVisible={setVisibleForm} />
        </div>
    );
}

export default ConnectionPage;
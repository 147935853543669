import { Form, Formik } from 'formik';
import React from 'react';
import { FloatForm, FormBody, FormHeader } from '../../UI/molecules/steps/step.styles';
import SelectBox from '../../UI/molecules/inputs/selectBox.component';
import { EChannels } from '../../../types/requests/accounts/account.interface';
import InputBox, { InputBoxProps } from '../../UI/molecules/inputs/inputBox.component';
import IAccount from "../../../types/requests/accounts/account.interface";
import FootBottons from '../../UI/molecules/steps/formFoot.component';

export interface AccountFormProps {
    visible: boolean;
}

const values: Partial<IAccount> = {}

const wppFields: InputBoxProps[] = [
    {
        name: 'connection.token',
        label: "Token",
        id: "token"
    }
]

const fbFields: InputBoxProps[] = [
    {
        name: 'connection.auth_token',
        label: "Token",
        id: "token"
    },
    {
        name: 'connection.account_id',
        label: "ID de cuenta (Account_id)",
        id: "account_id"
    }
]

const fields = {
    [EChannels.FB]: fbFields,
    [EChannels.WPP]: wppFields
}

const AccountForm: React.FC<AccountFormProps> = ({ visible }) => {

    return (
        <FloatForm mode='create' visible={visible}>
            <Formik
                initialValues={values}
                onSubmit={(values) => { console.log(values) }}
            >
                {({ values }) => (
                    <Form>
                        <FormHeader>Agregar nueva cuenta</FormHeader>

                        <FormBody>
                            <SelectBox
                                id='channel'
                                label='Seleccione el canal de la cuenta'
                                name='channel'
                            >
                                <option>Selecciona un canal</option>
                                <option value={EChannels.WPP}>WhatsApp</option>
                                <option value={EChannels.FB}>Facebook</option>
                            </SelectBox>

                            <InputBox
                                id='sender'
                                name='sender'
                                label="Ingrese el número / ID de la cuenta"
                                placeholder='Ejm: +573124522331'
                            />

                            {
                                values?.channel && (
                                    <>
                                        {
                                            fields[values.channel]?.map((field, i) => {
                                                return (
                                                    <InputBox
                                                        key={i}
                                                        {...field}
                                                    />
                                                )
                                            })
                                        }
                                    </>
                                )
                            }

                            <FootBottons
                                handleRemove={() => { }}
                                submit='Guardar cuenta'
                                cancel='Cancelar'
                            />

                        </FormBody>
                    </Form>
                )}
            </Formik>
        </FloatForm>

    );
}

export default AccountForm;
import React, { useContext } from 'react';
import { IStep } from '../../../../types/requests/bot/step.interface';
import withSelectStep, { withSelectStepProps } from '../../../../HOC/withSelectStep.hoc';
import { StepsFormContext, StepsFormContextType } from '../../../../context/stepsForm.context';
import { ICase } from '../../../../types/requests/bot/case.interface';
import Condition from './condition.component';
import { ConditionalConnectors, StepBase, StepContent, StepHeader } from './step.styles';
import { StepTable } from "../../atoms/wrapper/stepTable.component";
import Tag, { TagTypes } from '../../atoms/tag/tag.component';
import ConditionIcon from '../../atoms/icon/conditionIcon.component';

const Conditional: React.FC<IStep & withSelectStepProps> = ({ id, name, action, parentStep, condition, handleSelectStep }) => {
    
    const { currentStepId } = useContext(StepsFormContext) as StepsFormContextType;

    const handleConditions = ({ conditions, type, conditionalStructure } : ICase, branch: number) => {
        let returnedConditions: JSX.Element | (string | JSX.Element)[] = <></>;

        if (type === "PERSONALIZED") { 

            let conditionsKeys = conditions.map((con, i) => <Condition {...con} idx={i} row={branch} prefix={`preview-${id}`} key={i} />);

            const replaceIntoString = (str: string, conditionalIndex: number): any => {
                let structure;
                if (conditionsKeys[conditionalIndex] !== undefined) {
                    structure = str.split((conditionalIndex + 1).toString()).flatMap((el,idx) => [replaceIntoString(el, conditionalIndex + 1), {...conditionsKeys[conditionalIndex], props: {...conditionsKeys[conditionalIndex].props, number: idx}}]);
                    structure.pop();

                    return structure;
                } else {
                    return str;
                }
            }

            returnedConditions = replaceIntoString(conditionalStructure, 0);
        } else {

            let structuratedConditions = conditions.flatMap((con, i) => [<Condition {...con} idx={i} row={branch} prefix={`preview-${id}`} key={i} />, (type === "AND") ? "Y" : "O"]);

            structuratedConditions.pop();
            returnedConditions = structuratedConditions;
        }

        return returnedConditions;
    }

    return (
        <StepBase id={id} active={(id === currentStepId)} onClick={() => handleSelectStep(id, undefined, 'conditional')}>
            <StepHeader>{name}</StepHeader>
            <StepContent>
                <Tag type={TagTypes.primary} className='mx-auto'><ConditionIcon width="16" height="16" colorCode='white' /> Ramificación:</Tag>

                <p className='m-0 fw-light'>Las condiciones evaluadas son:</p>

                <StepTable>
                    <thead>
                        <tr>
                            <th>Rama</th>
                            <th>Condición</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            condition?.cases.map((conditionalCase, i) => {

                                if (conditionalCase.type === 'NO_MATCHES') return null;

                                return (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td className='tag-text'>Si {handleConditions(conditionalCase, i + 1)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </StepTable>
            </StepContent>
            <div className='py-3'></div>
            <ConditionalConnectors>
                {condition?.cases.map((conditionalCase, i) => (
                    <Tag
                        id={conditionalCase.caseId}
                        type={TagTypes.ramification}
                        key={"connector-" + conditionalCase.caseId}
                    >
                        {
                            conditionalCase.type === 'NO_MATCHES' ?
                                "No se cumple ninguna"
                                :
                                `Ramificación ${i + 1}`}
                    </Tag>
                ))}
            </ConditionalConnectors>
        </StepBase>
    )
}

export default withSelectStep<IStep>(Conditional);
import { IBot } from "../../types/requests/bot/bot.interface";
import { IDraftBot, IDraftBotResponse } from "../../types/requests/bot/draftbot.interface";
import { IStep } from "../../types/requests/bot/step.interface";

export const compareArrays = (arr1: IStep[], arr2: IStep[]): boolean => {
    // Primero, verificamos si tienen la misma longitud
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    // Luego, comparamos cada objeto en el mismo índice de ambos arrays
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false;
      }
    }
  
    return true;
  }

  export const compareObjects = (obj1: IDraftBotResponse, obj2: IBot): boolean => {
    let name = obj1.name === obj2.name;
    let finish_last_message = obj1.finish_last_message === obj2.finish_last_message;
    let reattempts_agent = obj1.reattempts_agent === obj2.reattempts_agent;
    let stop_notification = JSON.stringify(obj1.stop_notifications) === JSON.stringify(obj2.stop_notifications);
    let send_agent = JSON.stringify(obj1.send_agent) === JSON.stringify(obj2.send_agent);
    let inactive_message = JSON.stringify(obj1.inactive_message) === JSON.stringify(obj2.inactive_message);
    let hubspot_default_values = JSON.stringify(obj1.hubspot_default_values) === JSON.stringify(obj2.hubspot_default_values);
    let message_failure_process = JSON.stringify(obj1.message_failure_process) === JSON.stringify(obj2.message_failure_process);
    return name && finish_last_message && reattempts_agent && stop_notification && send_agent && inactive_message && hubspot_default_values && message_failure_process;
  }
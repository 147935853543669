import styled from "styled-components";
import { EColors } from "../../../../types/styles/colors.enum";

const CardWrapper = styled.div`
    border: solid 1px ${EColors.text};
    padding: 16px;
    background: ${EColors.lightGray};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    max-width: 250px;
    justify-content: space-between;
    min-height: 200px;
`;

export default CardWrapper;
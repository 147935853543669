import React from 'react';
import TableWrapper from '../../atoms/wrapper/table.component';
import LoaderWrapper from '../../atoms/wrapper/loaderWrapper.component';

export interface TableProps {
    headers: React.ReactNode[],
    children: React.ReactNode
}

const Table: React.FC<TableProps> = ({ headers, children }) => {
    return (
        <TableWrapper>
            <thead>
                <tr>
                    <LoaderWrapper isLoading={false} loader={<>Cargando...</>}>
                        {
                            headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))
                        }
                    </LoaderWrapper>
                </tr>
            </thead>
            <tbody>
                <LoaderWrapper loader={<>Cargando...</>} isLoading={false}>
                    {children}
                </LoaderWrapper>
            </tbody>
        </TableWrapper>
    )
}

export default Table;
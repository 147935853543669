import React from 'react';
import GlobalStyle from './styles';
import 'tippy.js/dist/tippy.css';
import 'react-loading-skeleton/dist/skeleton.css';
import Router from './router/router.component';
import { QueryClientProvider, QueryClient } from 'react-query';

const queryClient = new QueryClient();

const App: React.FC = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <GlobalStyle />
            <Router />
        </QueryClientProvider>
    );
}

export default App;

import styled from "styled-components";

export const TopbarWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  left: auto;
  background-color: #4d4d4d;
  color: white;

  & > .static-top {
    height: 3.5rem;
    & div {
      font-weight: bold;
    }
  }
  & .navbar-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

import styled from "styled-components";

export const PaginationSeparator = styled.div`
  background-color: ${props => props.active ? "#3D3D3D" : "transparent"};
  color: #3D3D3D;
  padding: 4px 0;
  font-weight: 500;
  font-size: 14px;
  margin: 8px 0;
  width: max-content;
  height: max-content;
  border: none;
  border-radius: 4px;
  line-height: 2;

`
import React, { useState } from 'react';
import { EColors } from '../../types/styles/colors.enum';
import SettingsIcon from '../UI/atoms/icon/settingsIcon.component';

export interface ConnectionListProps {
    id:string,
    bot: {
        name: string,
        id: string,
    },
    group: string,
    subgroup: string,
    number: string,
    i: number,
    updateRow: (data: any) => any;
    handleChangeStatus: (data: any) => any;
}
const ConnectionListRow: React.FC<ConnectionListProps> = ({id, bot, number, group, subgroup, i:idx, updateRow, handleChangeStatus}) => {
    return (
        <tr>
            <td>+{number}</td>
            <td> <div>{bot.name || "Sin Configurar"}</div> </td>
            <td>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id={`token-${idx}`} checked={bot.id ? true: false} onClick={() => handleChangeStatus({id, bot, number, group, subgroup})}/>
                    <label className="form-check-label" htmlFor={`token-${idx}`}>{bot.id ? "Activo": "Inactivo"}</label>
                </div>
            </td>

            <td> <SettingsIcon colorCode={EColors.secondary} width="32px" onClick={() => updateRow({id, bot, number, group, subgroup})} /></td>
        </tr>
    )
}
export default ConnectionListRow;
import React from 'react';
import Error, { ErrorProps } from '../../molecules/error/error.component';

interface ErrorWrapperProps extends ErrorProps {
    children: React.ReactNode;
} 

const ErrorWrapper: React.FC<ErrorWrapperProps> = ({ error, action, actionMessage, children }) => {
    if (error) {
        return <Error error={error} action={action} actionMessage={actionMessage} />
    } else {
        return <>{children}</>;
    }
}

export default ErrorWrapper;
import { useEffect, useCallback, useRef, useState } from "react";

interface Position {
  top: number;
  left: number;
  x: number;
  y: number;
}

const initialState: Position = { top: 0, left: 0, x: 0, y: 0 };

const useScrollOnDrag = () => {
  const [position, setPosition] = useState<Position>(initialState);
  const scrolleableRef = useRef<HTMLDivElement | null>(null);

  const mouseMoveHandler = useCallback((e: MouseEvent) => {
    if (scrolleableRef.current) {
      const dx = e.clientX - position.x;
      const dy = e.clientY - position.y;
      scrolleableRef.current.scrollTop = position.top - dy;
      scrolleableRef.current.scrollLeft = position.left - dx;
    }
  }, [position]);

  const mouseUpHandler = useCallback(() => {
    if (scrolleableRef.current) {
      scrolleableRef.current.removeEventListener('mousemove', mouseMoveHandler);
      scrolleableRef.current.removeEventListener('mouseup', mouseUpHandler);
      scrolleableRef.current.style.cursor = 'grab';
      scrolleableRef.current.style.removeProperty('user-select');
    }
  }, [mouseMoveHandler]);

  const mouseDownHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (scrolleableRef.current) {
      setPosition({
        left: scrolleableRef.current.scrollLeft,
        top: scrolleableRef.current.scrollTop,
        x: e.clientX,
        y: e.clientY
      });

      scrolleableRef.current.style.cursor = 'grabbing';
      scrolleableRef.current.style.userSelect = 'none';

      scrolleableRef.current.addEventListener('mousemove', mouseMoveHandler);
      scrolleableRef.current.addEventListener('mouseup', mouseUpHandler);
    }
  };

  useEffect(() => {
    return () => {
      if (scrolleableRef.current) {
        scrolleableRef.current.removeEventListener('mousemove', mouseMoveHandler);
        scrolleableRef.current.removeEventListener('mouseup', mouseUpHandler);
      }
    };
  }, [mouseMoveHandler, mouseUpHandler]);

  return { ref: scrolleableRef, mouseDownHandler };
};

export default useScrollOnDrag;

import React from 'react';
import { StepContent, StepStyle, StepHeader } from './step.styles';
import Tag, { TagTypes } from '../../atoms/tag/tag.component';

export interface FixedStepProps {
    type: 'start' | 'finish',
    stepId: string,
    body?: string
}

const FixedStep: React.FC<FixedStepProps> = ({ type, stepId, body }) => {
    return (
        <StepStyle active={false} id={stepId + "-" + type}>
            <StepHeader>{type}</StepHeader>
            {
                (body && type === 'start') &&
                <StepContent>
                    <Tag type={TagTypes.primary}>{body}</Tag>
                </StepContent>
            }
        </StepStyle>
    )
}

export default FixedStep;
import React from "react";
import { IDType } from "../../../../types/requests/bot/bot.interface";
import { IOption, IStep } from "../../../../types/requests/bot/step.interface";
import withSelectStep, {
    withSelectStepProps,
} from "../../../../HOC/withSelectStep.hoc";
import PlusIcon from "../icon/plusIcon.component";
import { EColors } from "../../../../types/styles/colors.enum";
import Button from "../button/button.component";
import useBot from "../../../../hooks/useBot.hook";
import { v4 as uuidv4 } from "uuid";
import { EActions } from "../../../../types/requests/bot/actions.enum";
import { EMessageType } from "../../../../types/requests/bot/stepType.enum";
export interface AddStepProps {
    prevStep: IDType | null;
    nextStep?: IDType;
    parentStep?: {
        parent: IDType;
        caseId: string;
    };
}

const AddStep: React.FC<AddStepProps & withSelectStepProps> = ({
    prevStep,
    nextStep,
    parentStep,
    handleSelectStep,
}) => {
    const { stepsHandler, setBot } = useBot();

    const handleClick = async () => {
        let stepId = uuidv4();       
        const newStep: Partial<IStep> = {
            id: stepId,
            name: `Paso #${stepId.slice(-4)}`,
            message: "",
            answer: "",
            finish: !nextStep,
            next: nextStep,
            previous: prevStep,
            parentStep: parentStep,
            condition: undefined,
            action: {
                type: EActions.SEND_MESSAGE,
            },
            options: [],
            type: EMessageType.NORMAL_MESSAGE,
        };

        let prevStepValue = JSON.parse(
            JSON.stringify(
                stepsHandler.steps.find((step) => step.id === prevStep)
            )
        );

        if (
            prevStepValue &&
            prevStepValue?.options &&
            prevStepValue?.options.length
        ) {
            
            prevStepValue.next = undefined;

            const option = prevStepValue.options.find(
                (option: IOption) => option.id === parentStep?.caseId
            );

            if (option) {
               // option.finish = !option.next ? true: false
                option.next = stepId;
            }
            

            stepsHandler.setStep(prevStepValue.id, prevStepValue);
        }

        stepsHandler.addStep(newStep as IStep, prevStep!, parentStep?.caseId);
        await setBot.mutateAsync({ botProps:{ steps: stepsHandler.steps }});

        handleSelectStep(stepId, prevStep!, "set_action");
    };

    return (
        <Button btnType="primary" onClick={handleClick} childType="icon">
            <PlusIcon colorCode={EColors.white} />
        </Button>
    );
};

export default withSelectStep<AddStepProps>(AddStep);


import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IStep } from "../../../../types/requests/bot/step.interface";
import { EActions } from "../../../../types/requests/bot/actions.enum";
import Connector from "../../molecules/connector/connector.component";

const ConnectorsContainer = styled.div<{ scale: number }>`
    button {
        transform: ${({ scale }) => `scale(${scale})`};
    }
`;

export interface ConnectorsProps {
    steps: IStep[];
    scale: number;
}

const Connectors: React.FC<ConnectorsProps> = ({ steps, scale = 1 }) => {
    const [validateStartFlow, setValidateStartFlow] = useState(false);
    useEffect(() => {
        let findStepStart = steps.find(step => step.action.type === EActions.START);
        setValidateStartFlow(findStepStart ? true: false)
    }, [steps])
    
    return (
        <ConnectorsContainer scale={scale}>
            {steps.length > 0 && !validateStartFlow && (
                <Connector id={"trigger-start"} next={steps[0].id} />
            )}

            {steps.map((step) => {
                if (step?.action?.type === EActions.CONDITION) {
                    return (
                        <React.Fragment key={"step-" + step.id}>
                            {step?.condition?.cases.map((conditionCase) => {
                                return (
                                    <Connector
                                        id={step.id}
                                        next={conditionCase?.next}
                                        isCase={true}
                                        parentStep={{
                                            parent: step.id,
                                            caseId: conditionCase.caseId,
                                        }}
                                        key={`arrow-case-${conditionCase.caseId}`}
                                    />
                                );
                            })}
                        </React.Fragment>
                    );
                } else if (                    
                    step?.options && 
                    step?.options?.length > 0
                ) {                    
                    return (
                        <React.Fragment key={"step-" + step.id}>
                            {step?.options?.map((option, i) => {   
                                if(!option) return;                                     
                                return (
                                    <Connector
                                        id={step.id}
                                        next={option?.next}
                                        isCase={true}
                                        parentStep={{
                                            parent: step.id,
                                            caseId: option.id,
                                        }}
                                        key={"arrow-case-" + option.id}
                                    />
                                );
                            })}
                        </React.Fragment>
                    );
                } else {
                    return (
                        <Connector
                            id={step.id}
                            next={step.next}
                            parentStep={step.parentStep}
                            goTo={step.go_to}
                            hiddenAddStep={step?.action?.type === EActions.REDIRECT_ADVISOR}
                            key={"step-" + step.id}
                        />
                    );
                }
            })}
        </ConnectorsContainer>
    );
};

export default Connectors;

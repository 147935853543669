import styled from 'styled-components'
import { EColors } from '../../../../types/styles/colors.enum';

export interface HeadLinkProps {
    isActive: boolean
}

const HeadLink = styled.span<HeadLinkProps>`
    color: ${props => props.isActive ? EColors.talksPro : EColors.white};
    font-weight: ${props => props.isActive ? "500" : ""};
    text-decoration: ${props => props.isActive ? "underline" : "none"};
`;

export default HeadLink;
import React from 'react'
import ContentLoading from './ContentLoading'

const RowLoading = ({ cells = 0 }) => {

  const tds = []

  for (let i = 0; i < cells; i++) {
    tds.push(<td key={i}><ContentLoading /></td>)
  }

  return (
    <>
      <tr>{tds}</tr>
      <tr>{tds}</tr>
      <tr>{tds}</tr>
      <tr>{tds}</tr>
      <tr>{tds}</tr>
    </>
  )
}

export default RowLoading;
import React from 'react'
import RowLoading from '../../molecules/Loading/RowLoading'


export interface TableContentProps {
    data: any,
    colsCount: number,
    removeRow?:(data: any) => any,
    updateRow?:(data: any) => any,
    handleChangeStatus?:(data: any) => any,
    row: any
}

const TableContent: React.FC<TableContentProps> = ({ data, row: Row, colsCount = 4, removeRow, updateRow, handleChangeStatus }) => {
  return (
    <>
      {
        data &&
        <>
          {
            data.length > 0 ?
              data.map((row:any, i: number) => (
               <Row
                  key={i}
                  {...row}
                  i={i}
                  removeRow={removeRow}
                  updateRow={updateRow}
                  handleChangeStatus={handleChangeStatus}
                />
              ))
              : <tr><td rowSpan={colsCount} colSpan={4}>No se han encontrado datos.</td></tr>
          }
        </>
      }

      {(!data === true) && <RowLoading cells={colsCount}/>}
    </>
  )
}

export default TableContent
import React, { useCallback, useEffect, useRef, useState } from "react";

import "@yaireo/tagify/dist/tagify.css";
import Tagify, { TagifySettings } from '@yaireo/tagify'
import Button from "../../UI/atoms/button/button.component";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import { IStep } from "../../../types/requests/bot/step.interface";
import useBot from "../../../hooks/useBot.hook";
import { TagStyles } from "./tag.styles";
import { zeroWidthTrim } from "../../../utils/removeCharacters";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../UI/atoms/floatingElements/tooltip.component";
import BotQuestion from "../../../assets/images/question.svg";
//import {MixedTags} from "./tagify/react.tagify"
// Tagify settings object


export type SimpleTagsProps = FieldHookConfig<string> & {
    id: string;
    label: string;
    disabled?: boolean;
    readonly?: boolean;
    className?: string;
    placeholder?: string;
    viewCount?: boolean;
    maxCount?: number;
    textHelp?: string | JSX.Element;
};
export type IWhiteList = {
    value: string,
    prefix?: string,
};
const SimpleTagInput: React.FC<SimpleTagsProps> = ({className = "", value, label, placeholder, disabled = false, readonly = false, textHelp,...props}) => {
    const formik = useFormikContext<IStep>();
    const [field, meta] = useField(props);
    const [valueInput, setValueInput] = useState<string>("");

    const inputElem = document.getElementById(props.id) as HTMLTextAreaElement;
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        setValueInput(value);
    }, [])
    
    useEffect(() => {

        const tagify = new Tagify(inputElem);
        if(!tagify) return;
        tagify.setDisabled(disabled);
        tagify.setReadonly(readonly);

        tagify.on('change',(e:any) => {
            const value = e.detail.tagify.value;
            const formatValues = value.map((el:{value:string}) => el.value.trim());
            
            formik.setFieldValue(props.name, formatValues);
            setValueInput(formatValues);
        })

    }, [inputElem])  

    return (
        <TagStyles className="form-group">

            {label ? <label className="form-label"  htmlFor={props.id}>
                {label} {textHelp && <Tooltip placement="top-start">
                            <TooltipTrigger> <img src={BotQuestion} alt="help" width={"16"} /> </TooltipTrigger>
                            <TooltipContent className="Tooltip">{textHelp}</TooltipContent>
                        </Tooltip>}
            </label>: ""}
                <input id={props.id} ref={inputRef} defaultValue={value} placeholder={placeholder} />
                {props.viewCount && <div className={`count-input ${valueInput.length > (props.maxCount || 100) && "red"}`}>{valueInput.length}/{props.maxCount || 100}</div>}
        </TagStyles>
    );
};

export default SimpleTagInput;

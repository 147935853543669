import React from 'react'
import { IconProps } from './sendIcon.component';
import { EColors } from '../../../../types/styles/colors.enum';

const PencilIcon: React.FC<IconProps> = ({ colorCode = EColors.text, ...props }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13.02 5.82849L15.85 3.00049L20.799 7.95049L17.97 10.7785M13.02 5.82849L3.414 15.4355C3.22645 15.623 3.12106 15.8773 3.121 16.1425V20.6785H7.657C7.9222 20.6784 8.17651 20.573 8.364 20.3855L17.97 10.7785M13.02 5.82849L17.97 10.7785" stroke={colorCode} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default PencilIcon;
import React from 'react';
import { IconProps } from './sendIcon.component';
import { EColors } from '../../../../types/styles/colors.enum';

const RecoveryIcon: React.FC<IconProps> = ({ colorCode = EColors.text, ...props }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M38 25.999V35.9988C38 37.0988 37.1 37.9988 36 37.9988H12C10.9 37.9988 10 37.0988 10 35.9988V25.999C10 24.899 9.1 23.999 8 23.999C6.9 23.999 6 24.899 6 25.999V37.9988C6 40.1988 7.8 41.9987 10 41.9987H38C40.2 41.9987 42 40.1988 42 37.9988V25.999C42 24.899 41.1 23.999 40 23.999C38.9 23.999 38 24.899 38 25.999ZM26 25.339L29.76 21.579C29.9452 21.3939 30.165 21.247 30.4069 21.1468C30.6488 21.0466 30.9081 20.995 31.17 20.995C31.4319 20.995 31.6912 21.0466 31.9331 21.1468C32.175 21.247 32.3948 21.3939 32.58 21.579C32.7652 21.7642 32.912 21.984 33.0123 22.2259C33.1125 22.4679 33.164 22.7271 33.164 22.989C33.164 23.2509 33.1125 23.5102 33.0123 23.7521C32.912 23.994 32.7652 24.2138 32.58 24.399L25.4 31.5789C25.215 31.7643 24.9952 31.9114 24.7532 32.0117C24.5113 32.1121 24.2519 32.1638 23.99 32.1638C23.7281 32.1638 23.4687 32.1121 23.2268 32.0117C22.9848 31.9114 22.765 31.7643 22.58 31.5789L15.4 24.399C15.026 24.025 14.816 23.5179 14.816 22.989C14.816 22.4602 15.026 21.953 15.4 21.579C15.774 21.2051 16.2811 20.995 16.81 20.995C17.3389 20.995 17.846 21.2051 18.22 21.579L22 25.339V7.99922C22 6.89923 22.9 5.99924 24 5.99924C25.1 5.99924 26 6.89923 26 7.99922V25.339Z" fill={colorCode} />
        </svg>
    )
}

export default RecoveryIcon;
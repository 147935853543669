import React, { useCallback, useEffect, useRef, useState } from "react";

import "@yaireo/tagify/dist/tagify.css";
import Tagify, { TagifySettings } from '@yaireo/tagify'
import { FieldHookConfig, useField, useFormikContext } from "formik";
import useBot from "../../../hooks/useBot.hook";
import { TagStyles } from "./tag.styles";
import { removeSpecialCharactersAndSpaces } from "../../../utils/pipes/strings.pipe";


export type MixedTagsProps = FieldHookConfig<string> & {
    id: string;
    withoutBorder?: boolean;
    className?: string;
};
export type IWhiteList = {
    value: string,
    prefix?: string,
};

const MixedTagText: React.FC<MixedTagsProps> = ({className = "", withoutBorder, ...props}) => {
    const { bot } = useBot();
    const [whiteList, setWhiteList] = useState<IWhiteList[]>([]);
    const settings: TagifySettings = {
        mode: 'mix', 
        pattern: /@|#/, // <- must define "patten" in mixed mode
        mixTagsInterpolator: ["{", "}"],
        dropdown: {
            enabled: 1,
            position: "text",
            classname: "color-blue",
            includeSelectedTags: true
        },
        duplicates: true,
        whitelist: [],
        tagTextProp: "title",
    } ;
    const inputElem = document.getElementById(props.id) as HTMLTextAreaElement;
    const inputRef = useRef<HTMLTextAreaElement>(null);
    
    
    useEffect(() => {
        // Obtener variable 
        // global.phone, global.publicName, global.firstText
        let dataValue = []
        for (const item of bot!.environment) {
            dataValue.push({value: `env.${removeSpecialCharactersAndSpaces(item.name)}`, title: `ENV ${item.name}`  }) 
        }
        for (const answer of bot!.answers) {
            dataValue.push({ value: `bot.${answer.code}`, title: `BOT ${answer.label}`  })
        }
        setWhiteList([
            { value: "global.phone", title: "GLOBAL Phone" },
            { value: "global.publicName", title: "GLOBAL Public Name"},
            { value: "global.firstText", title: "GLOBAL First Text" },
            { value: "global.vid", title: "GLOBAL VID" },
            { value: "global.lineNumber", title: "GLOBAL Line Number" },
            ...dataValue
            ])
    }, [bot]);

    useEffect(() => {
            // the 'input' element which will be transformed into a Tagify component
            if(whiteList.length){
                const tagify = new Tagify(inputElem , settings);
                tagify.setReadonly(true);
                tagify.whitelist = whiteList;
            }
    }, [whiteList])  

    return (
        <TagStyles withoutBorder={withoutBorder}>
            <textarea id={props.id} cols={30} rows={10} ref={inputRef} value={props.value} readOnly></textarea>
        </TagStyles>
    );
};

export default MixedTagText;

import React from 'react';
import { useFormikContext } from 'formik';
import Button from '../../atoms/button/button.component';

export interface FootBottonsProps {
    submit?: string;
    cancel?: string;
    handleRemove: () => void;
}

const FootBottons: React.FC<FootBottonsProps> = ({ handleRemove, submit = "Guardar acción", cancel = "Eliminar" }) => {

    const { isInitialValid, isValid, isSubmitting } = useFormikContext();

    return (
        <div className="d-flex justify-content-end">
            <Button
                btnType='primary'
                type='submit'
                className='me-2 w-100'
                disabled={isInitialValid as boolean || !isValid}
                isSubmitting={isSubmitting}
            >
                {submit}
            </Button>

            <Button
                btnType='tertiary'
                type='button'
                onClick={handleRemove}
            >
                {cancel}
            </Button>
        </div>
    )
}

export default FootBottons;
import React, { useContext } from "react";
import { IStep } from "../../../../types/requests/bot/step.interface";
import withSelectStep, {
    withSelectStepProps,
} from "../../../../HOC/withSelectStep.hoc";
import {
    StepsFormContext,
    StepsFormContextType,
} from "../../../../context/stepsForm.context";
import {
    StepBase,
    StepContent,
    StepElement,
    StepHeader,
    StepStyle,
} from "./step.styles";
import Tag, { TagTypes } from "../../atoms/tag/tag.component";
import StepIcon from "../../atoms/step/stepIcon.component";
import { getActionText } from "../../../../utils/pipes/actionType.pipe";
import useBot from "../../../../hooks/useBot.hook";
import { EMessageType } from "../../../../types/requests/bot/stepType.enum";
import { IOption } from "../../../../types/requests/bot/step.interface";
import { InputButtonProps } from "../inputs/InputButton.component";
import { ConditionalConnectors } from "./step.styles";
import { EActions } from "../../../../types/requests/bot/actions.enum";
import MixedTagText from "../../../templates/TagInput/tagText";

const Step: React.FC<
    IStep & withSelectStepProps & IOption & InputButtonProps
> = ({
    id,
    name,
    message,
    answer,
    finish,
    next,
    action,
    previous,
    parentStep,
    condition,
    handleSelectStep,
    options,
    type,
    ...props
}) => {
    const { currentStepId } = useContext(
        StepsFormContext
    ) as StepsFormContextType;
    const { answersHandler } = useBot();

    const verOptions = options?.map((option, i) => {
        if(option?.title !== EMessageType.NO_CUMPLE) {
            return (
                <Tag type={TagTypes.secundary} key={i}>
                    {option?.title}
                </Tag>
            );
        }
    }).filter(el => el);

    const numButtons = () => {
        return (
            <div>
                {verOptions?.map((option, idx) => (
                    <Tag type={TagTypes.secundary} key={idx}>{option}</Tag>
                ))}
            </div>
        );
    };

    const numMenu = () => {
        return (
            <div className="d-flex flex-row flex-wrap">
                {verOptions?.map((option) => (
                    <Tag type={TagTypes.secundary} className="w-100">
                        {option}
                    </Tag>
                ))}
            </div>
        );
    };

    const handleContent = () => {
        switch (action.type) {
            case EActions.GET:
                return (
                    <>
                        <Tag
                            id={`${id}_0`}
                            type={TagTypes.ramification}
                            key={`connector-${id}_0`}
                        >
                            Si recupera data
                        </Tag>
                        <Tag
                            id={`${id}_1`}
                            type={TagTypes.ramification}
                            key={`connector-${id}_1`}
                        >
                            No recupera data
                        </Tag>
                    </>
                );
            case EActions.SAVE_OR_UPDATE:
                return (
                    <>
                        
                        <Tag
                            id={`${id}_0`}
                            type={TagTypes.ramification}
                            key={`connector-${id}_0`}
                        >
                            Guardado correcto
                        </Tag>
                        <Tag
                            id={`${id}_1`}
                            type={TagTypes.ramification}
                            key={`connector-${id}_1`}
                        >
                            Error guardando
                        </Tag>
                    </>
                );
            case EActions.START:
                return (
                    <>
                        <Tag
                            id={`${id}_0`}
                            type={TagTypes.ramification}
                            key={`connector-${id}_0`}
                        >
                            Tiene Contacto
                        </Tag>
                        <Tag
                            id={`${id}_1`}
                            type={TagTypes.ramification}
                            key={`connector-${id}_1`}
                        >
                            No tiene contacto
                        </Tag>
                    </>
                );
            default:
                return (
                    <>
                        {options?.map((buttonCase, i) => (
                            <Tag
                                id={`${id}_${i}`}
                                type={TagTypes.ramification}
                                key={`connector-${id}_${i}`}
                            >
                                {options.length - 1 === i ? EMessageType.NO_CUMPLE: `Opción ${i + 1}`}
                            </Tag>
                        ))}
                    </>
                );
        }
    };

    return (
        <StepBase
            id={id}
            active={id === currentStepId}
            onClick={() => handleSelectStep(id, undefined, "edit")}
        >
            <StepStyle
                id={id}
                active={id === currentStepId}
                onClick={() => handleSelectStep(id, undefined, "edit")}
            >
                <StepHeader>{name}</StepHeader>
                <StepContent>
                    <div>
                        {(action?.type !== EActions.START) ? <Tag type={TagTypes.primary}>
                            <StepIcon
                                type={action?.type}
                                width="16"
                                height="16"
                                className="me-1"
                            />
                            {getActionText(action?.type)} {type === "multimedia" ? " (Multimedia)" : "" }
                        </Tag>: ""}
                        {message && <span className="mx-1 fw-light d-block">
                            <MixedTagText value={message} name="message" id={`message-${name}`}/>
                        </span>}
                        {props.group && <span className="mx-1 fw-light d-block"><b>Grupo: </b> {props.group}</span>}
                        {props.subgroup && <span className="mx-1 fw-light d-block"><b>Subgrupo: </b> {props.subgroup}</span>}
                    </div>
                    {answer && (
                        <StepElement className="pt-3 px-2 border-top">
                            <div className="d-flex justify-content-center fw-light">
                                {(() => {
                                    switch (type) {
                                        case EMessageType.MULTIMEDIA:
                                        case EMessageType.NORMAL_MESSAGE:
                                            return (
                                                <div>
                                                    <div>
                                                        Espera respuesta del
                                                        usuario para <br />{" "}
                                                        continuar
                                                        {answersHandler.getAnswer(
                                                            answer
                                                        ) && (
                                                            <>
                                                                {" "}
                                                                y es almacenada
                                                                como:{" "}
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="ms-1">
                                                        {answersHandler.getAnswer(
                                                            answer
                                                        ) && (
                                                            <Tag
                                                                type={
                                                                    TagTypes.secundary
                                                                }
                                                            >
                                                                {
                                                                    answersHandler.getAnswer(
                                                                        answer
                                                                    )?.label
                                                                }
                                                            </Tag>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        case EMessageType.MENU_MESSAGE:
                                           // answer = "true";
                                            return (
                                                <div>
                                                    <div>
                                                        Menu
                                                        <div className="ms-1">
                                                            {numMenu()}
                                                        </div>
                                                        {answersHandler.getAnswer(
                                                            answer
                                                        ) && (<div className="mt-3">
                                                        <p className="mb-2">Espera respuesta usuario y es almacenada como:</p>
                                                            {
                                                                <Tag
                                                                    type={
                                                                        TagTypes.secundary
                                                                    }
                                                                >
                                                                    {
                                                                        answersHandler.getAnswer(
                                                                            answer
                                                                        )?.label
                                                                    }
                                                                </Tag>
                                                            }
                                                        </div>)}
                                                    </div>
                                                    
                                                </div>
                                            );
                                        
                                        case EMessageType.BUTTONS_MESSAGE:
                                           // answer = "true";
                                            return (
                                                <div>
                                                    <div>
                                                        Botones
                                                        <div className="ms-1">
                                                            {numButtons()}
                                                        </div>
                                                        
                                                    </div>
                                                   
                                                    {answersHandler.getAnswer(
                                                            answer
                                                        ) && (<div className="mt-3">
                                                        <p className="mb-2">Espera respuesta usuario y es almacenada como:</p>
                                                        {
                                                            <Tag
                                                                type={
                                                                    TagTypes.secundary
                                                                }
                                                            >
                                                                {
                                                                    answersHandler.getAnswer(
                                                                        answer
                                                                    )?.label
                                                                }
                                                            </Tag>
                                                        }
                                                    </div>)}
                                                </div>
                                            );
                                    }
                                })()}
                            </div>
                        </StepElement>
                    )}
                </StepContent>
            </StepStyle>
            <ConditionalConnectors>{handleContent()}</ConditionalConnectors>
        </StepBase>
    );
};

export default withSelectStep<IStep>(Step);


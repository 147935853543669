import axios from "axios";
import { IBot, IBotRequest } from "../types/requests/bot/bot.interface";
import { ITokens } from "../types/requests/tokens/tokens.interface";
import { validateToken } from "../utils/token";
import { createLog } from "./logs.service";

export interface botServiceResponse<T = any> {
    status: string;
    message: string;
    data: T
}

export interface INotification {
    register: string;
    url: string;
}

const botsService = () => (axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 10000,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token_user')}
}));

export const createBot = async (bot: IBotRequest, notification?: INotification): Promise<IBot> => {
    try {
        let axiosBot = botsService();
        const result = await axiosBot.post<botServiceResponse<IBot>>('bots/create', { bot: bot });
        if(notification) {
            await createLog({id: result.data.data._id, bot}, 'bots/create', notification.register, notification.url, "Create" );
        }
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}

export const updateBot = async (id: string, bot: Partial<IBot>,notification?: INotification) => {
    try {
        let axiosBot = botsService();
        await axiosBot.patch<botServiceResponse<IBot>>('bots/update', {
            id: id,
            bot: bot
        });
        if(notification) {
            await createLog({id: id, bot}, 'bots/update', notification.register, notification.url, "Update" );
        }
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}

export const getBot = async (id: string): Promise<IBot> => {
    try {
        let axiosBot = botsService();
        const result = await axiosBot.get<botServiceResponse<IBot>>(`bots/get/${id}`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}
export const getBotByIdDraft = async (id: string): Promise<IBot> => {
    try {
        let axiosBot = botsService();
        const result = await axiosBot.get<botServiceResponse<IBot>>(`bots/getBotByIdDraft/${id}`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}

export const getBots = async (): Promise<IBot[]> => {
    try {
        let axiosBot = botsService();
        const result = await axiosBot.get<botServiceResponse<IBot[]>>(`bots/get`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}
export const archiveBot = async (id: string, notification?: INotification): Promise<IBot> => {
    try {
        let axiosBot = botsService();
        const result = await axiosBot.delete<botServiceResponse<IBot>>(`bots/archive/${id}`);
        if(notification) {
            await createLog({id: id}, `bots/archive/${id}`, notification.register, notification.url, "Delete" );
        }
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}
export const getTokenByBotId = async (id: string): Promise<Partial<ITokens[]>> => {
    try {
        let axiosBot = botsService();
        const result = await axiosBot.get<botServiceResponse<Partial<ITokens[]>>>(`bots/getNumber/${id}`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}
export const generateBot = async (id: string) => {
    try {
        let axiosBot = botsService();
        const result = await axiosBot.post<botServiceResponse>(`/bots/generate`,
          {id: id}
        );
        return result.data.message;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}
/* export const deleteBot = async (id: string): Promise<IBot> => {
    try {
        let axiosBot = botsService();
        const result = await axiosBot.delete<botServiceResponse<IBot>>(`bots/archive/${id}`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}
 */
export default botsService;

import { BranchModeType } from "../../context/steps.context.old";
import { EActions } from "../../types/requests/bot/actions.enum";
import { IDType } from "../../types/requests/bot/bot.interface";
import { ICase } from "../../types/requests/bot/case.interface";
import { IHubspotValue, IOption, IStep } from "../../types/requests/bot/step.interface";

export default class StepsHandler {
    
    private _steps: IStep[];

    constructor(steps: IStep[]) {
        this._steps = steps;
    }

    /* Nodos: Puede pensar en los nodos como bloques de construcción que cumplen diferentes funciones que, cuando se juntan,
    conforman un flujo de trabajo automatizado. */

    /* Obtiene todos los nodos(Steps) */
    public get steps() { return this._steps }

    /* Agrega un nodo */
    private setPrevious =(step: IStep, next: IDType | undefined, finish: boolean, caseId: string | undefined): IStep => {
        let previousStep = { ...step };
        if (previousStep.action.type === EActions.CONDITION) {
            let caseIndex = previousStep!.condition!.cases.findIndex(flowCase => flowCase.caseId === caseId);
            if (caseIndex > -1) {
                previousStep!.condition!.cases[caseIndex].next = next;
            }
        } else {
            previousStep.next = next;
            previousStep.finish = !next ? true: finish;
        }
        return previousStep;
    }
   
    private getPrevious = (stepId: IDType, position: number) => {

        const filteredSteps = this._steps.filter(step => step.next === stepId || step?.condition?.cases.some(flowCase => flowCase.next === stepId));
    
        const lowerSteps = filteredSteps.filter(step => filteredSteps.findIndex(botStep => botStep === step) < position);
        const stepsPositions = lowerSteps.map(step => filteredSteps.findIndex(botStep => botStep === step));
    
        if (stepsPositions.length > 0) {
            let nearPosition = stepsPositions[0];
    
            stepsPositions.forEach((stepPosition) => {
                if ((stepPosition - position) < nearPosition) {
                    nearPosition = stepPosition;
                }
            });
    
            return { step: filteredSteps[nearPosition], position: this._steps.findIndex(step => step.id === filteredSteps[nearPosition].id) };
    
        } else {
            return null;
        }
    }

    private getPreviousComponent = (previous: IDType) => {
        let findComponent = this.steps.find(step => step.id === previous);
        return findComponent;
    }

    public getPreviousBranch = (array: IStep[], stepId: IDType, to: IDType = ""): IStep[] => {
       let stepData = this.getPreviousComponent(stepId)
       if(stepData) {
            array.push(stepData);
            if(stepData.id !== to) this.getPreviousBranch(array, stepData.previous || "", to);
        } 
       return array;
    }

    public setStep = (stepId: IDType, props: Partial<IStep>): StepsHandler => {
        const stepsCopy = [...this._steps];
        let stepPos = stepsCopy.findIndex(step => step.id === stepId);
        if (stepPos > -1) {
            stepsCopy[stepPos] = { ...stepsCopy[stepPos], ...props }
        }

        this._steps = stepsCopy;
    
        return this;
    
    }
    
    public addStep = (step: IStep, previousStepId: IDType | null, caseId: string | undefined): StepsHandler => {        
        let stepsCopy = [...this._steps];
        let previousIndex = this._steps.findIndex(step => step.id === previousStepId);
        if (previousIndex > -1) {
            stepsCopy[previousIndex] = this.setPrevious(stepsCopy[previousIndex], step.id, false, caseId);
        }
        stepsCopy.splice(previousIndex + 1, 0, step);

        this._steps = stepsCopy;
        return this;
    }
    
    public removeStep = (stepId: IDType, next: IDType | undefined, finish: boolean, caseId: string | undefined) => {
        let stepsCopy = [...this._steps];
        let stepIndex = this._steps.findIndex(step => step.id === stepId);
        const preview = this.getPrevious(stepId, stepIndex);
        if (preview) {
            stepsCopy[preview.position] = this.setPrevious(stepsCopy[preview.position], next, finish, caseId);
        }
        
        this._steps = stepsCopy.filter(steps => steps.id !== stepId); 
        return this;
    }

    public getStep = (stepId: IDType) => {
        return this._steps.find(step => step.id === stepId);
    }
    public getStepAnswer = (answerId: IDType) => {
        return this._steps.filter(step => step.answer === answerId);
    }
    public getStepAnswerHS = (answerId: IDType) => {
        let filterHsGet = this._steps.filter(step => step.action.type === EActions.GET);
        let arrayHS: IHubspotValue[] = [];
        for (const step of filterHsGet) {
            let filterValuesHs: IHubspotValue[] = step.hubspotValues?.filter(el => el.answer === answerId) || [];
            arrayHS = [...arrayHS, ...filterValuesHs];
        }
        return arrayHS;
    }
    
    public getStepIndex = (stepId: IDType) => {
        return this._steps.findIndex(step => step.id === stepId);
    }
    
    public setMultipleSteps = (stepsConfig: { id: IDType, props: Partial<IStep> }[]) => {
        let stepsCopy = [...this._steps];
        stepsConfig.forEach(stepConfig => {
            stepsCopy = this.setStep(stepConfig.id, stepConfig.props).steps;
        });
    
        return stepsCopy;
    }

    public getBranch = (id: IDType | undefined, attribute: BranchModeType = "next") => {
        let elements: IDType[] = [];
        if (id) {
            const step: IStep | undefined = this.getStep(id);
            if (step) {
                elements.push(id, ...this.getBranch(step[attribute as keyof IStep]?.toString(), attribute))
            }
        }
        return elements;
    }
    
    public setBranch = (firstStepId: IDType | undefined, props: Partial<IStep>, onlyChildren:string = ""): StepsHandler => {
        
        const branch = this.getBranch(firstStepId).map((id: IDType) => {
            return { id, props }
        })
        let branchParent: string[] = [];
        if(onlyChildren) {
             branchParent = this.getStepsByParentId(onlyChildren || "").map(el => el.id);
        }
        let filter = branch.filter(el => branchParent.includes(el.id));

        this._steps = this.setMultipleSteps(onlyChildren ? filter: branch); 
        return this;
    }


    public setConditionalNextSteps = (parent: IStep, caseId: string | undefined, next: IDType | undefined): StepsHandler => {        
        let branch = [];
        if (next && caseId) {
            branch.push(
                ...this.setBranch(next, {
                    parentStep: {
                        caseId,
                        parent: parent.id,
                    }
                }).steps
            )
        } else {
            branch = this._steps;
        }
    
        this._steps = branch;
    
        return this;
    }
    
    
    public removeBranch = (firstStepId: IDType): StepsHandler => {
        let stepsCopy = [...this._steps];
        const branch = this.getBranch(firstStepId);
    
        branch.forEach((stepId) => {
            stepsCopy = this.removeStep(stepId, undefined, true, undefined).steps;
        });
    
        this._steps = stepsCopy;
    
        return this;
    }
    
    public removeBranchs = (cases: ICase[]): StepsHandler => {
        let stepsCopy = [...this._steps];
        cases.forEach(conditionalCase => {
            if (conditionalCase.next) stepsCopy = this.removeBranch(conditionalCase.next).steps;
        });

        this._steps = stepsCopy;
    
        return this;
    }
    public removeBranchsOptions = (cases: IOption[]): StepsHandler => {
        let stepsCopy = [...this._steps];
        cases.forEach(conditionalCase => {
            if (conditionalCase.next) stepsCopy = this.removeBranch(conditionalCase.next).steps;
        });

        this._steps = stepsCopy;
    
        return this;
    }
    public removeNextOption = (options: IOption[], stepId: string, optionId: string, next: string): StepsHandler => {
        let stepsCopy = [...this._steps];
        
        //Buscar la opcion 
        for (const option of options) {
            if(option.id === optionId ) {
                option.next = next;
            }
        }
        let stepPos = stepsCopy.findIndex(step => step.id === stepId);
        if (stepPos > -1) {
            stepsCopy[stepPos] = { ...stepsCopy[stepPos], options: options }
        }
        this._steps = stepsCopy;
    
        return this;
    }

    public deleteActionComponent = (id: string) => {
        let allSteps = [...this._steps];
        const filterComponentAction = allSteps.filter(step => step.go_to === id);
        for (const component of filterComponentAction) {
            this.removeStep(component.id, "", true, "");
        }
    }
    public getStepsByParentId = (id: string) => {
        let allSteps = [...this._steps];
        const filterSteps = allSteps.filter(step => step.parentStep?.parent === id);
        return filterSteps;
    }
    public getStepsByCaseId = (id: string) => {
        let allSteps = [...this._steps];
        const filterSteps = allSteps.filter(step => step.parentStep?.caseId === id);
        return filterSteps;
    }

    
}










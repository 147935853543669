import styled from "styled-components";

export const TagStyles = styled.div<{withoutBorder?: boolean}>`
    width: 100%;
    & .tagify--mix {
        border-radius: .375rem;
        ${props => props.withoutBorder && "border: none"};
        & .tagify__input {
            overflow: hidden;
            min-width: 50px;
        }
    }

    & .count-input {
        font-size: 12px;
        text-align: end;
        &.red {
            color: red;
        }
    }

    & .tagify {
        width: 100%
    }
`
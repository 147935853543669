import React, { useState } from "react";
import Xarrow from "react-xarrows";
import { IDType } from "../../../../types/requests/bot/bot.interface";
import AddStep from "../../atoms/step/addStep.component";

export interface ConnectorProps {
    id: IDType;
    next?: IDType;
    parentStep?: {
        parent: IDType;
        caseId: string;
    };
    isCase?: boolean;
    isOption?: boolean;
    goTo?: string;
    hiddenAddStep? : boolean;
}

const Connector: React.FC<ConnectorProps> = ({
    id,
    next,
    parentStep,
    isCase = false,
    isOption = false,
    goTo = "",
    hiddenAddStep = false
}) => {
    const [hover, setHover] = useState(false)
    return (
        <Xarrow
            labels={
                !goTo && !hiddenAddStep ? <AddStep
                    prevStep={id}
                    nextStep={goTo || next}
                    parentStep={parentStep}
                /> : ""
            }
            start={isCase ? parentStep!.caseId : id}
            startAnchor={isCase ? "bottom" : goTo ? ["left", "right"]: "auto"}
            endAnchor={goTo ? ["left", "right"] :"top"}
            end={
                goTo ? goTo: next
                    ? next
                    : isCase
                    ? `${parentStep?.caseId}-finish`
                    : `${id}-finish`
            }
            color="#86888A"
            lineColor={goTo ? "blue" :hover ? "red": "#86888A"}
            headColor={goTo ? "blue" :hover ? "red": "black"}
            strokeWidth={goTo || hover ? 4: 2}
            path={"smooth"}
            curveness={0.55}
            dashness={goTo ? true : false}
            animateDrawing={0.3}
            passProps={{cursor: "pointer", onClick: (e:any) => setHover(!hover)}}
        />
    );
};

export default Connector;

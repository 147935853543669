import { QueryClient, useMutation } from 'react-query';
import { IBot } from '../types/requests/bot/bot.interface';
import botsService from '../services/bots.service';
import Swal from 'sweetalert2';
import { createLog } from '../services/logs.service';

const useBotMutation = (queryClient: QueryClient, botId?: string) => {
    let axiosBot = botsService();
    const setBot = useMutation(
        ({ botProps, notification }: { botProps: Partial<IBot>, notification?: {register: string, url: string} }) =>
            axiosBot.patch("/draftBots/update", { id: botId, bot: botProps }),
          {
            onSuccess: async (data, variables) => {
                const { botProps, notification } = variables;
                await queryClient.invalidateQueries('draft_bot');
                if(notification) {
                    await createLog({ id: botId, bot: botProps },"/draftBots/update", notification.register, notification.url, "Update");
                }
            },
            onError: () => {
                Swal.fire({
                    position: "bottom-right",
                    title: "Error al guardar bot",
                    text: "Ha ocurrido un error inesperado al guardar la configuración del bot, intente nuevamente.",
                    timer: 5000
                })
            }
        }
    );

    return setBot;
}

export default useBotMutation;
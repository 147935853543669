import React from 'react';
import styled from 'styled-components';
import ArrowLeftIcon from '../../icons/arrows/ArrowLeftIcon';
import ArrowRightIcon from '../../icons/arrows/ArrowRightIcon';


const Buttontyled = styled.button`
  padding: 6px 4px;
  font-weight: 500;
  font-size: 14px;
  margin: 8px 12px;
  width: max-content;
  height: max-content;
  border: none;
  background: transparent;
`

const PaginationButton = (props) => {
  const { direction, setCurrentPage, currentPage, pages } = props;

  const handleClick = () => {
    if (direction === "left") setCurrentPage(currentPage > 1 ? currentPage - 1 : pages);
    if (direction === "right") setCurrentPage(currentPage < pages ? currentPage + 1 : 1);
  }

  return (
    <Buttontyled {...props} onClick={() => handleClick()}>
      {direction === "left" && <ArrowLeftIcon />}
      {direction === "right" && <ArrowRightIcon />}
    </Buttontyled>
  )
}

export default PaginationButton;
import React, { PropsWithChildren } from 'react';
import { Field, useFormikContext, FieldAttributes } from 'formik';
import InputError from '../../atoms/input/inputError.component';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../atoms/floatingElements/tooltip.component';
import BotQuestion from "../../../../assets/images/question.svg";

//! CORREGIR ANYS
export interface SelectBoxProps extends FieldAttributes<any> {
    label: string;
    name: string;
    id: string;
    className?: string;
    disabled?: boolean;
    textHelp?: string | JSX.Element;
}

const SelectBox: React.FC<PropsWithChildren<SelectBoxProps>> = ({ label, name, id, className = "", children, textHelp, ...props }) => {

    const formikContext = useFormikContext();

    const meta = formikContext.getFieldMeta(name);
    return (
        <div className='form-group'>
            <label className="form-label" htmlFor={id}>{label} {textHelp && <Tooltip placement="top-start">
                            <TooltipTrigger> <img src={BotQuestion} alt="help" width={"16"} /> </TooltipTrigger>
                            <TooltipContent className="Tooltip">{textHelp}</TooltipContent>
                        </Tooltip>}</label> 
            <Field
                as="select"
                id={id}
                name={name}
                className={`form-control ${className} ${(meta.touched && meta.error) ? "is-invalid " : ""}`}
                disabled={props.disabled || false}
                {...props}
            >
                {children}
            </Field>
            {meta.touched && meta.error && (
                    <InputError> {meta.error} </InputError>
                )}
        </div>
    )
}

export default SelectBox;
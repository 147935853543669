import React, { useContext } from "react";
import { ModeType, StepsFormContext, StepsFormContextType } from "../context/stepsForm.context";
import { IDType } from "../types/requests/bot/bot.interface";
import { EMessageType } from "../types/requests/bot/stepType.enum";

export interface withSelectStepProps {
    handleSelectStep: (stepId: IDType, prevStep: IDType | undefined, mode: ModeType) => void;
}

function withSelectStep<T extends Object>(Component: React.ElementType) {
    const SelectWrapper = (props: T) => {
        const formVisibility = useContext(StepsFormContext) as StepsFormContextType;

        const handleSelect = (stepId: IDType, prevStep: IDType, mode: ModeType) => {
            //@ts-ignored
            if(props?.type === EMessageType.START) return;
            formVisibility.setStepsIds(stepId, prevStep);
            formVisibility.setMode(mode);
            formVisibility.setVisible(true);
            formVisibility.setIsNew(false);
        }

        return <Component {...props} handleSelectStep={handleSelect} />
    }

    return SelectWrapper;
}

export default withSelectStep;
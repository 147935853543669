import React, { useMemo, useRef } from 'react';
import useScrollOnDrag from '../../../../hooks/useScrollOnDrag';
import Pagination from '../../molecules/pagination/Pagination';

import { ConversationTable } from './styles';
import TableContent from './TableContent';


export interface PaginationTableProps {
    updateRow?: (data: any) => any,
    handleChangeStatus?: (data: any) => any,
    removeRow?: (data: any) => any,
    withoutRight: boolean,
    pages: number,
    setPages:(data: any) => any,
    content: any[],
    headers: any[],
    row: any
}

const PaginationTable: React.FC<PaginationTableProps> = ({ withoutRight, pages, setPages, content, headers = [], row, updateRow, handleChangeStatus, removeRow }) => {

  const { ref, mouseDownHandler } = useScrollOnDrag();
  const tableRef = useRef(null);
  const headersAmount = useMemo(() => {
    return headers.reduce((acc, header) => {
      let amount = acc;
      
      if (typeof header === "object") {
        amount += header.colSpan ?? 1;
      } else {
        amount += 1;
      }

      return amount;
    }, 0);
  }, [headers]);

  return (
    <div
      className='p-0 table-responsive'
      ref={ref}
      onMouseDown={mouseDownHandler}
    >
      <ConversationTable id='table_connection' withoutRight={withoutRight ?? false} ref={tableRef}>
        <thead>
          <tr>
            {
              headers.map((header, i) => {
                let headerPros = (typeof header === "object") ? header : {};
                return (
                  <th
                    key={header + i}
                    {...headerPros}
                  >
                    {
                      (typeof header === "object") ? header.value : header
                    }
                  </th>
                )
              })
            }
          </tr>
        </thead>

        <tbody>
          <TableContent data={content} row={row} colsCount={headersAmount} updateRow={updateRow} removeRow={removeRow} handleChangeStatus={handleChangeStatus}/>
        </tbody>
      </ConversationTable>
      <Pagination pages={pages} setPages={setPages} fix={true} />
    </div>
  )
}

export default PaginationTable;
import { EActions } from "../../types/requests/bot/actions.enum";

export const getActionText = (action: EActions) => {
    switch (action) {
        case EActions.SEND_MESSAGE:
            return "Enviar mensaje";
        case EActions.SAVE_OR_UPDATE:
            return "Guardar información del contacto en HS";
        case EActions.GET:
            return "Recuperar datos del contacto desde HS";
        case EActions.CONDITION:
            return "Condicional"
        case EActions.GO_ACTION:
            return "Ir a la acción"
        case EActions.GROUP_AND_SUBGROUP:
            return "Cambio de Grupo y Subgrupo"
        case EActions.REDIRECT_ADVISOR:
            return "Enviar a asesor"
    }
}
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { IBot } from "../../../types/requests/bot/bot.interface";
import {
    BotFormContext,
    BotFormContextType,
} from "../../../context/botForm.context";

import {
    FlexGap,
    FloatForm,
    FormBody,
    FormHeader,
    StepFormWrapper,
} from "../../UI/molecules/steps/step.styles";
import InputBox from "../../UI/molecules/inputs/inputBox.component";

import Swal from "sweetalert2";
import Button from "../../UI/atoms/button/button.component";
import InactiveMsg from "../../UI/organisms/botForm/inactiveMsg.component";
import SimpleTagInput from "../TagInput/tagInputSimple";
import SelectBox from "../../UI/molecules/inputs/selectBox.component";
import SelectSearch from "../../UI/molecules/inputs/selectSearch.component";
import { getPropertiesContact } from "../../../services/hubspot.service";
import { IPropertyContact } from "../../../types/requests/hubspot/contact.interface";
import MixedTagInput from "../TagInput/tagInput";
import { InputJump } from "../../UI/organisms/botForm/botForm.styles";
import { AlertFormLarge, ICON } from "../../../utils/Alert";

const BotSchema = Yup.object().shape({
    name: Yup.string().required("Debes añadir un nombre al bot."),
    stop_notifications: Yup.array()/* .required("Debes añadir al menos una palabra.") */,
    send_agent: Yup.array()/* .required("Debes añadir al menos una palabra.") */,
    inactive_message: Yup.array().of(
        Yup.object().shape({
            message: Yup.string().required('Debes agregar un mensaje de inactividad'),
            time: Yup.number().required('Debes agregar un tiempo en minutos').min(5, "El valor debe ser igual o mayor a 5")
        })
    ),
    finish_last_message: Yup.bool(),
    time: Yup.number().min(5, "El valor debe ser igual o mayor a 5"),
    reattempts_agent: Yup.string().required("Debes seleccionar una opcion"),
    hubspot_default_values: Yup.array().of(
        Yup.object().shape({
            propertyName: Yup.string().required('Debes agregar una propiedad'),
            value: Yup.string().required('Debes agregar un valor')
        })
    ),
});

interface FormValues {
    name: string;
    stop_notifications: string[];
    send_agent: string[];
    inactive_message: {
        message: string,
        time: number
    }[],
    message_failure_process: string,
    finish_last_message: boolean,
    reattempts_agent: string,
    hubspot_default_values: {
        propertyName: string,
        value: string,
    }[],

}

export interface BotFormProps {
    mode: "create" | "update" | "delete";
    bot?: Partial<IBot>;
    submitForm: (bot: Partial<IBot>) => Promise<any>;
}

const BotForm: React.FC<BotFormProps> = ({ mode, bot, submitForm }) => {
    const { visible, setVisible, setIsModified } = useContext(
        BotFormContext
    ) as BotFormContextType;
    const [optionsProperties, setOptionsProperties] = useState<IPropertyContact[]>([]);
    
    useEffect(() => {
        setData();    
    } , [bot]);

    const setData = async() => {
        let PropertiesContact = await getPropertiesContact();
        setOptionsProperties(PropertiesContact);
    };

    const handleSubmit = async (values: FormValues) => {
        
        let newBot: IBot = {
            ...bot,
            name: values.name,
            stop_notifications: values.stop_notifications,
            send_agent: values.send_agent,
            inactive_message: values.inactive_message,
            finish_last_message: values.finish_last_message,
            reattempts_agent: values.reattempts_agent,
            hubspot_default_values: values.hubspot_default_values,
            message_failure_process: values.message_failure_process,
        } as IBot;

        try {
            await submitForm(newBot);
            if(mode === "update") AlertFormLarge({title: "Bot Actualizado Correctamente", text: "La información queda guardada temporalmente hasta que publique el bot", icon: ICON.SUCCESS});
            else AlertFormLarge({title: "Bot Creado Correctamente", icon: ICON.SUCCESS});
            setIsModified(true);
        } catch (e: any) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: e.message,
            });
        }

        setVisible(false);
    };

    if (mode === "create" || mode === "update") {
        return (
            
            <FloatForm visible={visible} mode={mode}>
                    <FormHeader>
                        <span>
                            {mode === "create" ? "Crear" : "Actualizar"} Bot
                        </span>
                    </FormHeader>
                    <Formik
                        initialValues={{
                            name: bot?.name || "",
                            stop_notifications: bot?.stop_notifications ?? [],
                            send_agent: bot?.send_agent ?? [],
                            inactive_message: bot?.inactive_message ?? [],
                            finish_last_message: bot?.finish_last_message ?? false,
                            reattempts_agent: bot?.reattempts_agent ??"N/A",
                            hubspot_default_values: bot?.hubspot_default_values ?? [],
                            message_failure_process: bot?.message_failure_process ?? "",
                        }}
                        enableReinitialize
                        validationSchema={BotSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isValid, isInitialValid, resetForm, isSubmitting, values, setFieldValue, getFieldProps }) => (
                            <Form>
                                <FormBody>
                                    <InputBox
                                        id="bot_name"
                                        label="Ingrese un nombre para el bot"
                                        placeholder="Ejm: Bot para ventas"
                                        name="name"
                                        value={getFieldProps("name").value}
                                    />

                                    <SimpleTagInput  
                                        id="bot_stop_notifications"
                                        label="Detener Notificaciones"  
                                        name="stop_notifications"
                                        placeholder="Ejm: alto, no me interesa, salir"
                                        value={getFieldProps("stop_notifications").value}
                                        textHelp={
                                            <div>
                                                <p><b>Detener notificaciones:</b></p>
                                                <p>Permite incluir palabras clave que permiten detectar que un contacto no quiere seguir recibiendo mensajes masivos. </p>
                                                <p>Ejemplo, puedes incluir la palabra "no más mensajes", y automáticamente el bot abrirá la conversación a un agente y además, los mensajes masivos nunca más le llegarán a ese contacto. </p>
                                                <p>De esta forma se puede mejorar la confiabilidad del número y evitar ser bloqueado.</p>
                                            </div>
                                        }
                                    />

                                    <SimpleTagInput  
                                        id="bot_send_agent"
                                        label="Enviar a un asesor"
                                        placeholder="Ejm: ayudar, asesor"
                                        name="send_agent"
                                        value={getFieldProps("send_agent").value}
                                        textHelp={
                                            <div>
                                                <p><b>Enviar a asesor: </b></p>
                                                <p>Permite incluir palabras clave que permiten dirigir a un agente de manera prematura.</p>
                                                <p>Ejemplo: Puedes incluir la palabra "asesor" y en cualquier momento que el contacto escriba esa palabra automáticamente se dirigirá al asesor</p>
                                                
                                            </div>
                                        }
                                    />

                                    
                                    <InactiveMsg />
                                    <InputBox
                                        id="bot_finish_last_message"
                                        label="Finalizar conversación en el último mensaje"
                                        name="finish_last_message"
                                        as="checkbox"
                                        value={getFieldProps("finish_last_message").value}
                                        textHelp={
                                            <div> 
                                                <p> <b>Finalizar conversación en el último mensaje:</b> </p>
                                                <p>Permite que al dejar el último mensaje de inactividad, se reinicie la conversación con el bot, después de una nuevo mensaje.</p>
                                                <p>Ejemplo: puede dejar 2 mensajes de inactividad "¿SIgues ahí?" y "Parece que estás un poco ocupado, daremos por terminada la sesión, pero puedes escribirnos de nuevo apenas estés disponible, ten un buen día"; y habilitar la opción "Finalizar conversación en el último mensaje" para que automáticamente, cuando el contacto escriba de nuevo después de un tiempo, inicie de nuevo la conversación.</p>
                                            </div>
                                        }
                                    />
                                    <SelectBox id="bot_reattempts_agent"
                                        label="Número de reintentos para enviar a un asesor"
                                        name="reattempts_agent"
                                        value={getFieldProps("reattempts_agent").value}
                                        textHelp={
                                            <div>
                                                <p><b>Número de reintentos para enviar a un asesor: </b></p>
                                                <p>Algunas veces una conversación se queda atascada, y el contacto no es capaz de continuar, puede incluir una cantidad de reintentos para que en caso de que el contacto no consiga seguir con el bot, se asigne a un agente.</p>
                                                <p>Ejemplo, en el bot le pregunta a un contacto si tiene mascotas, con las opciones si o no, y el contacto responde repetidas veces otra cosa; si tiene habilitado los reintentos en 2, permitirás que después de 2 veces que no responda correctamente el contacto asigne a un asesor</p>
                                            </div>
                                        }
                                        placeholder="Ejm: 2">
                                            <option value="N/A" selected>No habilitar envio asesor</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                    </SelectBox> 

                                    <h5>Valores Predeterminados de HubSpot</h5>
                                    <div>

                                        
                                        {values.hubspot_default_values.map((value, index) => (
                                            <InputJump flexauto={true} key={index}>
                                                <SelectSearch
                                                    id={`hubspot_default_values-${index}-propertyName`}
                                                    name={`hubspot_default_values.${index}.propertyName`}
                                                    label={
                                                        "Propiedad de Hubspot"
                                                    }
                                                    value={value.propertyName || ""} 
                                                    options={optionsProperties}
                                                    disabled={false}
                                                    className={"mb-4"}
                                                />
                                                <InputBox
                                                    id={`hubspot_default_values-${index}-value`}
                                                    label={"Valor de Propiedad"} 
                                                    placeholder={"Escribe la respuesta"}
                                                    name={`hubspot_default_values.${index}.value`}
                                                    value={value.value || ""} 
                                                />
                                            
                                            <div className="d-flex justify-content-center align-items-center">
                                                    <Button
                                                        btnType="tertiary"
                                                        type="button"
                                                        onClick={() => {
                                                            let newValues = [
                                                                ...values.hubspot_default_values,
                                                            ];
                                                            newValues.splice(index, 1);
                                                            setFieldValue("hubspot_default_values", newValues);
                                                        }}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </div>
                                            
                                            </InputJump>
                                        ))}
                                        <div className="text-center">
                                            <Button
                                                btnType="primary"
                                                type="button"
                                                onClick={() => {
                                                    let newValues = [
                                                        ...values.hubspot_default_values,
                                                        {
                                                            propertyName: "",
                                                            value: "",
                                                        },
                                                    ];
                                                    setFieldValue("hubspot_default_values", newValues);
                                                }}
                                                className="w-50 m-auto"
                                            >
                                                Añadir propiedad HubSpot
                                            </Button>
                                        </div>
                                    
                                    </div>
                                    
                                    <InputBox
                                        id="bot_message_failure_process"
                                        label="Mensaje al usuario si hay fallas en el proceso en el bot"
                                        placeholder="Ejm: Hemos detectado un error, intenta nuevamente"
                                        name="message_failure_process"
                                        value={getFieldProps("message_failure_process").value}
                                    />

                                    <div className="d-flex justify-content-end btn-group-form">
                                        <Button
                                            btnType="primary"
                                            type="submit"
                                            className="me-2 w-100"
                                            disabled={
                                                (isInitialValid as boolean) ||
                                                !isValid
                                            }
                                            isSubmitting={isSubmitting}
                                        >
                                            {mode === "create"
                                                ? "Crear"
                                                : "Actualizar"}{" "}
                                            bot
                                        </Button>
                                        <Button
                                            btnType="tertiary"
                                            type="button"
                                            onClick={() => {
                                                setVisible(false);
                                                resetForm();
                                            }}
                                        >
                                            Cancelar
                                        </Button>
                                    </div>
                                </FormBody>
                            </Form>
                        )}
                    </Formik>
            </FloatForm>
           
        );
    }

    if (mode === "delete") {
        return (
            <FloatForm visible={visible} mode={mode}>
                <FormHeader>
                    <span>Eliminar Bot</span>
                </FormHeader>
                <Form>
                    <FormBody>
                        <h2>¿Estas seguro que deseas eliminar este bot?</h2>
                        <div className="d-flex justify-content-end">
                            <Button
                                btnType="primary"
                                type="submit"
                                className="me-2 w-100"
                                onClick={() => {
                                    setVisible(false);
                                }}
                            >
                                Eliminar bot
                            </Button>
                            <Button
                                btnType="tertiary"
                                type="button"
                                onClick={() => {
                                    setVisible(false);
                                }}
                            >
                                Cancelar
                            </Button>
                        </div>
                    </FormBody>
                </Form>
            </FloatForm>
        );
    }

    return null;
};

export default BotForm;


import { EConditionTypes } from "../../types/requests/bot/conditionTypes.enum";

export const GetConditionTypeText = (type: EConditionTypes) => {
    switch (type) {
        case EConditionTypes.EQUAL_TO:
            return "Es igual a (=)";
        case EConditionTypes.EQUAL_TO_SENSITIVE:
            return "Es igual a (Caso sensitivo)";
        case EConditionTypes.CONTAIN_TEXT:
            return "Contiene el texto";
        case EConditionTypes.BIGGER_THAN:
            return "Es mayor a (>)";
        case EConditionTypes.BIGGER_OR_EQUAL_THAN:
            return "Es mayor o igual a (>=)";
        case EConditionTypes.LOWER_THAN:
            return "Es menor a (<)";
        case EConditionTypes.LOWER_OR_EQUAL_THAN:
            return "Es menor o igual a (<=)";
        case EConditionTypes.IS_EMAIL:
            return "Es un correo valido";
        case EConditionTypes.IS_NUMBER:
            return "Es un numero";
        case EConditionTypes.NOT_EQUAL:
            return "Es diferente a";
    }
}
import React from 'react'
import { IconProps } from './sendIcon.component'
import { EColors } from '../../../../types/styles/colors.enum'

const FocusIcon: React.FC<IconProps> = ({ colorCode = EColors.primary, ...props }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33333 14C2.96667 14 2.65267 13.8696 2.39133 13.6087C2.13044 13.3473 2 13.0333 2 12.6667V10H3.33333V12.6667H6V14H3.33333ZM10 14V12.6667H12.6667V10H14V12.6667C14 13.0333 13.8696 13.3473 13.6087 13.6087C13.3473 13.8696 13.0333 14 12.6667 14H10ZM8 10C7.44444 10 6.97222 9.80556 6.58333 9.41667C6.19444 9.02778 6 8.55556 6 8C6 7.44444 6.19444 6.97222 6.58333 6.58333C6.97222 6.19444 7.44444 6 8 6C8.55556 6 9.02778 6.19444 9.41667 6.58333C9.80556 6.97222 10 7.44444 10 8C10 8.55556 9.80556 9.02778 9.41667 9.41667C9.02778 9.80556 8.55556 10 8 10ZM2 6V3.33333C2 2.96667 2.13044 2.65267 2.39133 2.39133C2.65267 2.13044 2.96667 2 3.33333 2H6V3.33333H3.33333V6H2ZM12.6667 6V3.33333H10V2H12.6667C13.0333 2 13.3473 2.13044 13.6087 2.39133C13.8696 2.65267 14 2.96667 14 3.33333V6H12.6667Z" fill={colorCode} />
        </svg>

    )
}

export default FocusIcon;
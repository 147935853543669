import IWhatsAppAccount from './whatsAppAccount.interface';

enum EChannels {
    WPP = "whatsapp",
    FB = "facebook"
}

export { EChannels, IWhatsAppAccount }

export default interface IAccount<T extends object = any> {
    _id: string;
    sender: string;
    connection: T;
    channel: EChannels,
    bot: string;
}
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react'
import { getClientGroupSubgroup } from '../../../services/client.service';
import { IGroupSubgroup } from '../../../types/requests/client/client.interface';
import SelectBox from '../../UI/molecules/inputs/selectBox.component'

export const FormGroupSubGroup = () => {
    const formik = useFormikContext<{
        botId: string,
        group: string,
        subgroup: string,
    }>();

    const { values } = formik;
    const [optionsGroups, setOptionsGroups] = useState<string[]>([]);
    const [optionsSubGroups, setOptionsSubGroups] = useState<string[]>([]);
    const [dataGroups, setDataGroups] = useState<IGroupSubgroup>({});

    useEffect(() => {
        setData();
    }, [])
    
    useEffect(() => {
        if(values.group) {
            let findGroup = dataGroups[values.group];
            if(!findGroup) return;
            let dataSubGroups = Object.keys(findGroup);
            setOptionsSubGroups(dataSubGroups);
            let findData = dataSubGroups.find(el => el === formik.getFieldProps("subgroup").value) 
            if(!findData) formik.setFieldValue("subgroup", "");
        }
    }, [values.group])
       
    const setData = async() => {

        let listGroupSubGroups = await getClientGroupSubgroup();
        setDataGroups(listGroupSubGroups);

        let listGroups = Object.keys(listGroupSubGroups);
        setOptionsGroups(listGroups);
        const group = formik.getFieldProps("group").value;
        if(group) {
            let findGroup = listGroupSubGroups[group];
            if(!findGroup) return;
            let dataSubGroups = Object.keys(findGroup);
            setOptionsSubGroups(dataSubGroups);
            
        }
    }

  return <>
    <SelectBox
        id='token-group'
        label='Seleccione el Grupo'
        name='group'
    >
        <option value={""}>--Selecciona un Grupo--</option>
        {optionsGroups.map((group, idx) => {
            return  <option value={group} key={`group-${idx}`}>{group}</option>
        })}
    </SelectBox>
    <SelectBox
        id='token-subgroup'
        label='Seleccione el SubGrupo'
        name='subgroup'
    >
        <option value={""}>--Selecciona un Subgrupo--</option>
        {optionsSubGroups.map((subgroup, idx) => {
            return  <option value={subgroup} key={`subgroup-${idx}`}>{subgroup}</option>
        })}
    </SelectBox>
  </>
}

import React from 'react';
import StepsFormProvider from '../../context/stepsForm.context';
import BotFormProvider from '../../context/botForm.context';
import EditTemplate from '../../components/templates/edit/edit.template';
import ActionFormTemplate from '../../components/templates/stepForm/actionForm.template';
import StepFormTemplate from '../../components/templates/stepForm/stepForm.template';
import ConditionalFormTemplate from '../../components/templates/stepForm/conditionalForm.template';
import BotForm from '../../components/templates/botForm/botForm.template';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import useBotMutation from '../../hooks/useBotMutation.hook';
import ErrorWrapper from '../../components/UI/atoms/wrapper/errorWrapper.component';
import LoaderWrapper from '../../components/UI/atoms/wrapper/loaderWrapper.component';
import EditLoader from '../../components/templates/edit/edit.loader';
import { getDraftBot } from '../../services/draftBots.service';

const EditPage: React.FC = () => {

    const { botId } = useParams();
    const { data: bot, isLoading, isError, error } = useQuery('draft_bot', async () => await getDraftBot(botId!), { retry: false });
    const queryClient = useQueryClient();

    const setBot = useBotMutation(queryClient, bot?._id);

    return (
        <ErrorWrapper error={error}>
            <LoaderWrapper
                isLoading={isLoading || isError}
                loader={<EditLoader />}
            >
                <StepsFormProvider>
                    <BotFormProvider>
                        <EditTemplate />
                        <ActionFormTemplate />
                        <StepFormTemplate />
                        <ConditionalFormTemplate />
                        <BotForm
                            mode='update'
                            bot={bot}
                            submitForm={async (bot) => {
                                setBot.mutate({botProps: bot, notification: {register: `Actualizar Información de Bot: ${bot.name}`, url: window.location.href}});
                            }}
                        />
                    </BotFormProvider>
                </StepsFormProvider>
            </LoaderWrapper>
        </ErrorWrapper>
    )
}

export default EditPage;
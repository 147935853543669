import React, { useEffect, useState } from 'react';
import { IStep } from '../../../../types/requests/bot/step.interface';
import { StepsContainer } from '../../molecules/steps/step.styles';
import FixedStep from '../../molecules/steps/fixedStep.component';
import Conditional from '../../molecules/steps/conditional.component';
import Step from '../../molecules/steps/step.component';
import { groupByProperty } from '../../../../utils/arrays.utils';
import { EMessageType } from '../../../../types/requests/bot/stepType.enum';
import { IDraftBotResponse } from '../../../../types/requests/bot/draftbot.interface';
import { EActions } from '../../../../types/requests/bot/actions.enum';

export interface FlowProps {
    steps: IStep[],
    globalSteps: IStep[],
    main?: boolean
    currentBot?: IDraftBotResponse,
}

const Flow: React.FC<FlowProps> = ({ steps, globalSteps, main = false, currentBot }) => {

    const [validateBotStart, setValidateBotStart] = useState("V1");
    useEffect(() => {
        let findStepStart = currentBot?.steps.find(step => step.action.type === EActions.START);
        setValidateBotStart(findStepStart ? "V2" : "V1");
    }, [currentBot])
    
    return (
        <StepsContainer orientation='h'>
            {
                main &&  validateBotStart === "V1" && <FixedStep
                    type={'start'}
                    body={"El bot inicia al recibir un mensaje"}
                    stepId={'trigger'}
                />
            }
            {
                steps?.map((step) => {
                                        
                    if (step.condition || step.options) {

                        const subSteps = globalSteps.filter(globalStep => globalStep?.parentStep?.parent === step.id);
                        const groupsOfFlows = groupByProperty(subSteps, ["parentStep", "caseId"]) as { [name: string]: IStep[] }
                      
                        if(step.condition){
                            return (
                                <React.Fragment key={step.id}>
                                    <Conditional {...step} />
                                                                    
                                    <StepsContainer orientation="v">
                                        {
                                            step.condition.cases.map((flowCase, i) => {
                                                if (Object.keys(groupsOfFlows).includes(flowCase.caseId)) {
                                                    return <Flow steps={groupsOfFlows[flowCase.caseId]} key={i} globalSteps={globalSteps} />
                                                } else {
                                                    if(!flowCase.next) return  <FixedStep type={'finish'} stepId={flowCase.caseId} key={i} body='' />
                                                }
                                            })
                                        }
                                    </StepsContainer>
                                </React.Fragment>
                            )
                        }

                        if(step.options && step.options.length > 0){
                            return (
                                <React.Fragment key={step.id}>
                                    <Step {...step} />
                                                                    
                                    <StepsContainer orientation="v">
                                        {
                                            step.options.map((option, i) => {
                                                if (Object.keys(groupsOfFlows).includes(option?.id)) {
                                                    return <Flow steps={groupsOfFlows[option.id]} key={i} globalSteps={globalSteps} />
                                                } else { 
                                                    if(!option.next) return <FixedStep type={'finish'} stepId={option.id} key={i} body='' />                       
                                                }
                                            })
                                        }
                                    </StepsContainer>
                                </React.Fragment>
                            )
                        }

                        if(step.go_to){
                            return (
                                <React.Fragment key={step.id}>
                                    <Step {...step} />
                                                                    
                                </React.Fragment>
                            )
                        } 
                    }

                    if (step.finish === true) {
                        return (
                            <React.Fragment key={step.id}>
                                <Step {...step} key={step.id} />
                                <FixedStep type={'finish'} stepId={step.id} />
                            </React.Fragment>
                        )
                    }

                    return <Step {...step} key={step.id} />
                })
            }
        </StepsContainer>
    )
}

export default Flow;
import styled from "styled-components";


export const ConversationTable = styled.table<{withoutRight?: boolean}>`
  border-radius: ${props => props.withoutRight ? "8px 0px 0px 8px" : "8px" };
  border-right: ${props => props.withoutRight ? "0px none !important" : "8px" } ;
  background-color: white;
  font-size: 14px !important;
  width: 100%;
  border: solid 1px var(--border-color);
  overflow: hidden;
  max-height: 100%;

  tr {
    border-bottom: solid 1px var(--border-color);
    width: max-content;
  }

  thead {
    color: white;
    border-radius: 8px 0px 0px 0px;
    width: max-content;
    tr {
      background-color: var(--header-conversation-color);
      th {
        padding: 12px;
        white-space: nowrap;
      }
    }
  }

  tbody {
    border-radius: 0px 0px 0px 8px;
    width: max-content;
    tr {
      td {
        padding: 12px;
        user-select: text;
      }
    }
  }

  tfoot {
    border-radius: 0px 0px 0px 8px;
  }

  @media only screen and (max-width: 1250px) {
    td, th {
      white-space: nowrap;
    }
  }
  
`;

export const Row = styled.tr<{active: boolean}>`
  
  background-color: ${props => props.active ? "#FEF0E6": "transparent"};
  cursor: pointer;
  &:hover {
    background-color: #FEF0E6;
  }

  td {
    max-width: max-content;
  }
`;

export const HighlightedRow = styled.tr<{rowColor: string}>`
  background-color:  ${(props) => props.rowColor };
  cursor: pointer;
  &:hover {
    background-color: #fef0e6;
  }

  td {
    max-width: max-content;
  }
`;
import { IBot } from "../types/requests/bot/bot.interface";
import { useQueryClient, UseMutationResult } from "react-query";
import StepsHandler from "../utils/bots/steps.utils";
import AnswersHandler from "../utils/bots/answers.utils";
import { AxiosResponse } from 'axios';
import useBotMutation from "./useBotMutation.hook";
import { IDraftBotResponse } from "../types/requests/bot/draftbot.interface";

export interface useBotResult {
    bot: IBot | undefined
    stepsHandler: StepsHandler
    answersHandler: AnswersHandler
    setBot: UseMutationResult<AxiosResponse<any, any>, unknown, Partial<IBot>, unknown>
}

const useBot = () => {
    const queryClient = useQueryClient();
    const bot = queryClient.getQueryData<IDraftBotResponse>('draft_bot');
    const stepsHandler = new StepsHandler(bot!.steps);
    const answersHandler = new AnswersHandler(bot!.answers);
    const setBot = useBotMutation(queryClient, bot!._id);

    return { bot, stepsHandler, answersHandler, setBot }
}

export default useBot;
export enum EColors {
    primary = "#9747FF",
    primaryTransparent = "#9747FF40",
    secondary = "#5537b3",
    tertiary = "#ded3ff",
    quaternary = "#bca7ff",
    extra = "black",
    white = "#ffffff",
    text = "#3D3D3D",
    talksPro = "#FA7820",
    bgGray = "#EBEBEB",
    gradient = "linear-gradient(180deg, #E5B2CA 0%, #7028E4 100%)",
    lightGray = "#cccccc24",
    gray= "#c3c3c3"
}
export enum EActions {
    SEND_MESSAGE = "send_message",
    SAVE_OR_UPDATE = "hs_save_or_update",
    GET = "hs_get",
    CONDITION = "condition",
    SEND_TO_ASSESOR = "send_to_assesor",
    GO_ACTION = "go_action",
    GROUP_AND_SUBGROUP = "group_and_subgroup",
    REDIRECT_ADVISOR = "redirect_advisor",
    START = "start",
}
import styled from "styled-components";
import { EColors } from "../../../../types/styles/colors.enum";

export interface TagProps {
    type: {
        bg: EColors | string,
        color: EColors | string,
        border?: EColors | string
    }
}

export const TagTypes = {
    primary: {
        bg: EColors.secondary,
        color: EColors.white,
        border: EColors.secondary
    },
    secundary: {
        bg: EColors.extra,
        color: EColors.white,
        border: EColors.extra
    },
    terciary: {
        bg: "#c1c1c1",
        color: "#202124"
    },
    ramification: {
        bg: "#FFF",
        color: "#202124",
        border: "#202124"
    },
}


const Tag = styled.span<TagProps>`
    display: inline-block;
    padding: 2px 4px;
    background: ${({ type }) => type?.bg};
    color: ${({ type }) => type?.color};
    border: ${({ type }) => `solid 1px ${type?.border ?? type?.bg}`};
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    margin: 4px;    
    width: max-content;
`;

export default Tag;
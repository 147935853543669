import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Field, useFormikContext, FieldAttributes } from 'formik';

import Select, { SingleValue } from 'react-select';
import { customStyles } from '../../../../utils/theme/SelectTheme';
import InputError from '../../atoms/input/inputError.component';

//! CORREGIR ANYS
export interface SelectBoxProps extends FieldAttributes<any> {
    label: string;
    name: string;
    id: string;
    className?: string;
    disabled?: boolean;
}

const SelectSearch: React.FC<PropsWithChildren<SelectBoxProps>> = ({ label, name, id, className = "", ...props }) => {

    const formikContext = useFormikContext();    
    const [selectedOption, setSelectedOption] = useState<SingleValue<{ value: string; label: string }>>({ value: "", label: "" })
    const meta = formikContext.getFieldMeta(name);

    useEffect(() => {
        if(props.options.length > 0) setSelectedOption(props.options.find((option: {value: "", label: ""}) => props.value === option.value) );

    }, [props.options, props.value])
    
    
// Define el tipo correcto para la función handleChange
    const handleChange = (newValue: SingleValue<{ value: string; label: string }>) => {
        setSelectedOption(newValue);
        formikContext.setFieldValue(name, newValue?.value);
    };

    return (
        <div className='form-group'>
            <label className="form-label" htmlFor={id}>{label}</label>
            <Select
                value={selectedOption}
                onChange={handleChange}
                options={props.options}
                id={id}
                name={name}
                className={`${className} ${(meta.error) ? "is-invalid " : ""}`}
                isDisabled={props.disabled || false}
                styles={customStyles}
            />
            { meta.error && (
                <InputError> {meta.error} </InputError>
            )}
        </div>
    )
}

export default SelectSearch;
export const styleTheme = (theme) => {
  return {
    ...theme,
    borderRadius: 6,
    colors: {
      ...theme.colors,
      primary25: 'var(--primary-color-light)',
      primary: 'var(--primary-color)',
    },
    option: (provided, state) => ({
        ...provided,
        backgroundColor: "red"   ,            // Color de fondo por defecto
        color: state.isSelected ? 'white' : 'black', // Color del texto
        padding: 10,                         // Espaciado interno
      }),
  };
};
/* 
export const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: "white", // aquí puedes cambiar el color de fondo opciones
      minWidth: "120px",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent", // aquí puedes cambiar el color de fondo principal
      fontSize: "16px",
      paddingLeft: "5px",
      borderRadius: "4px",
      borderColor: "var(--Grey)", // aquí puedes cambiar el color del borde
      boxShadow: state.isFocused ? "0 0 0 2px var(--Mystic-opacity-25)" : "none", // aquí puedes ajustar el efecto de sombra
      "&:hover": {
        borderColor: "var(--Mystic)", // aquí puedes cambiar el color del borde en estado hover
      },
      minWidth: "120px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "var(--Grey)", // aquí puedes cambiar el color del campo de selección
    }),
    option: (provided, state) => ({
      ...provided,
      color: "white", // aquí puedes cambiar el color de texto
      backgroundColor: "var(--primary-color)", // aquí puedes cambiar el color de fondo
      "&:hover": {
        color: "white", // aquí puedes cambiar el color de texto en estado hover
        backgroundColor: 'var(--primary-color-light)', // aquí puedes cambiar el color de fondo en estado hover
      },
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: "var(--DarkLust)",
      border: "1px solid var(--Mystic)",
      borderRadius: "4px",
    }),
  
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
    }),
    container: (styles, { data }) => ({
      ...styles,
      width: "100%",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "var(--Mystic)",
  
      ":hover": {
        backgroundColor: "#8f2c2c",
        color: "white",
        cursor: "pointer",
      },
    }),

  }; */
  // Estilos personalizados
export const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected 
      ? 'var(--primary-color)' 
      : state.isFocused 
      ? 'var(--primary-color-light)' // Color para el estado `hover`
      : 'white',
      color: state.isSelected ? 'white' : 'black',
      padding: 10,

      // Puedes agregar más estilos específicos aquí
    }),
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'var(--primary-color)',
      },
      // Otros estilos para el control
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Ajusta el zIndex para que el menú esté sobre otros elementos
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 9999, // También ajusta el zIndex del portal del menú
      }),
    
  };
import React from 'react';
import Button from '../../atoms/button/button.component';

export interface PageHeaderProps {
    title: string;
    subtitle: string;
    button: React.ReactNode;
    action: React.MouseEventHandler<HTMLButtonElement>
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle, button, action }) => {
    return (
        <div className="d-flex flex-wrap justify-content-lg-start justify-content-center mb-3">
            <div className='flex-fill'>
                <h5 className='text-center text-md-start'>{title}</h5>
                <p className='fw-light text-center text-md-start'>{subtitle} </p>
            </div>
            <div>
                <Button onClick={action} btnType='primary'>{button}</Button>
            </div>
        </div>
    );
}

export default PageHeader;
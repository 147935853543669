import React from 'react'

const ArrowRightIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5.31344 13.0202C5.13593 12.8427 5.11979 12.5649 5.26503 12.3692L5.31344 12.3131L9.62633 7.99996L5.31344 3.68684C5.13593 3.50933 5.11979 3.23156 5.26503 3.03582L5.31344 2.97974C5.49095 2.80223 5.76873 2.78609 5.96447 2.93133L6.02055 2.97974L10.6872 7.64641C10.8647 7.82392 10.8809 8.10169 10.7356 8.29743L10.6872 8.35351L6.02055 13.0202C5.82528 13.2154 5.5087 13.2154 5.31344 13.0202Z" fill="#3D3D3D" stroke="#3D3D3D" strokeWidth="2" />
    </svg>
  )
}

export default ArrowRightIcon
import { FieldHookConfig, useField, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import InputError from "../../atoms/input/inputError.component";
import { IOption, IStep } from "../../../../types/requests/bot/step.interface";

import { idText } from "typescript";
import { EMessageType } from "../../../../types/requests/bot/stepType.enum";


export type InputButtonProps = FieldHookConfig<string> & {
    id: string;
    label: string;
    position: number;
    length: number;
    data?: IOption;
    steps?: IStep[];
    currentStepId?: string;
    as?:string;
    disabled?: boolean;
    value?: string;
    viewCount?: boolean;
    maxCount?: number;
};

const InputButton: React.FC<InputButtonProps> = ({
    label,
    className,
    steps,
    currentStepId,
    ...props
}) => {
    const formik = useFormikContext<IStep>();
    const [field, meta] = useField(props);
    const [data, setData] = useState<IOption>({
        title: "",
        description: "",
        next: "",
        id: "",
    });
    const [valueField, setValueField] = useState("");

    let fieldProps = {
        className:
            "form-control " +
            (meta.error && meta.touched ? "is-invalid " : "") +
            className,
    };

    useEffect(() => {
        setInitialData(props);
    }, []);
    useEffect(() => {
        if(props.id.startsWith("title")) {
            setValueField(data.title);
        } else if(props.id.startsWith("description")){
            setValueField(data?.description || "");
        }
    }, [data]);

    useEffect(() => {
        if (props.data && props.data.id) {
            setData({...props.data, next: props.data.next || ""});
            formik.setFieldValue(`options.${props.position}`, {
                title: props.data.title,
                id: props.data.id,
                next: props.data.next,
                description: props.data.description,
            });

        };
    }, [props.data]);

    const setInitialData = (props: any) => {
        if (props.data) {
            setData({...props.data, next: props.data.next || ""});
            formik.setFieldValue(`options.${props.position}`, {
                title: props.data.title,
                id: props.data.id,
                next: props.data.next,
                description: props.data.description,
            });
        };
    };

    const handleChangeButton = (e: any) => {
        const newData = { ...data };
        let index = props.position;
        let getOption = formik.getFieldProps(`options.${index}`);
        if(e.target.id.startsWith("step")){
            if(props.id.includes("No_cumple")){
                newData.next = e.target.value;
                newData.id = props.name;
                newData.title = EMessageType.NO_CUMPLE;
            } else {
                newData.next = e.target.value;
                newData.id = getOption!.value?.id || "";
                newData.title = getOption!.value?.title || "";
            }
            
            setData(newData);
            formik.setFieldValue(`options.${index}`, {
                next: newData.next,
                id: newData.id,
                title:  newData.title,
            });
        } else if(e.target.id.startsWith("title")){
            let dataValue = e.target.value;
            if(dataValue.length > (props.maxCount || 100)){
                dataValue = dataValue.slice(0, props.maxCount)
            }
            newData.title = dataValue;
            newData.id = e.target.id;
            setData(newData);
            formik.setFieldValue(`options.${index}`, {
                title: e.target.value.slice(0, props.maxCount),
                description: getOption!.value?.description || "",
                id: e.target.name,
                next: getOption!.value?.next || undefined,
            });
        } else if(e.target.id.startsWith("description")) {
            let dataValue = e.target.value;
            if(dataValue.length > (props.maxCount || 100)){
                dataValue = dataValue.slice(0, props.maxCount)
            }
            newData.description = dataValue;
            newData.id = e.target.id;
            setData(newData);
            formik.setFieldValue(`options.${index}`, {
                title: getOption!.value?.title || "",
                description: e.target.value.slice(0, props.maxCount),
                id: e.target.name,
                next: getOption!.value?.next || undefined,
            });
        }
    };

    const handleField = (as: string) => {
        switch (as) {
            case "textarea":
                return <textarea {...fieldProps}></textarea>;
            case "select":
                return (<select name={props.name} id={props.id} value={data.next} onChange={handleChangeButton} {...fieldProps}>
                    <option value="" >
                        Siguiente paso: Sin paso siguiente
                    </option>
                    {steps!
                        .filter((step) => step.id !== currentStepId)
                        .map((step, idx) => {
                           
                            return (<option key={step.id} value={step.id}>
                                    Siguiente paso: {step.name}
                                </option>)
                        })}
                        </select>);
            default:
                return (
                    <input
                        id={props.id}
                        value={valueField}
                        type="text"
                        name={props.name}
                        onChange={handleChangeButton}
                        disabled={props.disabled || false}
                        {...fieldProps}
                    />
                );
        }
    };

    return (
        <div className="form-group">
            <label className="form-label" htmlFor={props.id}>
                {label}
            </label>
            <>
                {handleField(props.as?.toString() ?? "input")}
                {props.viewCount && <div className={`count-input ${valueField.length > (props.maxCount || 100) && "red"}`}>{valueField.length}/{props.maxCount || 100}</div>}
                {meta.touched && meta.error && (
                    <InputError> {meta.error} </InputError>
                )}
            </>
            
        </div>
    );
};

export default InputButton;


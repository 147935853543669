import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { IOption, IStep } from "../../../../../types/requests/bot/step.interface";

import SelectBox from "../../../molecules/inputs/selectBox.component";
import { useState } from "react";
import { EMessageType } from "../../../../../types/requests/bot/stepType.enum";
import { IEnvironmentBot } from "../../../../../types/requests/bot/environment.interface";
import { IAnswer } from "../../../../../types/requests/bot/answer.interface";
import StepsHandler from "../../../../../utils/bots/steps.utils";
import useBot from "../../../../../hooks/useBot.hook";
import { getClientGroupSubgroup } from "../../../../../services/client.service";
import { IGroupSubgroup } from "../../../../../types/requests/client/client.interface";

export interface MessageActionFieldsProps {
    steps: IStep[];
    currentStep: IStep;
    env: IEnvironmentBot[];
    answers: IAnswer[]
}

export interface whiteListInterface {
    value: string;
    prefix?: string;
}

const GroupSubgroup: React.FC<MessageActionFieldsProps> = ({
    steps,
    currentStep,
}) => {
    const formik = useFormikContext<{
        answer: string;
        options: object[];
        redirect: {
            active: false;
            message: "";
            showMessage: false;
        };
    }>();
    const { stepsHandler } = useBot();
    const { values } = formik;
    const [selectedOption, setSelectedOption] = useState({group: "", subgroup: ""});
    const [dataGroups, setDataGroups] = useState<IGroupSubgroup>({});
    const [optionsGroups, setOptionsGroups] = useState<string[]>([]);
    const [optionsSubGroups, setOptionsSubGroups] = useState<string[]>([]);

    const setData = async(currentStep: IStep) => {
        let GroupSubgroup = await getClientGroupSubgroup();
        setDataGroups(GroupSubgroup);
        let arrayGroups = Object.keys(GroupSubgroup);
        setOptionsGroups(arrayGroups);
        if(currentStep.group) {
            const groupData = GroupSubgroup[currentStep.group];
            let arraySubGroups = Object.keys(groupData);
            setOptionsSubGroups(arraySubGroups);
            formik.setFieldValue("group", currentStep.group);
            formik.setFieldValue("subgroup", currentStep.subgroup);
        }
        setSelectedOption({subgroup: currentStep.subgroup || "", group: currentStep.group || ""});
        formik.setFieldValue("type", EMessageType.GROUP_AND_SUBGROUP);
    };
    useEffect(() => {
     setData(currentStep)
    }, [])

    const handleChange = (event: React.ChangeEvent<{ value: string, name: string }>) => {
        let {name, value} = event.target;
        if(name === "group") {
            const groupData = dataGroups[value];
            let arraySubGroups = Object.keys(groupData);
            setOptionsSubGroups(arraySubGroups);
            setSelectedOption({subgroup: "", group: value});
            formik.setFieldValue("group", value);
            formik.setFieldValue("subgroup", "");
        } else {
            setSelectedOption({...selectedOption, subgroup: value});
            formik.setFieldValue("subgroup", value);
        }
    };

    return (
        <>
            <SelectBox
                id="group-settings"
                name="group"
                label="Seleccione el Grupo"
                value={selectedOption.group}
                onChange={handleChange}
            >
                <option value="" disabled>
                    --- Selecciona un grupo ---
                </option>
                {optionsGroups.map((option, idx) => {
                    return  <option key={`group-${idx}`} value={option}>
                       {option}
                    </option>
                })}
            </SelectBox>
            <SelectBox
                id="group-settings"
                name="subgroup"
                label="Seleccione el Subgrupo"
                value={selectedOption.subgroup}
                onChange={handleChange}
            >
                <option value="" disabled>
                    --- Selecciona un subgrupo ---
                </option>
                {optionsSubGroups.map((option, idx) => {
                    return  <option key={`group-${idx}`} value={option}>
                       {option}
                    </option>
                })}
            </SelectBox>
        </>
    );
};

export default GroupSubgroup;


import React, { useContext, useEffect, useState } from "react";
import withCurrentStep, {
    withCurrentStepProps,
} from "../../../HOC/withCurrentStep.hoc";
import {
    ModeType,
    StepsFormContext,
    StepsFormContextType,
} from "../../../context/stepsForm.context";
import { EActions } from "../../../types/requests/bot/actions.enum";
import { IStep } from "../../../types/requests/bot/step.interface";
import { IDType } from "../../../types/requests/bot/bot.interface";
import { ICase, ICondition } from "../../../types/requests/bot/case.interface";
import {
    FlexGap,
    FormBody,
    FormHeader,
    StepFormContainer,
} from "../../UI/molecules/steps/step.styles";
import Button from "../../UI/atoms/button/button.component";
import SendIcon, { IconProps } from "../../UI/atoms/icon/sendIcon.component";
import SaveIcon from "../../UI/atoms/icon/saveIcon.component";
import RecoveryIcon from "../../UI/atoms/icon/recoveryIcon.component";
import ConditionIcon from "../../UI/atoms/icon/conditionIcon.component";
import useBot from "../../../hooks/useBot.hook";
import ActionIcon from "../../UI/atoms/icon/actionIcon.component";
import GroupIcon from "../../UI/atoms/icon/groupIcon.component";
import AdvisorIcon from "../../UI/atoms/icon/advisorIcon.component";
import { EMessageType } from "../../../types/requests/bot/stepType.enum";

let iconProps: IconProps = {
    colorCode: "white",
    width: "24",
    height: "24",
    className: "me-1",
};

const ActionForm: React.FC<withCurrentStepProps> = ({ currentStep }) => {
    const { currentStepId, setMode, setVisible, setIsNew } = useContext(
        StepsFormContext
    ) as StepsFormContextType;
    const { stepsHandler, setBot } = useBot();
    const [validGetHubSpot, setValidGetHubSpot] = useState(false)
    useEffect(() => {
        const previous = stepsHandler.getPreviousBranch([],currentStep.previous || "");
        
        const IdStart = previous.find(el => el.action.type === EActions.START)?.id;
        const findCaseId = previous.find(el => el.parentStep?.caseId === IdStart + "_1");
        const findSaveHs = previous.find(el => el.action.type === EActions.SAVE_OR_UPDATE);
        setValidGetHubSpot( findSaveHs ? true: findCaseId ? false: true);
    
    }, [])
    
    const handleCancel = async () => {
        stepsHandler.removeStep(
            currentStep.id,
            currentStep.next,
            currentStep.finish,
            currentStep?.parentStep?.caseId
        );
        let optionStep = stepsHandler.getStep(currentStep?.parentStep?.parent!);
        if(optionStep &&  ![EMessageType.NORMAL_MESSAGE].includes(optionStep?.type)) {
            let findOption = optionStep.options?.find(el => el.next === currentStep.id);
            if(findOption) {
                findOption.next = currentStep.next;
                stepsHandler.setStep(currentStep.parentStep?.caseId!,optionStep )
            }
        }
        await setBot.mutateAsync({ botProps: { steps: stepsHandler.steps }});
        setVisible(false); 
    };

    const setAction = async (
        action: EActions,
        mode: ModeType = "edit",
        defaults: Partial<IStep> = {}
    ) => {
        let updatedStep = {
            ...currentStep,
            action: { type: action },
            inactivity_messages: true,
            ...defaults,
        };

        stepsHandler.setStep(currentStepId!, updatedStep);
        await setBot.mutateAsync({botProps: {steps: stepsHandler.steps}});
        setMode(mode);
        setIsNew(true);
    };

    const createBranch = (id: IDType, conditions: ICondition[] = []): ICase => {
        let caseId = `${currentStepId}_${id}`;

        return {
            caseId,
            conditions,
            type: id === "no_matches" ? "NO_MATCHES" : "AND",
            conditionalStructure: "",
            next: undefined,

        };
    };

    return (
        <StepFormContainer visible={true}>
            <FormHeader>Selección de acción</FormHeader>

            <FormBody>
                <div className="form-group">
                    <span className="fw-medium">
                        Seleccione la acción que desea realizar
                    </span>
                    <FlexGap className="mt-2" gap="16px">
                        <Button
                            btnType="primary"
                            onClick={() => setAction(EActions.SEND_MESSAGE)}
                        >
                            <SendIcon {...iconProps} />
                            <span>Enviar mensaje</span>
                        </Button>

                        <Button
                            btnType="primary"
                            onClick={() => {
                                setAction(EActions.CONDITION, "conditional", {
                                    condition: {
                                        cases: [createBranch("no_matches")],
                                    },
                                    finish: false,
                                    enable_retries: true,
                                });
                            }}
                        >
                            <ConditionIcon {...iconProps} />
                            <span>Agregar condición</span>
                        </Button>

                        <Button
                            btnType="primary"
                            disabled={!validGetHubSpot}
                            onClick={() => {
                                setAction(EActions.GET, "edit", {
                                    options: [
                                        {
                                            title: "has-data",
                                            id: `${currentStepId}_0`,
                                        },
                                        {
                                            title: "no-has-data",
                                            id: `${currentStepId}_1`,
                                        },
                                    ],
                                    hubspotValues: []
                                });
                            }}
                        >
                            <RecoveryIcon {...iconProps} />
                            <span>Consultar contacto en HubSpot.</span>
                        </Button>

                        <Button
                            btnType="primary"
                            onClick={() => {
                                setAction(EActions.SAVE_OR_UPDATE, "edit", {
                                    options: [
                                        {
                                            title: "success",
                                            id: `${currentStepId}_0`,
                                        },
                                        {
                                            title: "error",
                                            id: `${currentStepId}_1`,
                                        },
                                    ],
                                });
                            }}
                        >
                            <SaveIcon {...iconProps} />
                            <span>Guardar datos del contacto en HubSpot.</span>
                        </Button>

                        <Button
                            btnType="primary"
                            onClick={() => {
                                setAction(EActions.GO_ACTION);
                            }}
                            disabled={currentStep.next ? true: false}
                        >
                            <ActionIcon {...iconProps} />
                            <span>Ir a acción</span>
                        </Button>

                        <Button
                            btnType="primary"
                            onClick={() => {
                                setAction(EActions.GROUP_AND_SUBGROUP);
                            }}
                        >
                            <GroupIcon {...iconProps} />
                            <span>Cambiar Grupo y Subgrupo</span>
                        </Button>

                        <Button
                            btnType="primary"
                            onClick={() => {
                                setAction(EActions.REDIRECT_ADVISOR);
                            }}
                            disabled={currentStep.next ? true: false}
                        >
                            <AdvisorIcon {...iconProps} />
                            <span>Enviar a un Asesor</span>
                        </Button>
                    </FlexGap>
                </div>

                <Button
                    btnType="tertiary"
                    className="w-100"
                    onClick={handleCancel}
                >
                    Cancelar
                </Button>
            </FormBody>
        </StepFormContainer>
    );
};

export default withCurrentStep(ActionForm, React.Fragment, "set_action");

import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import Button from '../../atoms/button/button.component';
import FocusIcon from '../../atoms/icon/focusIcon.component';
import { EColors } from '../../../../types/styles/colors.enum';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { createBot, generateBot, getBotByIdDraft, updateBot } from '../../../../services/bots.service';
import { IStep } from '../../../../types/requests/bot/step.interface';
import { IDraftBotResponse } from '../../../../types/requests/bot/draftbot.interface';
import { deleteDraftBot } from '../../../../services/draftBots.service';
import { AlertForm, AlertHTML, ICON } from '../../../../utils/Alert';
import { BotFormContext, BotFormContextType } from '../../../../context/botForm.context';
import { compareArrays, compareObjects } from '../../../../utils/pipes/array.pipe';
import BotInfo from '../bot/botInfo.component';
import BotIcon from '../../atoms/icon/botIcon.component';
import { validateBot } from '../../../../utils/bots/validate.utils';

export const ControlsPosition = styled.div<{ complete: boolean }>`
    position: fixed;
    bottom: 0px;
    max-width: ${props => props.complete === true ? '100vw' : 'calc(100vw - 320px)' } ;
    width: 100%;
    background: rgb(77, 77, 77, 0.95);
    padding: 16px;
    overflow: visible;
    /* box-shadow: 5px 4px 8px rgb(0 0 0 / 25%); */
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    transition: 1s;
    & > .bot-info {
        position: relative;
    }
    & > .title-bot {
        position: fixed;
        top: 4rem;
        left: 1rem;
    }

    & .text-pending {
        color: white;
    }
`;

const ZoomableControls = styled.div`
    display: flex;
    gap: 8px;
`;

export interface ControlsProps {
    addZoom: () => void;
    removeZoom: () => void;
    resetZoom: () => void;
    setVisibleArrows: (value: boolean) => void;
    visibleArrows: boolean
    complete: boolean;
    steps: IStep[],
    bot: IDraftBotResponse;
}

const Controls: React.FC<ControlsProps> = ({ addZoom, removeZoom, resetZoom, setVisibleArrows, visibleArrows, complete, steps, bot}) => {
    const { isModified, setIsModified } = useContext(BotFormContext) as BotFormContextType;
    const [showInfo, setShowInfo] = useState<boolean>(true);
    const { botId } = useParams();
    const [searchParams] = useSearchParams(); 
    const navigate = useNavigate();
    
    useEffect(() => {
        if(botId) setData(botId);
    }, [botId])
    
    const setData = async(botId: string) => {
        let dataBot = await getBotByIdDraft(botId!);

        let compareSteps = compareArrays(bot.steps, dataBot.steps);
        let compareBotForm =  compareObjects(bot, dataBot);
        setIsModified(!(compareSteps && compareBotForm));
    }
    const htmlText = (validate: {
        valid: boolean;
        errors: string[];
    }[]) => {
        let text = "";
        for (const item of validate) {
            text += `    
                <li>${item.errors}</li>
            `
        }
        return `<ul>${text}</ul>`
    }

    const handlePublish = async() => {
        let validate = validateBot(bot, true);
        if(Array.isArray(validate)) {
            let filterValidate = validate.filter(el => el.valid === false);
            if(filterValidate.length) {
                let textHtml = htmlText(filterValidate);
                return AlertHTML({title: "No se puede publicar cambios", html: textHtml})
            }
        }
        const never_published = searchParams.get('never_published');
        delete bot._id;
        delete bot.__v;
        delete bot.archived;
        delete bot.never_published;
        if(never_published === "true") {
            // Crear un bot con datos del draft bot
            let botReq = await createBot({...bot, steps: steps, id_draft: null, version: "1"}, {register: "Publicar Bot en Editor", url: window.location.href});
            await deleteDraftBot(botId!);
            await generateBot(botReq._id);
        } else {
            // actualizar bot segun id_draft bot con datos del draft bot
            let dataBot = await getBotByIdDraft(botId!);
            await deleteDraftBot(botId!);
            await updateBot(dataBot._id, {...bot, steps: steps, version: String(Number(dataBot.version) + 1), id_draft: null}, {register: "Publicar Bot en Editor", url: window.location.href});
            await generateBot(dataBot._id);
        }
        
        AlertForm({title: "Bot Publicado con exito", icon: ICON.SUCCESS});
        navigate("/");
    }
    const handleDiscard = async() => {
        const never_published = searchParams.get('never_published');
        // Borrar draft
        if(never_published !== "true") {
            let dataBot = await getBotByIdDraft(botId!);
            await updateBot(dataBot._id, {id_draft: null}, {register: `Descartar cambios del Bot: ${dataBot.name}`, url: window.location.href});
        }
        await deleteDraftBot(botId!);       
        navigate("/");
    } 

    return (
        <ControlsPosition complete={complete}>
            <div className="d-flex title-bot">
                <div><BotIcon colorCode={EColors.primary} width="24" height="24" /></div>
                <h5 className="ms-1 text-truncate fw-medium" style={{ fontSize: "24px" }}>{bot?.name}</h5>
            </div>
            <div className='bot-info'>
                <BotInfo
                    showInfo={showInfo}
                    setShowInfo={setShowInfo}
                />
            </div>
            <div className="d-flex justify-content-center gap-3">
                <Button btnType='tertiary' onClick={() => setShowInfo(!showInfo)}>Configuración</Button>
                <div className='me-5'>
                    <ZoomableControls>
                        <Button btnType='tertiary' onClick={addZoom}>+</Button>
                        <Button btnType='tertiary' onClick={removeZoom}>-</Button>
                        <Button btnType='tertiary' onClick={resetZoom}>
                            <FocusIcon colorCode={EColors.text} />
                        </Button>
                        <Button btnType='tertiary' onClick={() => setVisibleArrows(!visibleArrows)}>
                            {visibleArrows ? "Ocultar" : "Mostrar"} flechas
                        </Button>
                    </ZoomableControls>
                </div>

                <div className='pl-3 border-left'>
                    <ZoomableControls>
                        <Button onClick={handlePublish} btnType='primary' disabled={!isModified}>Publicar cambios</Button>
                        <Button onClick={handleDiscard} btnType='custom' bgColor="red">{searchParams.get('never_published') === "true" ? "Eliminar": "Descartar"}</Button>
                    </ZoomableControls>
                </div>
                {isModified && <div className='d-flex align-items-center border-left pl-3'>
                    <p className='m-0 text-pending'>🟡 Con cambios pendientes</p>
                </div> }
            </div>
        </ControlsPosition>
    )
}

export default Controls;
import { FieldHookConfig, useField, useFormikContext } from "formik";
import React from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../atoms/floatingElements/tooltip.component";
import InputError from "../../atoms/input/inputError.component";
import BotQuestion from "../../../../assets/images/question.svg";

export type InputBoxProps = FieldHookConfig<string> & {
    id: string;
    label: string;
    disabled?: boolean;
    placeholder?: string;
    checked?: boolean;
    width?: string;
    row?: number;
    fieldLowerCase?: boolean;
    textHelp?: string | JSX.Element;
};

const InputBox: React.FC<InputBoxProps> = ({
    label,
    className,
    disabled,
    placeholder,  
    fieldLowerCase = false,
    textHelp,
    ...props
}) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);
    const handleChange = (e:any) => {
        const lowercaseValue = e.target.value.toLowerCase();
        setFieldValue(props.name, lowercaseValue);
    };
    let fieldProps = {
        className:
            "form-control " +
            (meta.error && meta.touched ? "is-invalid " : "") +
            className,
        disabled: disabled,
        placeholder: placeholder,
        ...field,
        
    };
 
    if(fieldLowerCase) {
        fieldProps.value= field.value || '';
        fieldProps.onChange= handleChange;
    }

    const handleField = (as: string) => {
        switch (as) {
            case "textarea":
                return (
                    <textarea {...fieldProps} rows={props.row || 3} value={props.value} style={{resize: "none"}}></textarea>
                );
            case "checkbox":
                const newClassName = fieldProps.className.replace(
                    "form-control",
                    "form-check-input me-2"
                );
                fieldProps = { ...fieldProps, className: newClassName };
                return (
                    <input
                        type="checkbox"
                        checked={props.checked}
                        {...fieldProps}                        
                    />
                );
            default:
                return <input type={props.type || "text"} {...fieldProps} value={props.value} />;
        }
    };

    if (props.as === "checkbox") {
        return (
            <div className="form-group">
                <>
                    {handleField(props.as?.toString() ?? "input")}

                    {meta.touched && meta.error && (
                        <InputError> {meta.error} </InputError>
                    )}
                </>
                <label className="form-label" htmlFor={props.id}>
                    {label} {textHelp && <Tooltip placement="top-start">
                            <TooltipTrigger> <img src={BotQuestion} alt="help" width={"16"} /> </TooltipTrigger>
                            <TooltipContent className="Tooltip">{textHelp}</TooltipContent>
                        </Tooltip>}
                </label>
            </div>
        );
    } else {
        return (
            <div className="form-group" style={{width: props.width}}>
                {label ? <label className="form-label" htmlFor={props.id}>
                    {label} {textHelp && <Tooltip placement="top-start">
                            <TooltipTrigger> <img src={BotQuestion} alt="help" width={"16"} /> </TooltipTrigger>
                            <TooltipContent className="Tooltip">{textHelp}</TooltipContent>
                        </Tooltip>}
                </label>: ""}
                <>
                    {handleField(props.as?.toString() ?? "input")}

                    {meta.touched && meta.error && (
                        <InputError> {meta.error} </InputError>
                    )}
                </>
            </div>
        );
    }
};

export default InputBox;


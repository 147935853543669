import React, { SVGProps } from 'react';
import { EColors } from '../../../../types/styles/colors.enum';

export interface IconProps extends SVGProps<SVGSVGElement> {
    colorCode?: string | EColors
}

const SendIcon: React.FC<IconProps> = ({ colorCode = EColors.text, ...props }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M47.5703 0.429653C47.1666 0.0259012 46.5642 -0.105828 46.0309 0.0880505L0.964918 16.5095C0.385158 16.7208 -0.000760976 17.2723 1.12669e-06 17.8889C0.000373223 18.5055 0.386635 19.0562 0.966728 19.2668L21.3396 26.6597L28.7325 47.0326C28.9431 47.6124 29.4939 47.9987 30.1105 47.9993H30.1116C30.7278 47.9993 31.2789 47.6138 31.4899 47.0344L47.9113 1.96844C48.1067 1.43222 47.9731 0.831958 47.5705 0.428615L47.5703 0.429653ZM30.1144 42.2395L24.1821 25.8915L30.2648 19.8088C30.8379 19.2357 30.8379 18.3076 30.2648 17.7342C29.6917 17.1608 28.7636 17.1612 28.1903 17.7342L22.1075 23.8176L5.75949 17.8853L44.0761 3.92361L30.1144 42.2395Z" fill={colorCode} />
        </svg>
    )
}

export default SendIcon;
import styled from "styled-components";


export const InputJump = styled.div<{ padding?: string, flexauto?: boolean }>`

    display: flex;
    justify-content: center;
    aling-items: column;
    gap: 1rem;
    padding: ${props => props.padding ? props.padding: "0px 0px 20px 0px"};

    & > * {
        flex:  ${props => props.flexauto ? "auto": "1"};
    }
`

export const LineJump = styled.div`
    background-color: white;
    margin: 20px 0px 20px 0px;
`

export const BtnAddMinus = styled.div`
    display: flex;
    
`

export const AddBtn = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 35px;
    margin: 0px;
    justify-content: space-between;

    #numBtn{
        margin: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .btnAdd{
        display: flex;
        aling-content: center;
        justify-content: center;
        height: 35px;
    }

    #btnAddMinus{
        display: flex;
        background-color: #f2f2f2;
        border-radius: 10px;
    }

    #addBtn{
        width: 35px;
        height: 35px;
        border-radius: 0px 10px 10px 0px;
        border: 0px;
        margin: 0px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;
    }

    #addBtn:hover{
        background-color: #c8c8c8;
        cursor: pointer
    }

    #cont{
        width: 35px;
        font-size: 25px;
        margin: 0px;
        align-items: center;
    }

    #restBtn{
        width: 35px;
        border-radius: 10px 0px 0px 10px;
        border: 0px;
        margin: 0px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;
    }

    #restBtn:hover{
        background-color: #c8c8c8;
        cursor: pointer
    }

    
`


import * as Yup from "yup";
import { IHubspotValue } from "../../types/requests/bot/step.interface";
import { EMessageType } from "../../types/requests/bot/stepType.enum";
import { isImageOrVideoURL } from "../pipes/url.pipe";
import { DefaultStepSchema } from "./required.schema";

export const MessageFormSchema = DefaultStepSchema.concat(Yup.object().shape({
    message: Yup.string().when('type', {
        is: (value:string) => value === 'multimedia', // Verificar si el valor es "bajo" o "medio"
        then: Yup.string(),
        otherwise: Yup.string().required("Debe definir un mensaje.").max(512, 'El mensaje no puede tener más de 512 caracteres'),
      }),
    answer: Yup.string(),
    next: Yup.string().nullable(true),
    step_store_answer: Yup.string()
    .when(['answer', 'type'], {
      is: (answer: string, type: EMessageType ) => answer /* && [EMessageType.NORMAL_MESSAGE, EMessageType.BUTTONS_MESSAGE, EMessageType.MENU_MESSAGE].includes(type) */,
      then: Yup.string().required('El nombre de la variable es obligatorio.'),
      otherwise: Yup.string()/* .notRequired().oneOf([null, ''], 'Este campo debe estar vacío cuando answer es falso.') */
    }),
    inactivity_messages: Yup.bool(),
    multimedia: Yup.string().when('type', {
        is: "multimedia",
        then: Yup.string()
            .url('Debe ser una URL válida.')
            .required('La URL es requerida.')
            .test('is-image-or-video', 'La URL debe ser de una imagen, video o pdf.', (value:string | undefined) => isImageOrVideoURL(value || "")),
        otherwise: Yup.string()
    })
        
    }));
export const MessageFormHsSchema = DefaultStepSchema.concat(Yup.object().shape({

    hubspotValues: Yup.array().of(
        Yup.object().shape({

            propertyName: Yup.string()
                .required('Debes seleccionar una propiedad'),

            nameAnswerProperty: Yup.string()
                .required('Debes escribir un valor')
        })
    )
}));

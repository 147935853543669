import React, { useState } from 'react';
import TalksProLogo from '../../../../assets/images/TalksPro_Logo.svg';
import { NavLink } from 'react-router-dom';
import HeadLink from '../../atoms/link/headLink.component';
import SettingsIcon from '../../atoms/icon/settingsIcon.component';
import { EColors } from '../../../../types/styles/colors.enum';
import PersonIcon from '../../atoms/icon/personIcon.component';
import Button from '../../atoms/button/button.component';
import { TopbarWrapper } from './styles';

interface MenuProps { 
    logout: () => void;
}

const Menu: React.FC<MenuProps> = ({logout}) => {
    const [active, setActive] = useState<boolean>(false);

    const links: { name: string, uri: string }[] = [
        {
            name: 'Bots',
            uri: '/'
        },
        /* {
            name: 'Credenciales',
            uri: '/credentials'
        }, */
        {
            name: 'Conectar',
            uri: '/connection'
        },
       /*  {
            name: 'Logs',
            uri: '/logs'
        }, */
        /* {
            name: 'Canales',
            uri: '/channels'
        } */
    ]

    return (
        <TopbarWrapper>

            <nav className='border-bottom navbar navbar-expand navbar-light  topbar static-top shadow'>
                <div className='container-md'>
                    <div className="navbar-group">
                        <Button
                            btnType='primary'
                            className='d-md-none'
                            onClick={() => setActive(!active)}
                        >
                            M
                        </Button>

                        <div>
                            <img width={100} src={TalksProLogo} alt="Logo" />
                            <span className="border-start ps-2 ms-2 fw-bold"> BOTS</span>
                        </div>

                        <ul className={'d-md-flex justify-content-center order-3 order-md-2 list-unstyled m-0 ' + (active ? "d-flex w-100" : "d-none")}>

                            {
                                links.map((link, index) => {
                                    return (
                                        <li className='py-2 px-3' key={index}>
                                            <NavLink to={link.uri}>
                                                {({ isActive }) => (
                                                    <HeadLink isActive={isActive}>
                                                        {link.name}
                                                    </HeadLink>
                                                )}
                                            </NavLink>
                                        </li>
                                    )
                                })
                            }

                        </ul>

                        <div className='d-flex order-2 order-md-3 gap-2'>
                            <span>
                            <div className="btn-group">
                            <button type="button" className="btn  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <SettingsIcon colorCode={EColors.white} />
                            </button>
                            <ul className="dropdown-menu">
                                {/*  <li><a className="dropdown-item" href="#">Settings</a></li>
                                    <li><hr className="dropdown-divider" /></li> */}
                                <li><a className="dropdown-item" href="#" onClick={logout}>Cerrar Sesión</a></li>
                            </ul>
                            </div>
                            

                            </span>
                        {/*  <span><PersonIcon colorCode={EColors.text} /></span> */}
                        </div>
                    </div>
                </div>
            </nav>
        </TopbarWrapper>

    )
}

export default Menu;
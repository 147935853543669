import React from 'react'
import { IconProps } from './sendIcon.component';
import { EColors } from '../../../../types/styles/colors.enum';

const SaveIcon: React.FC<IconProps> = ({ colorCode = EColors.text, ...props }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M10 41.9991C8.9 41.9991 7.958 41.6077 7.174 40.8251C6.39133 40.0411 6 39.0991 6 37.9991V9.99952C6 8.89954 6.39133 7.95755 7.174 7.17356C7.958 6.39091 8.9 5.99958 10 5.99958H34L42 13.9995V24.7993L24.8 41.9991H10ZM12 19.9994H30V11.9995H12V19.9994ZM24 35.9992C25.6667 35.9992 27.0833 35.4158 28.25 34.2492C29.4167 33.0825 30 31.6659 30 29.9992C30 28.3326 29.4167 26.916 28.25 25.7493C27.0833 24.5827 25.6667 23.9993 24 23.9993C22.3333 23.9993 20.9167 24.5827 19.75 25.7493C18.5833 26.916 18 28.3326 18 29.9992C18 31.6659 18.5833 33.0825 19.75 34.2492C20.9167 35.4158 22.3333 35.9992 24 35.9992ZM30 45.999V42.4491L40 32.4992L43.5 36.0492L33.55 45.999H30ZM44.8 34.6992L41.25 31.1992L42.95 29.4993C43.15 29.2993 43.3913 29.1993 43.674 29.1993C43.958 29.1993 44.2 29.2993 44.4 29.4993L46.5 31.5992C46.7 31.7992 46.8 32.0325 46.8 32.2992C46.8 32.5659 46.7 32.7992 46.5 32.9992L44.8 34.6992Z" fill={colorCode} />
        </svg>
    )
}

export default SaveIcon;
import React, { PropsWithChildren, createContext, useEffect, useState } from "react";
import { IDType } from "../types/requests/bot/bot.interface";

export type ModeType = 'set_action' | 'edit' | 'conditional';

export interface StepsFormContextType {
    visible: boolean,
    currentStepId: IDType | undefined,
    parentStepId: IDType | undefined,
    mode: ModeType,
    arrowsAreVisibles: boolean,
    isNew: boolean,
    setArrowsAreVisibles: (state: boolean) => void,
    setIsNew: (mode: boolean) => void,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setMode: (mode: ModeType) => void,
    setStepsIds: (stepId: IDType, parentStepId?: IDType) => void
}

export const StepsFormContext = createContext<StepsFormContextType | null>(null);

const StepsFormProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [editFormMode, setEditFormMode] = useState<ModeType>('set_action');
    const [isNewStep, setIsNewStep] = useState<boolean>(false);
    const [arrowsAreVisibles, setArrowsAreVisibles] = useState<boolean>(true);
    const [currentStepId, setCurrentStepId] = useState<IDType>();
    const [parentStepId, setParentStepId] = useState<IDType>();

    const setCurrentStepIds = (stepId: IDType, parentStepId?: IDType) => {
        setCurrentStepId(stepId);
        setParentStepId(parentStepId);
    }

    useEffect(() => {
        if (!showEditForm) {
            setCurrentStepId(undefined);
            setParentStepId(undefined);
            setArrowsAreVisibles(true);
        } else {
            setArrowsAreVisibles(false);
        }

    }, [showEditForm]);

    return (
        <StepsFormContext.Provider value={{
            visible: showEditForm,
            setVisible: setShowEditForm,
            arrowsAreVisibles,
            setArrowsAreVisibles,
            currentStepId,
            parentStepId,
            setStepsIds: setCurrentStepIds,
            setMode: setEditFormMode,
            mode: editFormMode,
            setIsNew: setIsNewStep,
            isNew: isNewStep,
        }}>
            {children}
        </StepsFormContext.Provider>
    )
}

export default StepsFormProvider;
import React, { useState,useEffect } from 'react';
/* import { VERSION_SESSION } from '../../config'; */
/* import { MDBBtn } from 'mdbreact'; */
import { LoginStyles } from './styles';
import { LoadingMain } from '../../components/LoadingMain';
import Button from '../../components/UI/atoms/button/button.component';
import { getAllUsers, handleLogin } from '../../services/user.service';
import { AlertFormLarge, ICON } from '../../utils/Alert';

interface LoginFormProps {
    setLogin: React.Dispatch<React.SetStateAction<boolean>>;
    id?: string;
    correo?: string;
  }

const LoginForm: React.FC<LoginFormProps> = ({ setLogin, id, correo }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [autoLogin, setAutoLogin] = useState(false);

  useEffect(() => {
    setEmail(correo ?? "")
    setPassword(id ?? "")
  }, []);

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');
    if(autoLogin){
      setEmail(correo ?? "")
      setPassword(id ?? "")
    }
    try{
        let user = await handleLogin({email, password, autologin: autoLogin});
        localStorage.setItem('token_user', user.token);
        localStorage.setItem('rol', user.rol ?? "");
        localStorage.setItem('provider', user.provider ?? "");
        localStorage.setItem('multiline', String(user.multiline ?? false));
        localStorage.setItem('VERSION_SESSION', process.env.REACT_APP_VERSION_SESSION as string);
        localStorage.setItem('company', user.company ?? "");
        setTimeout(() => {
            setLogin(true);
            setLoading(false);
        }, 2000);
    }catch(e){
      AlertFormLarge({title: "Usuario y/o contraseña invalidos", icon: ICON.WARNING});
      setLoading(false);
    }
  };

  return (
    <LoginStyles>
      <div className="container">
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div className="card card-signin my-5">
              <div className="card-body">
                <form className="form-signin" onSubmit={handleSubmit}>
                  {loading && <LoadingMain />}
                  <div>
                    <img
                      src="https://www.triario.co/hs-fs/hubfs/Talks_Pro_-_Logo.png?width=846&name=Talks_Pro_-_Logo.png"
                      alt="logo"
                      width="200px"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="inputEmail">Correo</label>
                    <input
                      type="email"
                      id="inputEmail"
                      className="form-control"
                      placeholder="example@gmail.com"
                      required
                      autoFocus
                      value={email ?? ''}
                      onChange={handleChangeEmail}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="inputPassword">Contraseña</label>
                    <input
                      type="password"
                      id="inputPassword"
                      className="form-control"
                      placeholder="********"
                      required
                      value={password??''}
                      onChange={handleChangePassword}
                    />
                  </div>

                  <div className={`alert ${message ? 'alert-danger' : ''}`}>{message}</div>
                   <Button type='submit' btnType="secondary">Iniciar Sesión</Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginStyles>
  );
}

export default LoginForm;

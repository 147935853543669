import React from 'react';

export interface LoaderWrapperProps {
    isLoading: boolean;
    loader: React.ReactNode;
    children: React.ReactNode;
}

const LoaderWrapper: React.FC<LoaderWrapperProps > = ({ isLoading, loader, children }) => {
    return (
        <>{isLoading ? loader : children}</>
    );
}

export default LoaderWrapper;
import React, { useEffect, useState } from "react";
import {
    IStep,
} from "../../../../types/requests/bot/step.interface";
import { Formik, useFormikContext } from "formik";
import { AddBtn, InputJump } from "./botForm.styles";
import InputBox from "../../molecules/inputs/inputBox.component";
import Button from "../../atoms/button/button.component";
import TrashIcon from "../../atoms/icon/trashIcon.component";
import { EColors } from "../../../../types/styles/colors.enum";

export interface HSFormFieldsProps {
}

const InactiveMsg: React.FC<HSFormFieldsProps> = () => {
    const formik = useFormikContext<{
        inactive_message: {
            message: string,
            time: number
        }[];
        msg: string;
        time: string;
    }>();

    return (
        <>  
            <div>
                <InputJump flexauto={true}>
                    <InputBox
                        label={"Mensaje de Inactividad"}
                        id={`msg`}
                        name={`msg`}
                        placeholder={"Texto"}
                        value={formik.getFieldProps(`msg`).value}
                    />
                    <InputBox
                        label={"Tiempo de Inactividad (Min)"}
                        id={`time`}
                        name={`time`}
                        placeholder={"Tiempo(minutos)"}
                        value={formik.getFieldProps(`time`).value}
                        type={"number"}
                    />
                    <div className="d-flex justify-content-center align-items-center">
                        <Button
                            btnType="primary"
                            type="button"
                            disabled={formik.values.msg && formik.values.time && Number(formik.values.time) >= 5? false: true}
                            onClick={() => {
                                let newValues = [
                                    ...formik.values.inactive_message,
                                    {
                                        message: formik.values.msg,
                                        time: formik.values.time,
                                    },
                                ];
                                formik.setFieldValue("inactive_message", newValues);
                                formik.setFieldValue("msg", "");
                                formik.setFieldValue("time", "");
                                
                            }}
                        >
                            Crear
                        </Button>
                    </div>
                </InputJump>
                
                <small>Si deja 2 o mas mensajes, ten en cuenta que el tiempo comienza a correr una vez se deja el mensaje anterior</small>
            
            </div>
            {formik.values.inactive_message?.map((value, index) => (
                <div key={index}>
                        <InputJump flexauto={true}>
                        <InputBox
                            label={""}
                            id={`inactive_message-${index}-message`}
                            name={`inactive_message.${index}.message`}
                            placeholder={
                                "Introduzca un mensaje de inactividad"
                            }
                            value={value.message}
                            as="textarea"
                            row={2}
                        />

                        <InputBox
                            label={""}
                            id={`inactive_message-${index}-time`}
                            name={`inactive_message.${index}.time`}
                            placeholder={
                                "Tiempo (min)"
                            }
                            value={value.time}
                            width="50%"
                            type={"number"}
                        />
                        <div className="d-flex justify-content-center align-items-start">
                            <Button
                                btnType="custom"
                                bgColor="red"
                                color="white"
                                type="button"
                                onClick={() => {
                                    let dataInactiveMsg = formik.getFieldProps("inactive_message").value;
                                    dataInactiveMsg.splice(index, 1);

                                    formik.setFieldValue("inactive_message", dataInactiveMsg);
                                }}
                            >
                                <TrashIcon
                                    width={16}
                                    height={16}
                                    colorCode={EColors.white}
                                />
                            </Button>

                        </div>
                            
                    </InputJump>
                    
                
                </div>
            ))}
                
        </>
    );

};

export default InactiveMsg;


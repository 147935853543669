import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { IOption, IStep } from "../../../../types/requests/bot/step.interface";

import SelectBox from "../../molecules/inputs/selectBox.component";
import { useState } from "react";
import { EMessageType } from "../../../../types/requests/bot/stepType.enum";
import { IEnvironmentBot } from "../../../../types/requests/bot/environment.interface";
import { IAnswer } from "../../../../types/requests/bot/answer.interface";
import StepsHandler from "../../../../utils/bots/steps.utils";
import useBot from "../../../../hooks/useBot.hook";
import { EActions } from "../../../../types/requests/bot/actions.enum";

export interface MessageActionFieldsProps {
    steps: IStep[];
    currentStep: IStep;
    env: IEnvironmentBot[];
    answers: IAnswer[]
}

export interface whiteListInterface {
    value: string;
    prefix?: string;
}

const GoAction: React.FC<MessageActionFieldsProps> = ({
    steps,
    currentStep,
}) => {
    const formik = useFormikContext<{
        answer: string;
        options: object[];
        redirect: {
            active: false;
            message: "";
            showMessage: false;
        };
    }>();
    const { stepsHandler } = useBot();
    const { values } = formik;
    const [selectedOption, setSelectedOption] = useState("");
    const [filterSteps, setFilterSteps] = useState<IStep[]>([]);


    const setData = (currentStep: IStep) => {
        formik.setFieldValue("go_to", currentStep.go_to || "");
        setSelectedOption(currentStep!.go_to || "");
        const previous = stepsHandler.getPreviousBranch([],currentStep.previous || "");
        const findStartBot = previous.find(el => el.action.type === EActions.START);
        const findConditionComponent = previous.find(el => el.action.type === EActions.CONDITION && el.id === currentStep.parentStep?.parent);
        const previousBranch = previous.filter(el => el.parentStep?.parent === currentStep.parentStep?.parent);
        const previousId = [...previousBranch, findConditionComponent, findStartBot].map(el => el?.id);
        const filterSteps = steps.filter(step => !previousId.includes(step.id));
        const deleteCurrentStep = filterSteps.filter(step => step.id !== currentStep.id);
        const deleteActionComponent = deleteCurrentStep.filter(step => step.type !== EMessageType.GO_ACTION);
        setFilterSteps(deleteActionComponent);
    };
    useEffect(() => {
     setData(currentStep)
    }, [])

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedOption(event.target.value as string);
        formik.setFieldValue("type", EMessageType.GO_ACTION);
        formik.setFieldValue("go_to", event.target.value);
    };


    return (
        <>
            <SelectBox
                id="actionText"
                name="actionText"
                label="Seleccione la acción a la que desea ir"
                value={selectedOption}
                onChange={handleChange}
            >
                <option value="" disabled>
                    --- Selecciona una accion ---
                </option>
                {filterSteps
                    .map((step) => (
                        <option key={step.id} value={step.id}>
                            Siguiente paso: {step.name}
                        </option>
                    ))}
            </SelectBox>
        </>
    );
};

export default GoAction;


import React, { useEffect, useState } from "react";
import {
    IHubspotValue,
    IStep,
} from "../../../../types/requests/bot/step.interface";
import { FlexGap, StepFormContainer } from "../../molecules/steps/step.styles";
import { Formik, useFormikContext } from "formik";
import { EActions } from "../../../../types/requests/bot/actions.enum";
import SelectBox from "../../molecules/inputs/selectBox.component";
import { withCurrentStepProps } from "../../../../HOC/withCurrentStep.hoc";
import StepsHandler from "../../../../utils/bots/steps.utils";
import AnswersHandler from "../../../../utils/bots/answers.utils";
import { AddBtn, InputJump } from "./botForm.styles";
import { EMessageType } from "../../../../types/requests/bot/stepType.enum";
import InputBox from "../../molecules/inputs/inputBox.component";
import Button from "../../atoms/button/button.component";
import SelectSearch from "../../molecules/inputs/selectSearch.component";
import { getPropertiesContact } from "../../../../services/hubspot.service";
import { IPropertyContact } from "../../../../types/requests/hubspot/contact.interface";
import MixedTagInput from "../../../templates/TagInput/tagInput";

export interface HSFormFieldsProps {
    steps: IStep[];
    currentStep: IStep;
    stepsHandler: StepsHandler;
    answersHandler: AnswersHandler;
}

const HSFields: React.FC<HSFormFieldsProps> = ({
    steps,
    currentStep,
    stepsHandler,
    answersHandler,
}) => {
    const formik = useFormikContext<{
        answer: string;
        options: object[];
        hubspotValues: IHubspotValue[];
        deleteValues: string[],
    }>();
    const [optionsProperties, setOptionsProperties] = useState<IPropertyContact[]>([]);

    const setData = async(currentStep: IStep) => {
        let PropertiesContact = await getPropertiesContact();
        setOptionsProperties(PropertiesContact);
       
        formik.setFieldValue("type", EMessageType.HUBSPOT);

        formik.setFieldValue("answer", /* currentStep?.answer ||  */"");
        formik.setFieldValue("options", currentStep?.options);
        formik.setFieldValue("deleteValues", []);
        formik.setFieldValue(
            "hubspotValues",
            currentStep?.hubspotValues && currentStep?.hubspotValues.length > 0
                ? currentStep?.hubspotValues
                : [
                      {
                          propertyName: "",
                          nameAnswerProperty: "",
                          answer: ""
                      },
                  ]
        );
    };
    useEffect(() => {
        setData(currentStep)
        
    }, [currentStep]);

    const answers =
        currentStep?.previous !== null
            ? answersHandler.getAvailableAnswers(
                  currentStep?.previous,
                  stepsHandler
              )
            : [];

    if (currentStep?.action?.type === EActions.GET) {
        return (
            <>
                {formik.values.hubspotValues.map((value, index) => (
                    <div key={index}>
                        <SelectSearch
                            id={`hubspotValues-${index}-propertyName`}
                            name={`hubspotValues.${index}.propertyName`}
                            label={
                                "Seleccionar el nombre de la propiedad de Hubspot"
                            }
                            value={value.propertyName} 
                            options={optionsProperties}
                            disabled={false}
                            className={"mb-2"}
                        />
                        <InputJump>
                            <InputBox
                                label={"Nombre de la Variable"}
                                id={`hubspotValues-${index}-nameAnswerProperty`}
                                name={`hubspotValues.${index}.nameAnswerProperty`}
                                placeholder={
                                    "Introduzca el nombre de la propiedad de Hubspot"
                                }
                                value={value.nameAnswerProperty}
                                fieldLowerCase={true}
                            />
                        </InputJump>
                        {index !== 0 && (
                            <FlexGap gap="24px">
                                <Button
                                    btnType="tertiary"
                                    type="button"
                                    className="mt-3"
                                    onClick={() => {
                                        let newValues = [
                                            ...formik.values.hubspotValues,
                                        ];
                                        let deleteValue = newValues.splice(index, 1);
                                        formik.setFieldValue(
                                            "hubspotValues",
                                            newValues
                                        );
                                        formik.setFieldValue(
                                            "deleteValues",
                                            [...formik.values.deleteValues, deleteValue[0].answer]
                                        )
                                    }}
                                >
                                    Eliminar
                                </Button>
                            </FlexGap>
                        )}
                  
                    </div>
                ))}
                 <Button
                    btnType="primary"
                    type="button"
                    onClick={() => {
                        let newValues = [
                            ...formik.values.hubspotValues,
                            {
                                propertyName: "",
                                nameAnswerProperty: "",
                                answer: ""
                            },
                        ];
                        formik.setFieldValue("hubspotValues", newValues);
                    }}
                    className="w-100"
                >
                    Añadir nuevo valor
                </Button>
            </>
        );
    } else if (currentStep?.action?.type === EActions.SAVE_OR_UPDATE) {
        return (
            <>
                {formik.values.hubspotValues.map((value, index) => (
                    <div key={index}>
                        <SelectSearch
                            id={`hubspotValues-${index}-propertyName`}
                            name={`hubspotValues.${index}.propertyName`}
                            label={
                                "Seleccionar el nombre de la propiedad de Hubspot"
                            }
                            value={formik.getFieldProps(`hubspotValues.${index}.propertyName`).value || ""} 
                            options={optionsProperties}
                            disabled={false}
                            className={"mb-4"}
                        />

                        <MixedTagInput
                            label={"Seleccionar respuesta para la propiedad de Hubspot"} 
                            value={formik.getFieldProps(`hubspotValues.${index}.nameAnswerProperty`).value || ""} 
                            id={`hubspotValues-${index}-nameAnswerProperty`}
                            name={`hubspotValues.${index}.nameAnswerProperty`}
                            placeholder={"Escribe la respuesta"}
                            viewCount={true}
                            maxCount={512}
                        />
                        {index != 0 && (
                            <FlexGap gap="24px">
                                <Button
                                    btnType="tertiary"
                                    type="button"
                                    className="mt-3"
                                    onClick={() => {
                                        let newValues = [
                                            ...formik.values.hubspotValues,
                                        ];
                                        newValues.splice(index, 1);
                                        formik.setFieldValue(
                                            "hubspotValues",
                                            newValues
                                        );
                                    }}
                                >
                                    Eliminar
                                </Button>
                            </FlexGap>
                        )}
                    </div>
                ))}

                <Button
                    btnType="primary"
                    type="button"
                    onClick={() => {
                        let newValues = [
                            ...formik.values.hubspotValues,
                            {
                                propertyName: "",
                                nameAnswerProperty: "",
                            },
                        ];
                        formik.setFieldValue("hubspotValues", newValues);
                    }}
                    className="w-100"
                >
                    Añadir nuevo valor
                </Button>
            </>
        );
    }

    return <></>;
};

export default HSFields;


import React, { useContext } from 'react';
import { StepsFormContext, StepsFormContextType } from '../../../../context/stepsForm.context';
import { FormHeader } from '../../molecules/steps/step.styles';
import HideableInput from '../../molecules/inputs/hiddeableInput.component';
import CloseIcon from '../../atoms/icon/closeIcon.component';
import { EColors } from '../../../../types/styles/colors.enum';
import { useFormikContext } from 'formik';

export interface StepFormHeaderProps {
    hiddenClose?: boolean,
 }

const StepFormHeader: React.FC<StepFormHeaderProps> = ({hiddenClose = false}) => {
    const { setVisible, isNew } = useContext(StepsFormContext) as StepsFormContextType;
    const { isInitialValid, isValid } = useFormikContext();
    return (
        <FormHeader>
            <div className="d-flex">
                <HideableInput
                    type="text"
                    name="name"
                />
            </div>
            { !isNew && <div className='close' onClick={() => setVisible(false)}>
                <CloseIcon colorCode={EColors.white} />
            </div>}
        </FormHeader>
    )
}

export default StepFormHeader;
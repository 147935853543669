import React, { useContext } from "react";
import { ModeType, StepsFormContext, StepsFormContextType } from "../context/stepsForm.context";
import { IStep } from "../types/requests/bot/step.interface";
import useBot, { useBotResult } from "../hooks/useBot.hook";

export interface withCurrentStepProps extends useBotResult {
    currentStep: IStep;
}

function withCurrentStep<T>(Component: React.ElementType, Loader: React.ElementType, waitedMode: ModeType) {
    const WrapperComponent = (props: T) => {
        const botHook = useBot();
        const { currentStepId, mode, visible } = useContext(StepsFormContext) as StepsFormContextType;

        const currentStep = (currentStepId !== undefined) ? botHook.stepsHandler.getStep(currentStepId) : undefined;

        if (!visible || mode !== waitedMode) {
            return null;
        } else if (visible && mode === waitedMode && !currentStep) {
            return <Loader />
        } else {
            return <Component
                currentStep={currentStep}
                {...botHook}
                {...props}
                isNew={true}
            />
        }
    }

    return WrapperComponent;
}

export default withCurrentStep;
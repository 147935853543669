import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { v4 as uuidv4 } from "uuid";
import { IOption, IStep } from "../../../../types/requests/bot/step.interface";
import InputBox from "../../molecules/inputs/inputBox.component";
import RadiosGroup from "../../molecules/inputs/radiosGroup.component";
import SelectBox from "../../molecules/inputs/selectBox.component";
import { useState } from "react";
import { InputJump, AddBtn, LineJump } from "./botForm.styles";
import { EMessageType } from "../../../../types/requests/bot/stepType.enum";
import InputButton from "../../molecules/inputs/InputButton.component";
import MixedTagInput from "../../../templates/TagInput/tagInput";
import { IEnvironmentBot } from "../../../../types/requests/bot/environment.interface";
import { IAnswer } from "../../../../types/requests/bot/answer.interface";
import { isImageOrVideo, isImageOrVideoURL } from "../../../../utils/pipes/url.pipe";

export interface MessageActionFieldsProps {
    steps: IStep[];
    currentStep: IStep;
    env: IEnvironmentBot[];
    answers: IAnswer[];
    uuid: string;
}

export interface whiteListInterface {
    value: string;
    prefix?: string;
}

const MessageActionFields: React.FC<MessageActionFieldsProps> = ({
    steps,
    currentStep,
    uuid
}) => {
    const formik = useFormikContext<{
        answer: string;
        options: object[];
        inactivity_messages: boolean;
        multimedia: string;
        type: string;
    }>();

    const [startNum, setStartNum] = useState(1);
    const { values } = formik;
    const [selectedOption, setSelectedOption] = useState("regular");
    const [dataOptions, setDataOptions] = useState<IOption[]>([]);
    const [menuItemsIndexes, setMenuItemsIndexes] = useState([1]);
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [multimediaData, setMultimediaData] = useState<{type: "image"| "video" | "pdf" | "other", url: string}>({type: "other", url: ""});

    const setData = (currentStep: IStep) => {
        switch (currentStep.type) {
            case EMessageType.NORMAL_MESSAGE:
                setSelectedOption("regular");
                break;
            case EMessageType.BUTTONS_MESSAGE:
                setSelectedOption("buttons");
                break;
            case EMessageType.MENU_MESSAGE:
                setSelectedOption("menu");
                break;
            case EMessageType.MULTIMEDIA:
                setSelectedOption("multimedia");
                break;
        }
        if (currentStep.type === EMessageType.BUTTONS_MESSAGE) {
            if(currentStep!.options!.length === 0) {
                setDataOptions([{id: `${currentStep.id}_0`, title: ""}, {id: `${currentStep.id}_1`, title: "No cumple"}])
            } else {
                setDataOptions(currentStep?.options || [])
                setSelectedQuantity(currentStep!.options!.length - 1);
            }
        } else if (currentStep.type === EMessageType.MENU_MESSAGE) {
            if(currentStep!.options!.length === 0) {
                setDataOptions([{id: `${currentStep.id}_0`, title: "", description: ""}, {id: `${currentStep.id}_1`, title: "No cumple"}]);
                setStartNum(1);
            } else {
                setDataOptions(currentStep?.options || [])
                setStartNum(currentStep!.options!.length - 1);
            }
        }
    };
    useEffect(() => {
      setData(currentStep)
    }, [])

    useEffect(() => {
        if (EMessageType.BUTTONS_MESSAGE.startsWith(selectedOption)) {
            if(currentStep!.options!.length === 0) {
                setDataOptions([{id: `${currentStep.id}_0`, title: ""}, {id: `${currentStep.id}_1`, title: "No cumple"}])
            } else {
                setDataOptions(currentStep?.options || [])
                setSelectedQuantity(currentStep!.options!.length - 1);
            }
        } else if (EMessageType.MENU_MESSAGE.startsWith(selectedOption)) {
            if(currentStep!.options!.length === 0) {
                setDataOptions([{id: `${currentStep.id}_0`, title: "", description: ""}, {id: `${currentStep.id}_1`, title: "No cumple"}])
                setStartNum(1);
            } else {
                setDataOptions(currentStep?.options || [])
                setStartNum(currentStep!.options!.length - 1);
            }
        }
    }, [selectedOption])
    
    useEffect(() => {
        if(values.type === "multimedia") {
            let isValidMultimedia = isImageOrVideoURL(values.multimedia);
            if(isValidMultimedia) {
                let dataMultimedia = {
                    type: isImageOrVideo(values.multimedia).type,
                    url: values.multimedia,
                }
                setMultimediaData(dataMultimedia);
            }
        }
    }, [values])
    
    
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedOption(event.target.value as string);

        switch (event.target.value) {
            case "regular":
                formik.setFieldValue("type", EMessageType.NORMAL_MESSAGE);
                formik.setFieldValue("options", []);
                formik.setFieldValue("answer", formik.getFieldProps("step_store_answer").value ? uuid : "");
                break;
            case "multimedia":
                formik.setFieldValue("type", EMessageType.MULTIMEDIA);
                formik.setFieldValue("options", []);
                formik.setFieldValue("answer", "");
                break;
            case "buttons":
                formik.setFieldValue("type", EMessageType.BUTTONS_MESSAGE);
                formik.setFieldValue("answer", "Pending");
                break;
            case "menu":
                formik.setFieldValue("type", EMessageType.MENU_MESSAGE);
                formik.setFieldValue("answer", "Pending");
                break;
        }
    };

    const handleChangeQuantityButtons = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const numOfFields = (Number(event.target.value) as number);
        setSelectedQuantity(numOfFields);
        const button_options = formik.getFieldProps("options");

        let arrayButtons: any[] = [];
        for (let index = 0; index < numOfFields; index++) {
            let findOption = button_options.value.find((el: IOption) => (el.id === `${currentStep.id}_${index}` && el.title !== EMessageType.NO_CUMPLE));
            arrayButtons.push({id: `${currentStep.id}_${index}`, title: findOption?.title || `Opción ${index + 1}`, description: findOption?.description || ``,  next: findOption?.next || undefined});
        }
        //No cumple Option
        let findOption = button_options.value.find((el: IOption) => el.title === EMessageType.NO_CUMPLE);
        arrayButtons.push({id: `${currentStep.id}_${arrayButtons.length}`, title: "No cumple", next: findOption?.next || undefined})
       
        setDataOptions(arrayButtons);
        formik.setFieldValue(
            "options",
            arrayButtons
        );
    };
    
    const addButton = () => {
        if (startNum <= 9) {
            const menu_options = formik.getFieldProps("options");
            console.log({menu_options})
            let arrayMenu: any[] = [];
            for (let index = 0; index < startNum + 1; index++) {
                let findOption = menu_options.value.find((el: IOption) => (el.id === `${currentStep.id}_${index}` && el.title !== EMessageType.NO_CUMPLE));
                console.log(findOption)
                arrayMenu.push({id: `${currentStep.id}_${index}`, title: findOption?.title || `Menu ${index + 1}`, description: findOption?.description || ``, next: findOption?.next || undefined});
            }
            //No cumple Option
            let findOption = menu_options.value.find((el: IOption) => el.title === EMessageType.NO_CUMPLE);
            arrayMenu.push({id: `${currentStep.id}_${arrayMenu.length}`, title: "No cumple", next: findOption?.next || undefined})

            setDataOptions(arrayMenu);
            console.log(arrayMenu)
            formik.setFieldValue(
                "options",
                arrayMenu
            );
            setStartNum(startNum + 1);
        }
    };

    const restButton = () => {
        if (startNum > 1) {
            let newArrayDelete = [...dataOptions];
            newArrayDelete.splice(dataOptions.length - 2,1);

            //No cumple Option
            let findOption = newArrayDelete.find((el: IOption) => el.title === EMessageType.NO_CUMPLE);
            if(findOption) findOption.id = `${currentStep.id}_${newArrayDelete.length - 1}`;
            setDataOptions(newArrayDelete);
            formik.setFieldValue(
                "options",
                newArrayDelete
            );
            setStartNum(startNum - 1);
        }
    };
    
    return (
        <>
            <SelectBox
                id="typeText"
                name="typeText"
                label="Selecciona el tipo de texto que deseas usar"
                value={selectedOption}
                onChange={handleChange}
            >
                <option value="regular" id="regular">
                    Simple
                </option>
                <option value="multimedia" id="multimedia">
                    Multimedia
                </option>
                <option value="buttons" id="buttons">
                    Con botones
                </option>
                <option value="menu" id="menu">
                    Menu
                </option>
            </SelectBox>

            <InputJump padding="0">
                <MixedTagInput 
                    label={"Ingrese el mensaje que desea enviar"} 
                    value={formik.getFieldProps("message").value} 
                    name="message" 
                    id="message-bot" 
                    placeholder={"Ej: Bienvenido a nuestro canal digital. Usa @ o # para incluir tokens."}
                    viewCount={true}
                    maxCount={512}
                />
            </InputJump>

           {selectedOption === "regular" && (
                <div>
                    <InputJump>
                        <RadiosGroup
                            label="Esperar respuesta del usuario para continuar el flujo"
                            name="answer"
                            value={
                                formik.getFieldProps("answer").value
                            }
                            options={[
                                {
                                    value: uuid,
                                    id: "step-wait-response",
                                    label: "Sí, esperar para continuar",
                                },
                                {
                                    value: "",
                                    id: "step-no-wait-response",
                                    label: "No, continuar el flujo",
                                },
                            ]}
                        />
                    </InputJump>
                    <InputJump>
                        <InputBox
                            label={
                                "¿Cómo será almacenada la respuesta del usuario? (Opcional)"
                            }
                            type="text"
                            className="form-control"
                            id="step-store-answer"
                            name="step_store_answer"
                            placeholder="Ejm: Correo electrónico"
                            disabled={!values.answer}
                            fieldLowerCase={true}
                            value={
                                formik.getFieldProps("answer").value ? formik.getFieldProps("step_store_answer").value: ""
                            }
                        />
                    </InputJump>


                    {
                    values.answer && 
                        <InputBox
                            as="checkbox"
                            id="inactivity_messages"
                            name="inactivity_messages"
                            label="Habilitar Mensajes de inactividad"
                            checked={values.inactivity_messages}
                        />
                    }
                </div>
            )}
            {selectedOption === "multimedia" && (
                <div>
                    <MixedTagInput 
                        label={"URL Multimedia (Imagen, Video o PDF)"} 
                        value={formik.getFieldProps("multimedia").value} 
                        name="multimedia" 
                        id="multimedia-bot"     
                        placeholder={"Ej: https://www.imagen.com"}
                    />

                    <label className="mt-4">Previsualización</label>

                    {multimediaData.type === "image" && 
                        <div>
                            <img src={multimediaData.url} alt="media" width="300"/>
                        </div>
                    }
                    {multimediaData.type === "pdf" && 
                        <div>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png" alt="media" width="150"/>
                        </div>
                    }
                    {multimediaData.type === "video" && 
                        <div>
                            <video src={multimediaData.url} width="300" autoPlay muted controls></video>
                        </div>
                    }

                    <InputJump>
                        <RadiosGroup
                            label="Esperar respuesta del usuario para continuar el flujo"
                            name="answer"
                            value={
                                formik.getFieldProps("answer").value
                            }
                            options={[
                                {
                                    value: uuid,
                                    id: "step-wait-response",
                                    label: "Sí, esperar para continuar",
                                },
                                {
                                    value: "",
                                    id: "step-no-wait-response",
                                    label: "No, continuar el flujo",
                                },
                            ]}
                        />
                    </InputJump>
                    <InputJump>
                        <InputBox
                            label={
                                "¿Cómo será almacenada la respuesta del usuario? (Opcional)"
                            }
                            type="text"
                            className="form-control"
                            id="step-store-answer"
                            name="step_store_answer"
                            placeholder="Ejm: Correo electrónico"
                            disabled={!values.answer}
                            fieldLowerCase={true}
                            value={
                                formik.getFieldProps("answer").value ? formik.getFieldProps("step_store_answer").value: ""
                            }
                        />
                    </InputJump>
                </div>
            )}
            {selectedOption === "buttons" && (
                <div>
                    <div>
                        <InputJump>
                            <SelectBox
                                id="numBtns"
                                name="numBtns"
                                label="Selecciona la cantidad de botones"
                                value={selectedQuantity}
                                onChange={handleChangeQuantityButtons}
                            >
                                <option value="1" id="btn1">
                                    1
                                </option>
                                <option value="2" id="btn2">
                                    2
                                </option>
                                <option value="3" id="btn3">
                                    3
                                </option>
                            </SelectBox>
                        </InputJump>
                        {dataOptions.map((data, i) => {
                            let isLastOption = dataOptions.length - 1 === i;
                            return <InputJump key={`input-${i}`}>
                                <InputButton
                                    type="text"
                                    id={`title_${currentStep.id}_${i}`}
                                    name={`title_${i}`}
                                    position={i}
                                    length={dataOptions.length}
                                    label={`Ingresa el texto para el botón ${i + 1}`}
                                    data={ data }
                                    value=""
                                    viewCount={true}
                                    maxCount={20}
                                    disabled={isLastOption}
                                />
                            </InputJump>
                        })}
                         <InputJump>
                            <InputBox
                                label={
                                    "¿Cómo será almacenada la respuesta del usuario?"
                                }
                                type="text"
                                className="form-control"
                                id="button-step-store-answer"
                                name="step_store_answer"
                                placeholder="Ejm: Correo electrónico"
                                fieldLowerCase={true}
                                value={
                                    formik.getFieldProps("answer").value  ? formik.getFieldProps("step_store_answer").value: ""
                                }
                            />
                        </InputJump>
                        <InputBox
                            as="checkbox"
                            id="inactivity_messages"
                            name="inactivity_messages"
                            label="Habilitar Mensajes de inactividad"
                            checked={values.inactivity_messages}
                        />
                    </div>
                </div>
            )}

            {selectedOption === "menu" && (
                <div>
                    <AddBtn>
                        <p id="numBtn">Numero de opciones: </p>
                        <div id="btnAddMinus">
                            <div id="rest" className="btnAdd">
                                <button
                                    id="restBtn"
                                    type="button"
                                    onClick={restButton}
                                >
                                    -
                                </button>
                            </div>
                            <div>
                                <h2 id="cont" className="btnAdd">
                                    {" "}
                                    {startNum}{" "}
                                </h2>
                            </div>
                            <div id="add" className="btnAdd" >
                                <button
                                    id="addBtn"
                                    type="button"
                                   
                                    onClick={addButton}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    </AddBtn>
                    <LineJump>
                        {dataOptions.map((data, i) => {
                            let isLastOption = dataOptions.length - 1 === i;
                            console.log({data})
                            return <InputJump key={`input-${i}`}>
                                <InputButton
                                    type="text"
                                    id={`title_${currentStep.id}_${i}`}
                                    name={`${currentStep.id}_${i}`}
                                    position={i}
                                    length={menuItemsIndexes.length}
                                    label={`Texto para el ítem ${i + 1}`}
                                    data={ data }
                                    value=""
                                    viewCount={true}
                                    maxCount={24}
                                    disabled={isLastOption}
                                />
                                 {!isLastOption && <InputButton
                                    type="text"
                                    id={`description_${currentStep.id}_${i}`}
                                    name={`${currentStep.id}_${i}`}
                                    position={i}
                                    length={menuItemsIndexes.length}
                                    label={`Descripción para el ítem ${i + 1}`}
                                    data={ data }
                                    value=""
                                    viewCount={true}
                                    maxCount={72}
                                    disabled={isLastOption}
                                />}
                            </InputJump>
                        })}
                    </LineJump>
                    <InputJump>
                        <InputBox
                                label={
                                    "¿Cómo será almacenada la respuesta del usuario?"
                                }
                                type="text"
                                className="form-control"
                                id="menu-step-store-answer"
                                name="step_store_answer"
                                placeholder="Ejm: Correo electrónico"
                                fieldLowerCase={true}
                                value={
                                    formik.getFieldProps("answer").value ? formik.getFieldProps("step_store_answer").value: ""
                                }
                            />
                    </InputJump>
                    <InputBox
                            as="checkbox"
                            id="inactivity_messages"
                            name="inactivity_messages"
                            label="Habilitar Mensajes de inactividad"
                            checked={values.inactivity_messages}
                        />
                </div>
            )}
        </>
    );
};

export default MessageActionFields;


import React from 'react';

const ArrowLeftIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.6866 2.97982C10.8641 3.15733 10.8802 3.43511 10.735 3.63085L10.6866 3.68693L6.37367 8.00004L10.6866 12.3132C10.8641 12.4907 10.8802 12.7684 10.735 12.9642L10.6866 13.0203C10.5091 13.1978 10.2313 13.2139 10.0355 13.0687L9.97945 13.0203L5.31279 8.35359C5.13528 8.17608 5.11914 7.89831 5.26438 7.70257L5.31279 7.64649L9.97945 2.97982C10.1747 2.78456 10.4913 2.78456 10.6866 2.97982Z" fill="#3D3D3D" stroke="#3D3D3D" strokeWidth="2" />
    </svg>
  )
}

export default ArrowLeftIcon;
import { EActions } from "../../types/requests/bot/actions.enum";
import { IBot } from "../../types/requests/bot/bot.interface"
import { IDraftBotResponse } from "../../types/requests/bot/draftbot.interface";
import { IStep } from "../../types/requests/bot/step.interface";
import { EMessageType } from "../../types/requests/bot/stepType.enum";

export const validateStep = (step: IStep): { valid: boolean, errors: string[] } => {
    let errors: string[] = [];

    // Validación para send_message
    if (step.action.type === EActions.SEND_MESSAGE && step.type !== EMessageType.MULTIMEDIA) {
        if (typeof step.message !== 'string' || !step.message) {
            errors.push(step.name +': Message debe ser una cadena no vacía');
        }
    }
    
    // Validación para hs_get
    if (step.action.type === EActions.GET) {
        if (!Array.isArray(step.hubspotValues) || step.hubspotValues.length < 1) {
            errors.push(step.name +': hubspotValues debe ser un arreglo con al menos un dato');
        }
        if (!Array.isArray(step.options) || step.options.length < 2) {
            errors.push(step.name +': options debe ser un arreglo con al menos dos elementos');
        }
    }
    
    // Validación para hs_save_or_update
    if (step.action.type === EActions.SAVE_OR_UPDATE) {
        if (!Array.isArray(step.options) || step.options.length < 2) {
            errors.push(step.name +': options debe ser un arreglo con al menos dos elementos');
        }
    }
    
    // Validación para condition
    if (step.action.type === EActions.CONDITION) {
        if (typeof step.condition !== 'object' || step.condition === null) {
            errors.push(step.name +': condition debe ser un objeto');
        } else if (!Array.isArray(step.condition.cases) || step.condition.cases.length < 2) {
            errors.push(step.name +': condition.cases debe ser un arreglo con al menos dos elementos');
        }
    }
    
    // Validación para group_and_subgroup
    if (step.action.type === EActions.GROUP_AND_SUBGROUP) {
        if (typeof step.group !== 'string' || step.group === '') {
        errors.push(step.name +': group debe ser una cadena no vacía');
        }
        if (typeof step.subgroup !== 'string' || step.subgroup === '') {
        errors.push(step.name +': subgroup debe ser una cadena no vacía');
        }
    }
    
    // Validación para go_action
    if (step.action.type === EActions.GO_ACTION) {
        if (typeof step.go_to !== 'string' || step.go_to === '') {
        errors.push(step.name +': go_to debe ser una cadena no vacía');
        }
    }
    
    // Validación para redirect_advisor
    if (step.action.type === EActions.REDIRECT_ADVISOR) {
        if (typeof step.group !== 'string' || step.group === '') {
        errors.push(step.name +': group debe ser una cadena no vacía');
        }
        if (typeof step.subgroup !== 'string' || step.subgroup === '') {
        errors.push(step.name +': redirect_advisor.subgroup debe ser una cadena no vacía');
        }
    }
    
    return {
        valid: errors.length === 0,
        errors
    };
    }
export const validateBot = (bot: IBot | IDraftBotResponse, details: boolean = false) => { 
    const steps = bot.steps;
    let validateBot = [];
    for (const step of steps) {
        validateBot.push(validateStep(step));
    }
    if(details) return validateBot;
    return validateBot.every(el => el.valid);
}
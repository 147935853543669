import React from 'react'
import { IconProps } from './sendIcon.component';
import { EColors } from '../../../../types/styles/colors.enum';

const PlusIcon: React.FC<IconProps> = ({ colorCode = EColors.primary, ...props }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13.5 8.998H8.5V13.998C8.5 14.2632 8.39464 14.5176 8.20711 14.7051C8.01957 14.8926 7.76522 14.998 7.5 14.998C7.23478 14.998 6.98043 14.8926 6.79289 14.7051C6.60536 14.5176 6.5 14.2632 6.5 13.998V8.998H1.5C1.23478 8.998 0.98043 8.89264 0.792893 8.70511C0.605357 8.51757 0.5 8.26322 0.5 7.998C0.5 7.73278 0.605357 7.47843 0.792893 7.29089C0.98043 7.10336 1.23478 6.998 1.5 6.998H6.5V1.998C6.5 1.73278 6.60536 1.47843 6.79289 1.29089C6.98043 1.10336 7.23478 0.998001 7.5 0.998001C7.76522 0.998001 8.01957 1.10336 8.20711 1.29089C8.39464 1.47843 8.5 1.73278 8.5 1.998V6.998H13.5C13.7652 6.998 14.0196 7.10336 14.2071 7.29089C14.3946 7.47843 14.5 7.73278 14.5 7.998C14.5 8.26322 14.3946 8.51757 14.2071 8.70511C14.0196 8.89264 13.7652 8.998 13.5 8.998Z" fill={colorCode} />
        </svg>
    )
}

export default PlusIcon;
import React, { useState } from 'react';
import { FieldHookConfig, useField } from 'formik';
import PencilIcon from '../../atoms/icon/pencilIcon.component';
import InputError from '../../atoms/input/inputError.component';

export type HideableInputProps = FieldHookConfig<string> & {}

const HideableInput: React.FC<HideableInputProps> = ({ className, ...props }) => {
    const [field, meta] = useField(props);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    return (
        <div>
            <input
                className={"form-control "
                    + (isVisible ? " d-block " : " d-none ")
                    + ((meta.error && meta.touched) ?
                        "border border-danger text-danger " : "") + className
                }
                {...field}
                onBlur={() => setIsVisible(false)}
            />

            <div
                className={isVisible ? "d-none" : "cursor-pointer"}
                onClick={() => setIsVisible(true)}
            >
                <span className='me-2'>
                    {
                        (meta.error && meta.touched) ?
                            <InputError>{meta.error}</InputError> : field.value
                    }
                </span>

                <PencilIcon className='mb-1' colorCode='white' width={16} height={16} />
            </div>
        </div>
    )
}

export default HideableInput
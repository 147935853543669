import React from 'react';
import PageHeader from '../../UI/molecules/pageHeader/pageHeader.component';
import Table from '../../UI/molecules/table/table.component';
import CardWrapper from '../../UI/atoms/wrapper/card.component';
import Tag, { TagTypes } from '../../UI/atoms/tag/tag.component';
import IAccount, { EChannels, IWhatsAppAccount } from '../../../types/requests/accounts/account.interface';
import HideableInput from '../../UI/molecules/inputs/hiddeableInput.component';

const Accounts: IAccount<IWhatsAppAccount>[] = [
    {
        _id: "hi",
        bot: "312312312",
        channel: EChannels.WPP,
        connection: {
            token: "Das"
        },
        sender: "311-433-2223"
    }
]

export interface ChannelsTemplateProps { }

const ChannelsTemplate: React.FC<ChannelsTemplateProps> = ({ }) => {
    return (
        <>
            <PageHeader
                title='Administrador de canales'
                subtitle='Agrega nuevas cuentas de los canales de tus clientes y conecta tus plataformas de mensajería.'
                button='Agregar una nueva cuenta'
                action={() => { console.log("Clicked") }}
            />

            <CardWrapper>
                <Tag type={TagTypes.primary}>Cuentas de WhatsApp vinculadas</Tag>
                <Table headers={["NÚMERO / ID DE CUENTA", "SECRET TOKEN FROM META", "SECRET TOKEN FROM META", "EXTRA"]}>
                    <tr>
                        <td>0</td>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                    </tr>
                </Table>
            </CardWrapper>

        </>
    );
}

export default ChannelsTemplate;
import { IAnswer } from "../../types/requests/bot/answer.interface";
import { IDType } from "../../types/requests/bot/bot.interface";
import StepsHandler from "./steps.utils";

export default class AnswersHandler {
    private answers: IAnswer[];

    constructor(answers: IAnswer[]) {
        this.answers = answers;
    }

    public get Answers(): IAnswer[] {
        return this.answers;
    }

    public addAnswers = (uuids: string[], answers: IAnswer[]) => {

        const deleteAnswer = this.answers.filter(answer => !uuids.includes(answer.id));
        const addNewAnswers = [...deleteAnswer, ...answers];
        
        this.answers = addNewAnswers;
    }

    public removeAnswer = (uuid: IDType) => {
        this.answers = this.answers.filter(answer => answer.id !== uuid)
    }

    public getAnswer = (uuid: IDType) => this.answers.find(answer => answer.id === uuid);

    public getAvailableAnswers = (previous: IDType, stepsHandler: StepsHandler) => {
        const inverseThree = stepsHandler.getBranch(previous, "previous");
        return this.answers.filter(answer => inverseThree.includes(answer.id));
    }
}
import { useEffect, useState } from "react";

export const defaultTransform = (data: any) => data;

export interface PaginationProps {
    data: any;
    showRows: number;
    transformShowedData?: (data: any) => any;
    numOfConversations?: number;
}

const usePagination = ({ data, showRows, transformShowedData = defaultTransform, numOfConversations }: PaginationProps) => {
  const [paginationPages, setPaginationPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [showedData, setShowedData] = useState(data);

  useEffect(() => {
    setPaginationPages(Math.ceil(data?.length / showRows) || 1);
  }, [data, showRows]);

  useEffect(() => {
    setShowedData(
      transformShowedData(data?.slice((currentPage * showRows - showRows), (currentPage * showRows)))
    );
  }, [currentPage, showRows, data, transformShowedData]);

  return { showedData, paginationPages, setCurrentPage, currentPage };
};

export default usePagination;
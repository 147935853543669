import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import PaginationButton from './PaginationButton';
import PaginationItem from './PaginationItem';
import { PaginationSeparator } from './PaginationSeparator';

const PaginationStyle = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: #F4F5F6;
  width: 100%;
  position: ${props => props.fix ? "sticky" : "initial"};
  left: 0;
`;

const Pagination = ({ pages, setPages, fix }) => {
  const [page, setPage] = useState();
  const [showItems, setShowItems] = useState([]);
  const [maxItems] = useState(10);

  useEffect(() => {
    if(pages===1) setPage(1);
    else{
      if(pages>page) setPage(page);
    }
  }, [pages]);

  useEffect(() => {
    
    setPages(page);
  }, [page, setPages]);

  useEffect(() => {
    let items = [];
    for (let i = 1; i <= pages; i++) items.push(i);

    const numberShowItems = Math.floor(maxItems / 2);

    if (pages > maxItems) {
      if (numberShowItems < page && (page + numberShowItems) < pages) {
        setShowItems(items.slice((page - numberShowItems - 1), (page + numberShowItems)));
      } else if (numberShowItems >= page) {
        setShowItems(items.slice(0, maxItems));
      } else {
        setShowItems(items.slice(pages - maxItems));
      }
    } else {
      setShowItems(items)
    }

  }, [page, maxItems, pages])

  return (
    <PaginationStyle fix={fix}>
      <PaginationButton direction="left" currentPage={page} setCurrentPage={setPage} pages={pages} />
      {
        (!showItems.includes(1) && page > pages / 2 && pages > maxItems) &&
        <>
          <PaginationItem number={1} key={1} active={(page === 1)} onClick={() => setPage((1))} />
          <PaginationSeparator>...</PaginationSeparator>
        </>
      }
      {
        showItems.map((item) => <PaginationItem number={item} key={item} active={(page === (item))} onClick={() => setPage((item))} />)
      }
      {
        (!showItems.includes(pages) && page <= pages / 2 && pages > maxItems) &&
        <>
          <PaginationSeparator>...</PaginationSeparator>
          <PaginationItem number={pages} key={pages} active={(page === pages)} onClick={() => setPage((pages))} />
        </>
      }
      <PaginationButton direction="right" currentPage={page} setCurrentPage={setPage} pages={pages} />
    </PaginationStyle>
  )
}

export default Pagination;
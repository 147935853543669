const spacesRegex = /\s/g;
const specialCharactersRegex = /[^a-zA-Z0-9 ]/g;
const acentsRegex = /[\u0300-\u036f]/g;

export const removeSpecialCharactersAndSpaces = (str: string): string => {
    return str
        .replace(spacesRegex, "_")
        .toLowerCase()
        .normalize("NFD")
        .replace(acentsRegex, "")
        .replace(specialCharactersRegex, "_")
}
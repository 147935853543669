import React from 'react';
import { Outlet } from 'react-router-dom';
import Menu from '../components/UI/organisms/menu/menu.component';
interface AuthWrapperProps { 
    logout: () => void;
}
const AuthWrapper: React.FC<AuthWrapperProps>= ({logout}) => {
    return (
        <>
            <Menu logout={logout} />
            <Outlet />
        </>
    )
}

export default AuthWrapper;
import axios from "axios";

import ILogsActivity, { ILogsActivityRequest } from "../types/requests/bot/logs.interface";
import { validateToken } from "../utils/token";
import { botServiceResponse } from "./draftBots.service";

export interface clientServiceResponse<T = any> {
    status: string;
    message: string;
    data: T
}

const logsService = () => (axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 10000,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token_user')}
}));

export const createLog = async (data: any, endpoint: string, register: string, url: string, type: "Create" | "Update" | "Delete" | "Get" ): Promise<ILogsActivity> => {
    try {
        let axiosBot = logsService();
        let info: ILogsActivityRequest = {
            data : {
                payload: data,
                endpoint: endpoint
            },
            type: type,
            register: register,
            app: "Builder Bots",
            url: url
        }
        const result = await axiosBot.post<botServiceResponse<ILogsActivity>>('logs/create', { logs: info });

        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}


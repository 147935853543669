import React, { useContext } from 'react';
import { IBot } from '../../../types/requests/bot/bot.interface';
import Button from '../../UI/atoms/button/button.component';
import { EColors } from '../../../types/styles/colors.enum';
import PlusIcon from '../../UI/atoms/icon/plusIcon.component';
import { BotFormContext, BotFormContextType } from '../../../context/botForm.context';
import BotList from '../../UI/organisms/list/botList.component';
import ErrorWrapper from '../../UI/atoms/wrapper/errorWrapper.component';
import PageHeader from '../../UI/molecules/pageHeader/pageHeader.component';

export interface MainTemplateProps {
    bots: IBot[];
    isLoading: boolean;
    error: any;
    isError: boolean;
    title: string;
    showCreateBot: boolean;
    isDraftBot?: boolean;
    onlyView?: boolean;
}

const MainTemplate: React.FC<MainTemplateProps> = ({ bots, error, isError, isLoading, title, showCreateBot, isDraftBot, onlyView }) => {

    const { setVisible } = useContext(BotFormContext) as BotFormContextType;

    return (
        <>
            <h2 className='my-5'>{title}</h2>
            <div className="d-flex flex-wrap m-auto justify-content-lg-start justify-content-center gap-4">

            { showCreateBot && <Button
                    style={{ width: "250px", borderRadius: "16px", paddingTop: "48px", paddingBottom: "48px" }}
                    btnType='primary'
                    onClick={() => setVisible(true)}
                    disabled={isLoading || isError}
                >
                    <PlusIcon colorCode={EColors.white} width={64} height={64} />
                </Button>
            }
            <ErrorWrapper actionMessage='Recargar página' error={error}>
                <BotList isLoading={isLoading} bots={bots} isDraftBot={isDraftBot} onlyView={onlyView}/>
            </ErrorWrapper>
            </div>
        </>
    )
}

export default MainTemplate;
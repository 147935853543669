import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IDType } from '../../../../types/requests/bot/bot.interface';
import { EConditionTypes } from '../../../../types/requests/bot/conditionTypes.enum';
import Tag, { TagTypes } from '../../atoms/tag/tag.component';
import { GetConditionTypeText } from '../../../../utils/pipes/conditionType.pipe';
import useBot from '../../../../hooks/useBot.hook';
import MixedTagText from '../../../templates/TagInput/tagText';

export interface ConditionProps {
    answer: IDType,
    type: EConditionTypes,
    value: string | object,
    idx: number,
    row: number,
    prefix: string,
    number?: number,
}

const Condition: React.FC<ConditionProps> = ({ answer, type, value, idx, row, prefix,number }) => {
    const { answersHandler } = useBot();
    const stepAnswer = answersHandler.getAnswer(answer);

    return (
        <>
            <Tag type={TagTypes.secundary}> 
                <span className="mx-1 fw-light d-block">
                    <MixedTagText value={answer} name={`value1-${row}-${idx}`} id={`value1-${prefix}-${row}-${idx}${number ? number: ""}`} withoutBorder={true}/>
                </span>
            </Tag>
            <b>{GetConditionTypeText(type)}</b>
            {!["is_email", "is_number"].includes(type) && <Tag type={TagTypes.terciary}>
            <span className="mx-1 fw-light d-block">
                    <MixedTagText value={value.toString() || "(Vacio)"} name={`value2-${row}-${idx}`} id={`value2-${prefix}-${row}-${idx}${number ? number: ""}`} withoutBorder={true}/>
                </span>
            </Tag>}
        </>
    )
}

export default Condition;
import React, { useEffect, useState } from "react";
import { IBot } from "../../../../types/requests/bot/bot.interface";
import CardWrapper from "../../atoms/wrapper/card.component";
import { FlexGap } from "../../molecules/steps/step.styles";
import { useNavigate } from "react-router-dom";
import Button from "../../atoms/button/button.component";
import PencilIcon from "../../atoms/icon/pencilIcon.component";
import TrashIcon from "../../atoms/icon/trashIcon.component";
import { EColors } from "../../../../types/styles/colors.enum";
import LoaderWrapper from "../../atoms/wrapper/loaderWrapper.component";
import Skeleton from "react-loading-skeleton";
import { archiveBot, createBot, generateBot, getTokenByBotId, updateBot } from "../../../../services/bots.service";
import { useMutation, useQueryClient } from "react-query";
import { AlertConfirm, AlertForm, ICON } from "../../../../utils/Alert";
import { createDraftBot, deleteDraftBot, getDraftBot } from "../../../../services/draftBots.service";
import { IDraftBot, IDraftBotResponse } from "../../../../types/requests/bot/draftbot.interface";
import CloneIcon from "../../atoms/icon/cloneIcon.component";
import { validateBot } from "../../../../utils/bots/validate.utils";
import { ITokens } from "../../../../types/requests/tokens/tokens.interface";
import { createLog } from "../../../../services/logs.service";

export interface BotListProps {
    bots: IBot[];
    isLoading: boolean;
    isDraftBot?: boolean;
    onlyView?: boolean;
}

const BotList: React.FC<BotListProps> = ({ isLoading, bots, isDraftBot, onlyView }) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [isSendRequest, setIsSendRequest] = useState<boolean>(false);
    const [disabledStates, setDisabledStates] = useState<boolean[]>([]);
    const [numberStates, setNumberStates] = useState<Partial<ITokens[]>[]>([]);
    const archiveTodoMutation = useMutation(archiveBot, {
        onSuccess: async (data, variables) => {
            queryClient.invalidateQueries('bots');
            await createLog({ id: variables },`draftBots/delete/${variables}`, `Eliminar Bot: ${variables}`, window.location.href, "Delete"); 
        },
      });
    const deleteTodoMutation = useMutation(deleteDraftBot, {
        onSuccess: async (data, variables) => {
            queryClient.invalidateQueries('draft_bots');
            await createLog({ id: variables },`draftBots/delete/${variables}`, `Eliminar Draft Bot: ${variables}`, window.location.href, "Delete"); 
        },
      });
    
    useEffect(() => {
    const validateAllBots = async () => {
        const results = await Promise.all(bots.map(bot => handleValidateBot(bot)));
        setDisabledStates(results);
        const resultsToken = await Promise.all(bots.map(bot => requestTokenBot(bot._id)));
        setNumberStates(resultsToken || []);

       };
    validateAllBots();
    }, [bots]);

    const onConfirmDeleteBot = async (bot: IBot) => {        
        let result = await AlertConfirm({ 
            title: "¿Estás seguro de que quieres eliminar este bot?", 
            showCancelButton: true, 
            cancelButtonText: "Cancelar",  
            confirmButtonText: "Eliminar"
        })
        
        if (result.isConfirmed) {
            if(isDraftBot) {
                await deleteTodoMutation.mutateAsync(bot._id );
            } else {
                await archiveTodoMutation.mutateAsync(bot._id);
            }
        } 
    };
    const onConfirmCloneBot = async (bot: IBot) => {        
        let result = await AlertConfirm({ 
            title: "¿Desea Clonar este bot?", 
            showCancelButton: true, 
            cancelButtonText: "Cancelar",  
            confirmButtonText: "Si"
        })
        
        if (result.isConfirmed) {
                let copyBot = {...bot, name: bot.name + " (Clone)", never_published: true, message_failure_process: ""};
                delete copyBot.legacy;
                delete copyBot.id_draft;
                delete copyBot.version;
                delete copyBot.archived;
                delete copyBot.__v;
                //@ts-ignored
                delete copyBot.connectionBusiness;
                //@ts-ignored
                copyBot._id = undefined;
                try {
                    await createDraftBot(copyBot as IDraftBot, {register: "Clonar y crear Draft Bot", url: window.location.href });
                    AlertForm({title: "Bot clonado correctamente", text: "El bot clonado se encuentra en Bots sin publicar", icon: ICON.SUCCESS});
                    queryClient.invalidateQueries('draft_bots');
                } catch (error: any) {
                    console.warn(error.response)
                    AlertForm({title: "Hubo un inconveniente al clonar bot", text: error.response.data?.message + " " + JSON.stringify(error.response.data?.validation), icon: ICON.ERROR});
                    
                }
                
        } 
    };

    const handlePublish = async (bot: any) => {
        setIsSendRequest(true);
        if(isDraftBot) {
           await handlePublishDraft(bot);
        } else {
           await handlePublishBot(bot);
        }
        setIsSendRequest(false);
    }

    const handlePublishDraft = async (bot: IDraftBotResponse, title:string = "Bot Publicado con exito") => {
        let id = bot._id || "";

        const dataBot = {
            ...bot,
            version: "1",
        };

        dataBot._id = undefined;
        delete dataBot.__v;
        delete dataBot.archived;
        delete dataBot.never_published;

        let botReq = await createBot(dataBot, {register: `Publicar Bot en estado Draft: ${dataBot.name}`, url: window.location.href});
        await deleteDraftBot(id);

        await generateBot(botReq._id);
        queryClient.invalidateQueries('bots');
        queryClient.invalidateQueries('draft_bots');
        AlertForm({title: title, icon: ICON.SUCCESS});
    }

    const handlePublishBot = async (bot: IBot) => {
        let id = bot._id || "";
        let dataDraft = await getDraftBot(bot.id_draft!);

        const dataBot = {
            ...dataDraft,
            version: isDraftBot ? "": bot.version,
        };

        dataBot._id = undefined;
        delete dataBot.__v;
        delete dataBot.archived;
        delete dataBot.never_published;
        dataBot.version = String(Number(dataBot.version) + 1);
        await updateBot(id, {...dataBot, id_draft: null}, {register: `Publicar Bot: ${dataBot.name}`, url: window.location.href});
        await deleteDraftBot(bot.id_draft!);
        await generateBot(id);
        queryClient.invalidateQueries('bots');
        queryClient.invalidateQueries('draft_bots');
        AlertForm({title: "Bot Publicado con exito", icon: ICON.SUCCESS})
    }
    const handleEditBot = async (bot: IBot) => {       
        if(isDraftBot) {
           return navigate(`/edit/${bot._id}?never_published=true`);
        } else {
            //* Crear Draft Bot si no existe 
            //* O redireccionar ID del Draft Bot si existe
            if(bot.id_draft) {
                return navigate(`/edit/${bot.id_draft}`);
            } else {
                // Crear Draft Bot
                let draftBot = await createDraftBot({
                    name: bot.name, 
                    environment: bot.environment, 
                    answers: bot.answers, 
                    inactive_message: bot.inactive_message || [], 
                    steps: bot.steps, 
                    never_published: false,
                    stop_notifications: bot.stop_notifications,
                    send_agent: bot.send_agent,
                    finish_last_message: bot.finish_last_message || false,
                    reattempts_agent: bot.reattempts_agent || "N/A",
                    hubspot_default_values:  bot.hubspot_default_values || [],
                    message_failure_process: bot.message_failure_process || "",
                }, {register: "Editar bot y crear Draft Bot", url: window.location.href });
                // Asociarlo al bot
                await updateBot(bot._id, {id_draft: draftBot._id});
                // Redirigir al editor
                return navigate(`/edit/${draftBot._id}`);
            }
        }
    }

    const handleDiscard = async (bot: IBot) => {
        let result = await AlertConfirm({title: "Desea descartar cambios?", text: "Si se descarta se elimina la información modificada", icon: ICON.WARNING});
        if(result.isConfirmed) {
            //* Desasociar id_draft del bot
            //* Eliminar draft bot
            await updateBot(bot._id, {id_draft: null},  {register: `Descartar cambios del Bot: ${bot.name}`, url: window.location.href});
            await deleteDraftBot(bot._id);
            queryClient.invalidateQueries('bots');
            AlertForm({title: "Operación realizado con exito", icon: ICON.SUCCESS });
        }
    }

    const handleValidateBot = async(bot: IBot):Promise<boolean> => {
        if(isSendRequest) return isSendRequest;
        if(bot.id_draft) {
            let dataDraft = await getDraftBot(bot.id_draft!);
            let validate = !validateBot(dataDraft);
            return validate;
        } else {
            let validate = !validateBot(bot);
            return validate;
        }
    }
    const requestTokenBot = async(botId: string):Promise<Partial<ITokens[]>> => {
        let dataDraft = await getTokenByBotId(botId!);
        return dataDraft;
    }
    return (
        <LoaderWrapper
            isLoading={isLoading}
            loader={
                <>
                    {[...new Array(3)].map((_, i) => (
                        <CardWrapper key={i} style={{ maxWidth: "250px" }}>
                            <FlexGap gap="8px">
                                <Skeleton className="w-100 p-1" />
                                <Skeleton className="w-75" />
                                <Skeleton className="w-75" />
                            </FlexGap>
                            <div className="d-flex w-100 mt-1">
                                <Skeleton
                                    baseColor={EColors.quaternary}
                                    containerClassName="w-100 me-2"
                                    className="w-100 p-1"
                                />
                                <Skeleton width={50} className="p-1" />
                            </div>
                        </CardWrapper>
                    ))}
                </>
            }
        >
            {bots?.length > 0 ? (
                bots?.map((bot, index) => {
                    return (
                        <CardWrapper
                            key={bot._id}
                        >
                            <FlexGap gap="8px">
                                <div className="fw-medium text-uppercase text-truncate">
                                    {bot.name}
                                </div>
                                {!isDraftBot && <div className="fw-light"><b>Version: </b> {bot.version} </div>}
                                {!(isDraftBot || onlyView)  &&  numberStates[index]?.length ? <>Conectado a:  {numberStates[index]?.map((el: any) => `+${el.number}`).join(", ") || "N/A"}</>: "" }

                            </FlexGap>
                            <section style={{ width: "100%" }}>
                            <div className="d-flex w-100 mt-1">
                            {(bot.id_draft || isDraftBot) && <> 
                                <Button
                                    className="w-100"
                                    btnType="custom"
                                    color="white"
                                    bgColor="green"
                                    onClick={() => handlePublish(bot)}
                                    disabled={disabledStates[index]}
                                >
                                    <span>Publicar</span>
                                </Button>
                           
                            </>
                            }
                            {bot.id_draft && <> 
                                <Button
                                    btnType="custom"
                                    color="white"
                                    bgColor="orange"
                                    className="ms-2"
                                    onClick={() => handleDiscard(bot)}
                                >
                                    <span>Descartar</span>
                                </Button>

                            </>
                            } 
                             </div>
                            <div className="d-flex w-100 mt-1">
                                {!onlyView && 
                                    <>
                                        <Button btnType="primary" className="w-100" onClick={() => handleEditBot(bot)}>
                                            <PencilIcon
                                                width={16}
                                                height={16}
                                                colorCode={EColors.white}
                                                className="me-2"
                                            />
                                            <span>Editar</span>
                                        </Button>

                                        <Button
                                            className="ms-2"
                                            btnType="custom"
                                            bgColor="black"
                                            color="white"
                                            title="Clonar"
                                            onClick={() => {
                                                onConfirmCloneBot(bot);
                                            }}
                                            >
                                                <CloneIcon
                                                    width={16}
                                                    height={16}
                                                    colorCode={EColors.white}
                                                />
                                        </Button>

                                        <Button
                                        className="ms-2"
                                        btnType="custom"
                                        bgColor="red"
                                        color="white"
                                        onClick={() => {
                                            onConfirmDeleteBot(bot);
                                        }}
                                        >
                                            <TrashIcon
                                                width={16}
                                                height={16}
                                                colorCode={EColors.white}
                                            />
                                        </Button>
                                    </>
                                }
                               
                            </div>
                            </section>
                        </CardWrapper>
                    );
                })
            ) : (
                <div>
                    {isDraftBot ? "No hay Bots en Borrador": "Comienza a generar valor para tus clientes creando un bot."}
                </div>
            )}
        </LoaderWrapper>
    );
};

export default BotList;


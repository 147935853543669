import styled from "styled-components";
import { EColors } from "../../../../types/styles/colors.enum";

export const FlowContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 800px;
  backgrund: blue;
`;

export const StepsContainer = styled.div<{ orientation: "v" | "h" }>`
  display: flex;
  justify-content: ${props => props.orientation === "v" ? "center" : "start"};
  align-items: ${props => props.orientation === "v" ? "start" : "center"};
  cursor: grab;
  gap: 80px;
  margin-bottom: 16px;
  flex-direction: ${props => props.orientation === "v" ? "row" : "column"};
  &:active {
    cursor: grabbing;
  }
`;

export const StepBase = styled.div<{ active: boolean }>`
  position: relative;
  overflow: visible;
  display: block;
  border-radius: 16px;
  box-shadow: ${props => props.active ? `0px 0px 9px ${EColors.primary}` : "0px 1px 2px rgba(0, 0, 0, 0.25)"};
  background: #FFFFFF;
  text-align: center;
  width: max-content;
  font-weight: light;
`;

export const StepStyle = styled(StepBase)`
  max-width: 400px;
  overflow: hidden !important;
`;

export const StepHeader = styled.h3`
  padding: 8px 40px;
  margin: 0;
  color: #3D3D3D;
  font-size: 16px;
  font-weight: 500;
  border-bottom: solid 1px #C4C4C4;
`;

export const StepContent = styled.div`
  padding: 8px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: light;
`;

export const ConditionalConnectors = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
  gap: 8px;
  position: absolute;
  bottom: -23px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
`;

export const StepElement = styled.div`
  padding: 8px 0;
`;

export const StepFormWrapper = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.19);
    top: 0;
    left: 0;
    z-index: 100;
`;


export const StepFormContainer = styled.div<{ visible: boolean }>`
  position: fixed;
  border-radius: 8px;
  top: 56px;
  right: ${props => props.visible ? "0px" : "-500px"};
  max-width: 500px;
  width: 100%;
  z-index: 101;
  background: white;
  height: calc(100vh - 56px);
  overflow-y: auto;
  box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.25);
  transition: 1s;
`;

export const FormHeader = styled.fieldset`
  color: white;
  background: #3D3D3D;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  font-size: 20px;
  font-weight: 500;

  input {
    background: transparent;
    color: white;
    border: solid 1px white;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }

  .close {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  max-height: calc(100vh - 125px);
  padding: 16px 16px;
  position: relative;
  .form-group {
    margin: 0;
    width: 100%;
    label:first-child {
      font-weight: 500;
    }
  }
  .btn-group-form {
    position: sticky;
    bottom: -16px;
    left: 0px;
    background-color: white;
    padding: 1rem;
  }
`;

export const StepTypesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;

export const FloatForm = styled.div<{ mode: string, visible: boolean /*! Error move here */ }>`
  max-height: calc(100vh - 56px);
  border-radius: 8px;
  overflow-y: auto;
  position: fixed;
  top: ${props => props.mode === "update" ? "56px" : "50%"};
  width: 100%;
  left: ${props => props.visible ? (props.mode === "update") ? "0" : "50%" : "calc(-100% - 10px)"};
  max-width: 40%; 
  background: white;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.25);
  z-index: 101;
  transition: 1s;

  ${props => props.mode === 'create' && `
    transform: translate(-50%, -50%);
  `}

  ${props => props.mode === 'delete' && `
    tranform: translate(-50%, -50%);
  `}
`;

export const FlexGap = styled.div<{ gap?: string }>`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: ${({ gap }) => gap ?? "16px"};
`;
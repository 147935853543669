import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { IBot } from "../../../types/requests/bot/bot.interface";
import { ISchedule } from "../../../types/requests/bot/schedule.interface";
import {
    FloatForm,
    FormBody,
    FormHeader,
} from "../../UI/molecules/steps/step.styles";
import InputBox from "../../UI/molecules/inputs/inputBox.component";
import Schedule from "../../UI/molecules/inputs/schedule.component";
import Swal from "sweetalert2";
import Button from "../../UI/atoms/button/button.component";
import RadiosGroup from "../../UI/molecules/inputs/radiosGroup.component";
import Table from "../../UI/molecules/table/table.component";
import { AlertConfirm, AlertForm, ICON } from "../../../utils/Alert";


interface IData {
    name: string,
    value: string,
    isEdit: boolean,
}

interface FormValues {
    environment_create_name: string,
    environment_create_value: string,
    tableData: IData[],
}

export interface EnvFormProps {
    bot?: Partial<IBot>;
    setIsVisibleEnvForm: (visible: boolean) => void;
    isVisibleEnvForm:boolean;
    submitForm: (bot: Partial<IBot>) => Promise<any>;
}


const EnvForm: React.FC<EnvFormProps> = ({ bot, setIsVisibleEnvForm, isVisibleEnvForm , submitForm  }) => {

    const handleSubmit = async (values: FormValues) => {
        const dataBot = {
            environment: values.tableData.map(el => ({name: el.name, value: el.value }))
        }
        try {
            await submitForm(dataBot);
        } catch (e: any) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: e.message,
            });
        }
        setIsVisibleEnvForm(false);
    };

    const handleCreateEditEnv = async (values: FormValues, setFieldValue: any, index:number) => {
        const newItems = [...values.tableData]; // Copiamos el array de items
        newItems[index] = { ...newItems[index], isEdit: !newItems[index].isEdit }; // Actualizamos el objeto en el índice especificado con el nuevo valor
        setFieldValue('tableData', newItems); // Actualizamos el valor en Formik
    }

    const validateVariableInFlow = (data: IData) => {
        let steps = bot!.steps || [];
        let variable = `env.${data.name}`;
        let isValid = true;
        for (const step of steps) {
            if(step.action.type === "condition"){
                //@ts-ignore
                for (const caseData of step.condition.cases) {
                    let find = caseData.conditions.find(el => el.answer.includes(variable) || el.value.includes(variable));
                    if(find) {
                        isValid = false;
                        break;
                    }
                }
            } else {
                if(step.message?.includes(variable)) {
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    }

    const handleDeleteEnv = async (values: FormValues, setFieldValue: any, index:number) => {
        let result = await AlertConfirm({title: "Eliminar Variable?"});
        if(result.isConfirmed){
            if(validateVariableInFlow(values.tableData[index])) {
                let data = values.tableData;
                data.splice(index, 1);
                setFieldValue("tableData", data);
            } else {
                AlertForm({title: "No se puede eliminar Variable", text: "La variable es usado en el flujo, debes borrarlo para poder eliminarlo", icon: ICON.WARNING})
            }
        }
    }

    const handleCreateEnv = (values: FormValues, setFieldValue: any) => {
        // Agregamos el dato adicional al objeto de valores
        let data = values.tableData;
        let findRepeatName = data.find(el => el.name === values.environment_create_name);
        if(findRepeatName) {
            return AlertForm({title: "Nombre de Variable de Entorno ya existe", text: "Por favor usar otro nombre"})
        }
        if(values.environment_create_name && values.environment_create_value) {
            data.push({name: values.environment_create_name, value: values.environment_create_value, isEdit: false});
            // Establecemos los nuevos valores en Formik
            setFieldValue("tableData", data);
            setFieldValue("environment_create_name", "");
            setFieldValue("environment_create_value", "");
        }
    }
    
    const handleCancel = async (setFieldValue: any) => {
            let result = await AlertConfirm({title: "Cancelar Guardado?", text: "No se guardara ningun cambio"});
            if(result.isConfirmed){
                setIsVisibleEnvForm(false);
                setFieldValue("tableData", bot?.environment?.map(el => ({
                    ...el, isEdit: false
                })) || []);
                setFieldValue("environment_create_name", "");
                setFieldValue("environment_create_value", "");
            }
    }

    const validationCreateEnv = (values: {environment_create_name:string, environment_create_value: string}): boolean => {
        let dataEnv = (values.environment_create_name && values.environment_create_value) ? true: false;
       /*  let validateSpaces = values.environment_create_name.indexOf(" ") === -1; */
        return !(dataEnv /* && validateSpaces */);
    }

    const validationEditEnv = (data: {name:string, value: string}): boolean => {
        let dataEnv = (data.name && data.value) ? true: false;
        return !(dataEnv);
    }
    return (
        <FloatForm visible={isVisibleEnvForm} mode={"create"}>
            <FormHeader>
                <span>
                    Administrar Variables de Entorno
                </span>
            </FormHeader>
            <Formik
                initialValues={{
                    tableData: bot?.environment?.map(el => ({
                        ...el, isEdit: false
                    })) || [],
                    environment_create_name: "",
                    environment_create_value: "",
                }}
                onSubmit={handleSubmit}
            >
                {({ isValid, isInitialValid, resetForm, isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <FormBody>
                            <Table headers={["Nombre", "Valor", "Acciones"]}>
                            {values.tableData.map((row, index) => (
                                <tr key={index}>
                                    <td>
                                        { values.tableData[index]?.isEdit ? <InputBox
                                            id={`tableData.${index}.name`}
                                            label=""
                                            placeholder="Ingrese nombre de la variable"
                                            name={`tableData.${index}.name`}
                                            value={values.tableData[index]?.name}
                                        /> : values.tableData[index]?.name }
                                        
                                    </td>
                                    <td>
                                        { values.tableData[index]?.isEdit ? <InputBox
                                            id={`tableData.${index}.value`}
                                            label=""
                                            placeholder="Ingrese valor de la variable"
                                            name={`tableData.${index}.value`}
                                            value={values.tableData[index]?.value}
                                        /> : values.tableData[index]?.value }
                                    </td>
                                    <td> 
                                    <div className="d-flex justify-content-center gap-2">
                                        <Button
                                            btnType="custom"
                                            type="button"
                                            bgColor="green"
                                            onClick={() => handleCreateEditEnv(values, setFieldValue, index)}
                                            disabled={validationEditEnv(values.tableData[index])}
                                        >
                                            {values.tableData[index]?.isEdit ? "Guardar": "Editar"} 
                                        </Button>
                                        {!values.tableData[index]?.isEdit &&<Button
                                            btnType="custom"
                                            type="button"
                                            bgColor="red"
                                            onClick={() => handleDeleteEnv(values, setFieldValue, index)}
                                        >
                                            Eliminar
                                        </Button>}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                                <tr>
                                    <td>
                                        <InputBox
                                            id={`environment_create_name`}
                                            label=""
                                            placeholder="Ingrese nombre la variable"
                                            name={`environment_create_name`}
                                            value={values.environment_create_name}
                                        />
                                    </td>
                                    <td>
                                        <InputBox
                                            id={`environment_create_value`}
                                            label=""
                                            placeholder="Ingrese valor de variable"
                                            name={`environment_create_value`}
                                            value={values.environment_create_value}
                                        />
                                    </td>
                                    <td>
                                        <Button
                                            btnType="primary"
                                            type="button"
                                            onClick={() => handleCreateEnv(values, setFieldValue)}
                                            disabled={validationCreateEnv(values)}
                                        >
                                            Crear
                                        </Button>
                                    </td>
                                </tr>
                            </Table >

                            <div className="d-flex justify-content-end gap-2">
                                <Button
                                    btnType="primary"
                                    type="submit"
                                    isSubmitting={isSubmitting}
                                >
                                    Guardar
                                </Button>
                                <Button
                                    btnType="tertiary"
                                    type="button"
                                    isSubmitting={isSubmitting}
                                    onClick={() => handleCancel(setFieldValue)} 
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </FormBody>
                    </Form>
                )}
            </Formik>
        </FloatForm>
    );
}

export default EnvForm;


import styled, { keyframes } from 'styled-components'

export const LoadAnimation = keyframes`
  0% { background: linear-gradient(90deg, rgba(226,226,226,1) 0%, rgba(231,231,231,1) 0%, rgba(190,190,190,1) 100%); }
  25% { background: linear-gradient(90deg, rgba(226,226,226,1) 0%, rgba(231,231,231,1) 25%, rgba(190,190,190,1) 100%); }
  50% { background: linear-gradient(90deg, rgba(226,226,226,1) 0%, rgba(231,231,231,1) 50%, rgba(190,190,190,1) 100%); }
  75% { background: linear-gradient(90deg, rgba(226,226,226,1) 0%, rgba(231,231,231,1) 75%, rgba(190,190,190,1) 100%); }
  100% { background: linear-gradient(90deg, rgba(226,226,226,1) 0%, rgba(231,231,231,1) 100%, rgba(190,190,190,1) 100%); }
`;

export const LoadAnimationV2 = keyframes`
  0% { background: #cccccc }
  50% { background: rgba(235, 235, 235, 0.8) }
  100% { background: #cccccc }
`;

export const ContentLoading = styled.div`
  width: ${props => props?.aproxWidth || "100%"};
  min-height: 24px;
  background-color: #c9c9c9;
  border-radius: 6px;
  animation: ${LoadAnimationV2} 2s infinite ease-in-out;
  transition: 1ms;
`;

export default ContentLoading;
import React, { useContext } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { StepsFormContext, StepsFormContextType } from '../../../../context/stepsForm.context';
import Controls from '../controls/controls.component';
import Connectors from '../connectors/connectors.component';
import { EColors } from '../../../../types/styles/colors.enum';
import Flow from '../flow/flow.component';
import useBot from '../../../../hooks/useBot.hook';

export interface BotSpaceProps {
    complete: boolean;
}

const BotSpace: React.FC<BotSpaceProps> = ({ complete }) => {
    
    const { bot } = useBot();
    const steps = bot!.steps;
    const { arrowsAreVisibles, setArrowsAreVisibles } = useContext(StepsFormContext) as StepsFormContextType;

    return (
        <TransformWrapper
            minScale={0.5}
            maxScale={3}
            centerOnInit={true}
            limitToBounds={false}
        >
            {({ zoomIn, zoomOut, resetTransform, centerView, state }) => (
                <div className='position-relative'>
                    <div style={{
                        overflow: "hidden",
                        position: "relative",
                        zIndex: 0
                    }}>
                        <TransformComponent wrapperStyle={{ background: EColors.bgGray, width: "100vw", height: "calc(100vh - 5rem)" }}>
                            <div className='m-4'>
                                <Flow steps={steps.filter(step => !step.parentStep)} main={true} globalSteps={steps} currentBot={bot} />
                            </div>
                        </TransformComponent>

                        {arrowsAreVisibles && <Connectors steps={steps} scale={state.scale} />}
                    </div>
                    <Controls 
                        resetZoom={() => centerView(1, 500, "linear")}
                        addZoom={() => zoomIn()}
                        removeZoom={() => zoomOut()}
                        visibleArrows={arrowsAreVisibles}
                        setVisibleArrows={setArrowsAreVisibles}
                        complete={complete}
                        steps={steps}
                        bot={bot!}
                    />
                </div>
            )}
        </TransformWrapper>
    )
}

export default BotSpace;
import axios from "axios";

import { IPropertyContact } from "../types/requests/hubspot/contact.interface";
import { validateToken } from "../utils/token";

export interface clientServiceResponse<T = any> {
    status: string;
    message: string;
    data: T
}

const clientService = () => (axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 10000,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token_user')}
}));

export const getPropertiesContact = async (): Promise<IPropertyContact[]> => {
    try {
        let axiosBot = clientService();
        const result = await axiosBot.get<clientServiceResponse<IPropertyContact[]>>(`hubspot/properties-contact`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}

export default clientService;

import React, { createContext, useState, PropsWithChildren } from "react";

export interface BotFormContextType {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    isModified: boolean,
    setIsModified: (isModified: boolean) => void;
}

export const BotFormContext = createContext<BotFormContextType | null>(null);

const BotFormProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [showBotForm, setShowBotForm] = useState<boolean>(false);
    const [modified, setModified] = useState<boolean>(false);

    return (
        <BotFormContext.Provider value={{
            visible: showBotForm,
            setVisible: setShowBotForm,
            isModified: modified,
            setIsModified: setModified,
        }}>
            {children}
        </BotFormContext.Provider>
    )
}

export default BotFormProvider;
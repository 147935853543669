import styled from "styled-components";
import { EColors } from "../../../../types/styles/colors.enum";
import { ButtonHTMLAttributes } from "react";

export type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'custom';
export interface ButtonProps {
    btnType: ButtonType,
    childType?: 'icon' | 'text',
    isSubmitting?: boolean,
    bgColor?: string,
    color?: string,
}

const getButtonColors = (type: ButtonType, bgColor: string = EColors.primary, colorText:string = EColors.white) => {
    switch (type) {
        case 'primary':
            return {
                backgroundColor: EColors.primary,
                color: EColors.white,
                border: `solid 1px ${EColors.primary}`
            }
        case 'secondary':
            return {
                backgroundColor: EColors.secondary,
                color: EColors.white,
                border: `solid 1px ${EColors.secondary}`
            }
        case 'tertiary':
            return {
                backgroundColor: EColors.white,
                color: EColors.text,
                border: `solid 1px ${EColors.text}`
            }
        case 'custom':
            return {
                backgroundColor: bgColor,
                color: colorText,
                border: `solid 1px ${bgColor}`
            }
    }
}

const ButtonStyles = styled.button<ButtonProps>`
    ${props => getButtonColors(props.btnType, props.bgColor, props.color)};
    padding: ${props => props.childType === "icon" ? "8px" : "8px 16px"};
    border-radius: 6px;
    line-height: ${props => props.childType === "icon" && 0};
    font-weight: 500;
    
    filter: ${props => props.disabled === true ? 'opacity(50%)' : 'opacity(100%)'};
    
    &:hover {
        box-shadow: 2px 2px 2px var(--text-color);
    }
    * {
        vertical-align: middle;
        display: inline-block;
    }
`;

const Button: React.FC<ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps> = ({ isSubmitting, btnType, disabled, children, ...props }) => {
    return (
        <ButtonStyles btnType={btnType} disabled={isSubmitting || disabled} {...props}>
            {
                isSubmitting ?
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    : children
            }
        </ButtonStyles>
    )
}


export default Button;
import axios from "axios";
import { IBot, IBotRequest } from "../types/requests/bot/bot.interface";
import { IClient, IGroupSubgroup } from "../types/requests/client/client.interface";
import { validateToken } from "../utils/token";

export interface clientServiceResponse<T = any> {
    status: string;
    message: string;
    data: T
}

const clientService = () => (axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 10000,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token_user')}
}));

export const getClientGroupSubgroup = async (): Promise<IGroupSubgroup> => {
    try {
        let axiosBot = clientService();
        const result = await axiosBot.get<clientServiceResponse<IGroupSubgroup>>(`client/group_subgroup`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}

export default clientService;

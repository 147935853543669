import styled from "styled-components";

export const StepTable = styled.table`
  border-spacing: 4px;
  border-collapse: separate;
  max-width: 500px;
  th, td {
    padding: 8px 16px;
    width: max-content;
  }

  thead {
    th {
      background: var(--primary-color);
      color: white;
      font-weight: 500;
    }
  }
  tbody {
    tr {
      td:first-child {
        background: var(--quaternary-color);
        color: white;
        font-weight: 500;
      }
      td:last-child {
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
      }
      td {
        background: #F4F4F4;
        
        &.tag-text {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 400px;
            gap: 0.4rem;
        }
      }
    }
  }
`;

import React from 'react'
import styled from 'styled-components';

const ItemStyled = styled.button`
  background-color: ${props => props.active ? "#3D3D3D" : "transparent" };
  color: ${props => props.active ? "white" : "#3D3D3D" };
  padding: 4px 6px;
  font-weight: 500;
  font-size: 14px;
  margin: 8px 12px;
  width: max-content;
  height: max-content;
  border: none;
  border-radius: 4px;
  line-height: 2;
`

const PaginationItem = (props) => {
  const { number } = props;
  return (
    <ItemStyled {...props}>{number}</ItemStyled>
  )
}

export default PaginationItem;
import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider, RouteObject } from "react-router-dom";
import AuthWrapper from './authWrapper.component';
import EditPage from '../pages/edit/edit.page';
import MainPage from '../pages/main/main.page';
import Error from '../components/UI/molecules/error/error.component';
import ChannelsPage from '../pages/channels/channels.page';
import LoginForm from '../pages/Login';
import { useQuery } from 'react-query';
import { userCurrent } from '../services/user.service';
import { LoadingMain } from '../components/LoadingMain';
import ConnectionPage from '../pages/connection/connection.page';

const Router: React.FC = () => {
    const { data, isLoading, isError } = useQuery('userCurrent', userCurrent, {retry: false});
    const [login, setLogin] = useState(false);

    useEffect(() => {
        if(isError) {
            handleCloseSession();
        } else if (localStorage.getItem('token_user')) {
          if (process.env.REACT_APP_VERSION_SESSION !== localStorage.getItem('VERSION_SESSION')) {
            localStorage.clear();
            sessionStorage.clear();
          } else {
            setLogin(true);
            if(!data) return;
            const user = data?.user;
            const company = data?.company;
            localStorage.setItem('client', String(user.connectionBusiness?.business?.client || {}));
            localStorage.setItem('firstname', user.firstname);
            localStorage.setItem('updated', '');
            localStorage.setItem('company', company ?? "");
        }}}, [data]);

        
    const handleCloseSession = () => {
        localStorage.clear();
        sessionStorage.clear();
        setLogin(false);
      };

    const router: RouteObject[] =
        (login) ?
            [
                {
                    path: "/",
                    element: <AuthWrapper logout={handleCloseSession} />,
                    errorElement: <Error error={null} />,
                    children: [
                        {
                            path: "/",
                            element: <MainPage />,
                        },
                       
                        {
                            path: "edit/:botId",
                            element: <EditPage />
                        },
                        {
                            path: "channels",
                            element: <ChannelsPage />
                        },
                        {
                            path: "connection",
                            element: <ConnectionPage />
                        },
                        {
                            path: "*",
                            element: <Error
                                error={{ response: { data: { message: "La ruta especificada no existe." } } }}
                                forceErrorCode={404}
                            />
                        }
                    ]
                }
            ]
            :
            [
                {
                    path: "/",
                    element: <LoginForm setLogin={setLogin} />
                },
                {
                    path: "*",
                    element: <Error
                        error={{ response: { data: { message: "La ruta especificada no existe." } } }}
                        forceErrorCode={404}
                    />
                }
            ]
    if(isLoading) return <LoadingMain />

    return (
        <RouterProvider router={createBrowserRouter(router)} />
    )
}

export default Router;
import React from 'react';
import ChannelsTemplate from '../../components/templates/channels/channels.template';
import AccountForm from '../../components/templates/account/accountForm.template';

export interface ChannelsPageProps { }

const ChannelsPage: React.FC<ChannelsPageProps> = ({ }) => {
    return (
        <div className='container-lg' style={{padding: "4rem 0"}}>
            <ChannelsTemplate />
            <AccountForm visible={true} />
        </div>
    );
}

export default ChannelsPage;
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import MainTemplate from '../../components/templates/main/main.template';
import BotForm from '../../components/templates/botForm/botForm.template';
import BotFormProvider from '../../context/botForm.context';
import { useQuery } from 'react-query';
import { getBots } from '../../services/bots.service';
import { useNavigate } from 'react-router-dom';
import { EActions } from '../../types/requests/bot/actions.enum';
import { EMessageType } from '../../types/requests/bot/stepType.enum';
import { createDraftBot, getDraftBots } from '../../services/draftBots.service';
import { IDraftBot } from '../../types/requests/bot/draftbot.interface';


const MainPage: React.FC = () => {

    let stepIdStart = uuidv4();
    let stepId = uuidv4();
    let stepId2 = uuidv4();

    const emptyDraftBot: Omit<IDraftBot, '_id'> = {
        name: '',
        answers: [],
        hubspot_default_values: [],
        steps: [
            {
                id: stepIdStart,
                name: "Inicio del Bot",
                action: {
                    type: EActions.START
                },
                finish: false,
                answer: "",
                message: "",
                previous: null,
                options: [
                    {
                        title: "has-contact",
                        id: stepIdStart + "_0",
                        next: stepId
                    },
                    {
                        title: "no-has-contact",
                        id: stepIdStart + "_1",
                        next: stepId2
                    }
                ],
                hubspotValues: [],
                type: EMessageType.START
            },
            {
                id: stepId,
                name: "Saludo inicial",
                message: "¡Es un placer saludarte!",
                action: { type: EActions.SEND_MESSAGE },
                finish: true,
                go_to: "",
                hubspotValues: [],
                options: [],
                parentStep: {
                    parent: stepIdStart,
                    caseId: stepIdStart + "_0",
                },
                previous: stepIdStart,
                type: EMessageType.NORMAL_MESSAGE,
                inactivity_messages: true,
                answer: ""
            },
            {
                id: stepId2,
                name: "Saludo inicial Sin Contacto",
                message: "¡Es un placer saludarte!",
                action: { type: EActions.SEND_MESSAGE },
                finish: true,
                go_to: "",
                hubspotValues: [],
                options: [],
                parentStep: {
                    parent: stepIdStart,
                    caseId: stepIdStart + "_1",
                },
                previous: stepIdStart,
                type: EMessageType.NORMAL_MESSAGE,
                inactivity_messages: true,
                answer: ""
            }
        ],
        inactive_message:[],    
        never_published: true,
        stop_notifications: [],
        send_agent: [],
        finish_last_message: false,
        reattempts_agent: "N/A",
        message_failure_process: "",
    }

    const [draftFilter, setDraftFilter] = useState<IDraftBot[]>([]);
    
    const { data: bots, isLoading, error, isError } = useQuery('bots', getBots, {retry: false});
    const { data: draftBots, isLoading: loadingDraft, error: errorDraft, isError: isErrorDraft } = useQuery('draft_bots', getDraftBots, {retry: false});
    const navigate = useNavigate();
 
    useEffect(() => {
        if(draftBots){
            setDraftFilter(draftBots.filter(el => el.never_published === true));
        }
    }, [draftBots])
    
    return (
        <BotFormProvider>
            <div className='container-lg' style={{padding: "4rem 0"}}>

                <MainTemplate
                    bots={bots ? bots!.filter(el => el.legacy === false): []}
                    isLoading={isLoading}
                    error={error} 
                    isError={isError}
                    title="Bots Publicados"
                    showCreateBot={true}
                />
                <MainTemplate
                    bots={draftFilter!}
                    isDraftBot={true}
                    isLoading={isLoading}
                    error={error} 
                    isError={isError}
                    title="Bots Sin Publicar"
                    showCreateBot={false}
                />
                {
                    bots?.filter(el => el.legacy === true).length ? 
                        <MainTemplate
                            bots={bots?.filter(el => el.legacy === true)}
                            isDraftBot={false}
                            isLoading={isLoading}
                            error={error} 
                            isError={isError}
                            title="Bots Legacy"
                            showCreateBot={false}
                            onlyView={true}
                        />: ""
                }
                

                <BotForm
                    mode='create'
                    bot={emptyDraftBot}
                    submitForm={async (bot) => {
                        let response = await createDraftBot(bot as IDraftBot, {register: "Crear nuevo Draft Bot", url: window.location.href });
                        navigate(`/edit/${response._id}?never_published=true`);
                    }} 
                />
            </div>
        </BotFormProvider>
    )
}

export default MainPage;
import { createGlobalStyle } from 'styled-components';
import { EColors } from './types/styles/colors.enum';

const GlobalStyle = createGlobalStyle`
    :root {
        --primary-color: ${EColors.primary};
        --primary-color-light: ${EColors.primaryTransparent};
        --secondary-color: ${EColors.secondary};
        --terciary-color: ${EColors.tertiary};
        --quaternary-color: ${EColors.quaternary};
        --extra-color: ${EColors.extra};
        --text-color: ${EColors.text};
        --talkspro-color: ${EColors.talksPro};
        --gradient-color: ${EColors.gradient};
        --gray-bg-color: ${EColors.bgGray};
        --bs-primary: #6f42c1;
        --border-color: #C9CDD2;
        --header-conversation-color: #3d3d3d;
    }

    body {
        margin: 0; 
        padding: 0;
        box-sizing: border-box;
        font-family: 'Roboto', sans-serif;
    }

    a {
    text-decoration: none;
    }

    .count-input {
        font-size: 12px;
        text-align: end;
        &.red {
            color: red;
        }
    }
    .form-check-input:checked {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
    }
    .form-control {
        appearance: auto;
    }
    .form-control:focus {
        border-color: var(--primary-color);
        box-shadow: 0 0 0 .25rem var(--primary-color-light);
    }

    .Tooltip {
        background-color: #444;
        color: white;
        font-size: 90%;
        padding: 4px 8px;
        border-radius: 4px;
        box-sizing: border-box;
        width: max-content;
        max-width: 300px;
        z-index: 1000;

    }

    .text-tooltip {
        display: inline-block;
    }
    .text-tooltip:hover {
            cursor: help;
        }

`;


export default GlobalStyle;
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { InfoWrapper } from '../../UI/organisms/bot/botInfo.component';
import { EColors } from '../../../types/styles/colors.enum';
import { ControlsPosition } from '../../UI/organisms/controls/controls.component';

export interface EditLoaderProps { }

const EditLoader: React.FC<EditLoaderProps> = ({ }) => {
    return (
        <SkeletonTheme>
            <div className="d-flex">
                <InfoWrapper visible={true}>
                    <Skeleton className='w-100' />
                    <Skeleton className='w-75' />
                    <Skeleton className='mt-4' />
                    <Skeleton className='w-75 mt-2' count={4} />
                    <Skeleton baseColor={EColors.quaternary} className='p-2 mt-4' />
                </InfoWrapper>

                <div className='w-100 d-flex gap-4 align-items-center flex-column p-4 p-relative' style={{ background: EColors.bgGray }}>
                    {
                        new Array(2).fill(1).map((_, i) => (
                            <Skeleton
                                key={i}
                                baseColor={EColors.quaternary}
                                width={300}
                                height={100}
                                className='mt-3'
                            />
                        ))
                    }
                    <Skeleton baseColor={EColors.white} width={300} height={40} />

                    <ControlsPosition complete={false}>
                        <div className="d-flex justify-content-center">
                            <div className="d-flex gap-2 me-5">
                                <Skeleton className='p-2' width={50} />
                                <Skeleton className='p-2' width={50} />
                                <Skeleton className='p-2' width={50} />
                                <Skeleton className='p-2' width={100} />
                            </div>

                            <div className="d-flex gap-2">
                                <Skeleton className='p-2' width={100} baseColor={EColors.quaternary} />
                                <Skeleton className='p-2' width={100} baseColor={EColors.quaternary} />
                            </div>

                        </div>
                    </ControlsPosition>
                </div>

            </div>

        </SkeletonTheme>
    );
}

export default EditLoader;
import React, { useState  } from 'react';
import BotInfo from '../../UI/organisms/bot/botInfo.component';
import BotSpace from '../../UI/organisms/bot/botSpace.component';

const EditTemplate: React.FC = () => {
    const [showInfo, setShowInfo] = useState<boolean>(true);


    return (
        <div className='position-relative overflow-hidden d-flex' style={{ height: "calc(100vh - 88px)", marginTop: "3.5rem" }} >
           {/*  <BotInfo
                showInfo={showInfo}
                setShowInfo={setShowInfo}
            /> */}
            <BotSpace 
                complete={true}
            />
        </div>
    )
}

export default EditTemplate;



import axios from "axios";
import { IUser, IUserCurrentResponse, IUserLogin, IUserLoginResponse, IUserResponse } from "../types/requests/user/user.interface";
import { validateToken } from "../utils/token";

/* export interface userServiceResponse<T = any> {
    status: string;
    message: string;
    data: T
} */

const userPublicService = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 10000
});

const userService = () => axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 10000,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token_user')}
});

export const getAllUsers = async (): Promise<IUser> => {
    try {
        let axiosUser = userService();
        const result = await axiosUser.get<IUser>('users/getAll');
        return result.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}

export const handleLogin = async ({email, password, autologin}: IUserLogin): Promise<IUserLoginResponse> => {
    try {
        const result = await userPublicService.post<IUserLoginResponse>('/users/login', { email , password, autologin });
        return result.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}

export const userCurrent = async (): Promise<IUserCurrentResponse> => {
    const token = localStorage.getItem('token_user');
    if (!token) {
        throw new Error('No token found in localStorage');
      }
    try {
        let axiosUser = userService();
        let res =  await axiosUser.get<IUserResponse>('/users/current');
        return res.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}

/* export const getBot = async (id: string): Promise<IUser> => {
    try {
        const result = await userService.get<userServiceResponse<IUser>>(`bots/get/${id}`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}

export const getBots = async (): Promise<IUser[]> => {
    try {
        const result = await userService.get<userServiceResponse<IUser[]>>(`bots/get`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}
 */
export default userService;


import React from 'react';
import { Field, FieldAttributes } from 'formik';

export interface RadiosGroupProps {
    name: string,
    label: string,
    value: string,
    //! CORREGIR ANY
    options: FieldAttributes<any>[],
}

const RadiosGroup: React.FC<RadiosGroupProps> = ({ label, name, options, value }) => {
    return (
        
        <div className="form-group">
            <label htmlFor={options[0]?.id} className='form-label d-block'>{label}</label>

            {
                options.map((option, idx) => (
                    <div className="form-check form-check-inline" key={`${name}-${idx}`}>
                        <Field className="form-check-input" type="radio" {...option} value={option.value} name={name} checked={option.value ? value.length ? true: false: !value.length} />
                        <label className="form-check-label" htmlFor={option.id}>{option.label}</label>
                    </div>
                ))
            }

        </div>
    )
}

export default RadiosGroup;
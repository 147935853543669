import styled from "styled-components";
import { EColors } from "../../../../types/styles/colors.enum";

const TableWrapper = styled.table`
    color: ${EColors.text};
    border-collapse: collapse;
    border-spacing: 8px;
    padding: 0;
    width: 100%;

    thead {
        font-size: 16px;
        font-weight: 400 !important;
        text-transform: uppercase;
        border-bottom: 1px solid lightgray;
        th {
            padding: 8px;
            font-weight: 500 !important;
            text-align: left;
        }
    }

    tbody {
        font-size: 14px;
        font-weight: light;
        td {
            /* background-color: #E8E8E8; */
            padding: 16px 8px;
            text-align: left;
        }
    }
`;

export default TableWrapper;
import axios from "axios";
import { ITokens } from "../types/requests/tokens/tokens.interface";
import { validateToken } from "../utils/token";
import { INotification } from "./bots.service";
import { createLog } from "./logs.service";

export interface clientServiceResponse<T = any> {
    status: string;
    message: string;
    data: T
}
export interface requestToken {
    token: {flow: string | null, group: string, subgroup: string}
}

const tokensService = () => (axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 10000,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token_user')}
}));

export const getTokens = async (): Promise<ITokens[]> => {
    try {
        let axiosBot = tokensService();
        const result = await axiosBot.get<clientServiceResponse<ITokens[]>>(`tokens/get`);
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}
export const updateTokens = async (id: string, data: requestToken, notification?: INotification): Promise<ITokens> => {
    try {
        let axiosBot = tokensService();
        const result = await axiosBot.put<clientServiceResponse<ITokens>>(`tokens/update/${id}`, data);
        if(notification) {
            await createLog({id: id, ...data}, `tokens/update/${id}`, notification.register, notification.url, "Update" );
        }
        return result.data.data;
    } catch (error: any) {
        validateToken(error.response);
        throw error;
    }
}

export default tokensService;

import React, { useContext, useEffect, useState } from 'react';
import { BotFormContext, BotFormContextType } from '../../../../context/botForm.context';
import BotIcon from '../../atoms/icon/botIcon.component';
import styled from 'styled-components';
import { EColors } from '../../../../types/styles/colors.enum';
import Button from '../../atoms/button/button.component';
import useBot from '../../../../hooks/useBot.hook';
import EnvForm from '../../../templates/envForm/envForm.template';
import CloseIcon from '../../atoms/icon/closeIcon.component';
import { getBotByIdDraft, getTokenByBotId } from '../../../../services/bots.service';
import { ITokens } from '../../../../types/requests/tokens/tokens.interface';

interface StyledProps {
    visible: boolean
}

export const InfoWrapper = styled.div<StyledProps>`
    position: absolute;
    top: ${({ visible }) => visible ? "100px" : "-315px"};;
    left: 0px;
    background: white;
    padding: 1.5rem;
    min-height: 300px;
    width: max-content;
    min-width: 320px;
    transition: 1s;
    text-align: left;

    overflow-x: visible;
    overflow-y: auto;
    z-index: 2;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            padding: 4px 0;
        }
    }
    & .group-btn {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    & > .close {
        text-align: end;
        &:hover {
            cursor:pointer;
        }
    }
`;

export interface BotInfoProps {
    showInfo: boolean,
    setShowInfo: React.Dispatch<React.SetStateAction<boolean>>
}

const BotInfo: React.FC<BotInfoProps> = ({ setShowInfo, showInfo }) => {
    const { bot, setBot } = useBot();

    const { setVisible } = useContext(BotFormContext) as BotFormContextType;
    const [isVisibleEnvForm, setIsVisibleEnvForm] = useState(false);
    const [infoBot, setInfoBot] = useState<Partial<ITokens[]>>([]);

    useEffect(() => {
        const validateAllBots = async () => {
            const dataBot = await getBotByIdDraft(bot?._id || "")
            const resultsToken = await requestTokenBot(dataBot?._id || "");
            setInfoBot(resultsToken);
           };
        validateAllBots();
        }, [bot]);
    const requestTokenBot = async(botId: string):Promise<Partial<ITokens[]>> => {
        let dataDraft = await getTokenByBotId(botId!);
        return dataDraft;
    }
    return (
        <>
            <InfoWrapper visible={showInfo}>
                <div onClick={() => setShowInfo(!showInfo)} className="close">
                    <CloseIcon colorCode={EColors.primary} />
                </div>
                <div className="d-flex">
                    <div><BotIcon colorCode={EColors.primary} width="24" height="24" /></div>
                    <h5 className="ms-1 text-truncate fw-medium" style={{ fontSize: "24px" }}>{bot?.name}</h5>
                </div>

                <div className="text-truncate fw-light">Vinculado a con el número {infoBot.map((el: any) => `+${el.number}`).join(", ") || "N/A"} </div> <br />
                <div className="group-btn">
                    <Button
                        btnType='primary'
                        className='mt-2'
                        onClick={() => setVisible(true)}
                    >
                        Editar información general
                    </Button>
                    <Button
                        btnType='primary'
                        className='mt-2'
                        onClick={() => setIsVisibleEnvForm(true)}
                    >
                        Variables Entorno
                    </Button>
                </div>
                
            </InfoWrapper>


            {isVisibleEnvForm && <EnvForm 
                bot={bot} 
                setIsVisibleEnvForm={setIsVisibleEnvForm} 
                isVisibleEnvForm={isVisibleEnvForm}
                submitForm={async (bot) => {
                    await setBot.mutateAsync({botProps: bot, notification: {register: `Configuración de variables de Entorno`, url: window.location.href}});
                }}    
            />}
        </>

    )
}

export default BotInfo;